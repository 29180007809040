export class Form{
    id ;
    tag;
    title;
    placeHolder;
    type ;
    mandatory  ;
    allowedCharacters;
    maxLength ;
    line ;
    fetchApi ;
    queryParams = new Array();
    imageDirectory ;

    // constructor() {
    //     if(!this.requestParams){
    //         this.requestParams = new Array();
    //     }
    // }

    constructor(){}


}
