import React from 'react';
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from 'reactstrap';
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import {FormType} from "../../model/formbuilder/FormType";
import imageGallery from 'assets/img/image_gallery.png';
import HorizontalLine from "./HorizontalLine";
import WarningLabel from "./WarningLabel";
import MyCardImage from "../Card/MyCardImage";


export default class InspectionEventFormWidget extends BaseComponent{

    constructor(props) {
        super(props);
        let state = this.state
        state.inspection = this.props.inspection
        state.inspectionEvent = this.props.inspectionEvent
        state.formLaneApplication = this.props.formLaneApplication?this.props.formLaneApplication:{}
        state.formApplication = this.props.formApplication?this.props.formApplication:{}
        state.stepFormLaneId = this.props.stepFormLaneId ;
        state.cameraFormShow = false ;
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            let state = this.state
            state.inspection = props.inspection
            state.inspectionEvent = props.inspectionEvent
            state.formLaneApplication = props.formLaneApplication?props.formLaneApplication:{}
            state.formApplication = props.formApplication?props.formApplication:{}
            state.stepFormLaneId = props.stepFormLaneId ;
            state.cameraFormShow = false ;
            this.setState(state)
        }
    }

    renderSubInputs=(formApplication)=>{
        let subFormApplications = formApplication.subFormApplications?formApplication.subFormApplications:[]
        if(!subFormApplications || subFormApplications.length<=0){
            return ;
        }
        return(
            <>
                <HorizontalLine/>
                <Row>
                {
                    subFormApplications.map((subFormApplication, index) =>
                        this.renderSubInput(subFormApplications, formApplication, subFormApplication, index)
                    )
                }
                </Row>
            </>
        )
    }

    renderSubInput=(subFormApplications, formApplication, subFormApplication, index)=>{
        let {cameraFormShow} = this.state
        let form = subFormApplication.form?subFormApplication.form:{}
        if(form.type==FormType.TEXT){
            return (
                <Col md={12}>
                    <FormGroup>
                        <Label for="name">{form.title}</Label>
                        <Input
                            className={form.error?"form-error":undefined}
                            color={"danger"}
                            type="textarea"
                            name="title"
                            rows={form.line>0?form.line:1}
                            maxLength={form.maxLength>0?form.maxLength:undefined}
                            value={subFormApplication.value}
                            disabled
                            />
                    </FormGroup>
                </Col>
            );
        }
        if(form.type==FormType.POSITIVE_NEGATIVE){
            return (
                <Col md={12}>
                    <FormGroup>
                        <Label for="name">{form.title}</Label>
                        <Row>
                            <Col>
                                <InputGroup
                                    className={"clickable "+(form.error?"form-error":undefined)}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <Input
                                                checked={form.positiveValue===subFormApplication.value}
                                                addon type="checkbox"
                                                aria-label={form.positiveValue}/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input disabled placeholder={form.positiveValue} />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup
                                    className={"clickable "+(form.error?"form-error":undefined)}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <Input
                                                checked={form.negativeValue===subFormApplication.value}
                                                addon type="checkbox"
                                                aria-label={form.negativeValue}/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input disabled placeholder={form.negativeValue} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            );
        }
        if(form.type==FormType.IMAGE){
            return (
                <>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="name">{form.title}</Label>
                            <Card className={form.error?"form-error":undefined}>
                                <CardBody>
                                    <MyCardImage
                                        src={subFormApplication.imageUrl?subFormApplication.imageUrl:imageGallery}
                                        onError={(elm)=>this.defaultImage(elm, imageGallery)}
                                        onClick={()=>{
                                            if(subFormApplication.imageUrl){
                                                this.openLightBoxSingleImage(subFormApplication.imageUrl)
                                            }
                                        }}
                                    />
                                    <WarningLabel show={form.error} message={form.message}/>
                                </CardBody>
                            </Card>
                        </FormGroup>
                    </Col>
                    {super.render()}
                </>
            );
        }
        return (
            <></>
        )
    }


    render() {
        let {inspection, inspectionEvent, formLaneApplication, stepFormLaneId, formApplication, cameraFormShow} = this.state
        let formApplications = formLaneApplication.formApplications?formLaneApplication.formApplications:[]
        let form = formApplication.form?formApplication.form:{}
        if(stepFormLaneId!==formLaneApplication.laneId){
            return null;
        }
        if(form.type===FormType.TEXT){
            return (
                <Col md={6} key={formApplication.id}>
                    <Card className={form.error?"form-error":undefined}>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="name"><strong>{form.tag}</strong>&nbsp;-&nbsp;{form.title}</Label>
                                        <Input
                                            color={"danger"}
                                            type="textarea"
                                            name="title"
                                            rows={form.line>0?form.line:1}
                                            maxLength={form.maxLength>0?form.maxLength:undefined}
                                            value={formApplication.value}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.renderSubInputs(formApplication)}
                        </CardBody>
                    </Card>
                    <br/>
                </Col>
            );
        }
        if(form.type==FormType.POSITIVE_NEGATIVE){
            return (
                <Col  md={6} key={formApplication.id}>
                    <Card className={form.error?"form-error":undefined}>
                        <CardBody>
                            <FormGroup>
                                <Label for="name"><strong>{form.tag}</strong>&nbsp;-&nbsp;{form.title}</Label>
                                <Row>
                                    <Col>
                                        <InputGroup className={"clickable"}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <Input
                                                        checked={form.positiveValue===formApplication.value}
                                                        addon type="checkbox"
                                                        aria-label={form.positiveValue}/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input disabled placeholder={form.positiveValue} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className={"clickable"}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <Input
                                                        checked={form.negativeValue===formApplication.value}
                                                        addon type="checkbox"
                                                        aria-label={form.negativeValue}/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input disabled placeholder={form.negativeValue} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                            {this.renderSubInputs(formApplication)}
                        </CardBody>
                    </Card>
                    <br/>
                </Col>
            );
        }
        if(form.type==FormType.IMAGE){
            return (
                <Col md={6} key={formApplication.id}>
                    <Card className={form.error?"form-error":undefined}>
                        <CardBody>
                            <FormGroup>
                                <Label for="name"><strong>{form.tag}</strong>&nbsp;-&nbsp;{form.title}</Label>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <MyCardImage
                                                    src={formApplication.imageUrl?formApplication.imageUrl:imageGallery}
                                                    onError={(elm)=>this.defaultImage(elm, imageGallery)}
                                                    onClick={()=>{
                                                        if(formApplication.imageUrl){
                                                            this.openLightBoxSingleImage(formApplication.imageUrl)
                                                        }
                                                    }}/>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <HorizontalLine />
                            </FormGroup>
                            {this.renderSubInputs(formApplication)}
                        </CardBody>
                    </Card>
                    <br/>
                    {super.render()}
                </Col>
            );
        }

        // return (
        //     <></>
        // )
    }
}

InspectionEventFormWidget.propTypes = {
    inspection:PropTypes.object.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    stepFormLaneId:PropTypes.object.isRequired,
    formLaneApplication:PropTypes.object.isRequired,
    formApplication:PropTypes.object.isRequired,
}

