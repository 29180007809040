import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
} from 'reactstrap';
import {parseDate} from '../../utils/Utilities';
import {DD_MM_YYYY_HH_MM_SS} from '../../utils/Global';
import label from '../../message/Label'
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";


export default class AdminStatusHistoryModal extends BaseComponent{

    constructor(props) {
        super(props);
        let admin = this.props.admin?this.props.admin:{}
        let adminStatuses = admin.adminStatuses?admin.adminStatuses:[]
        this.state ={
            admin:admin,
            adminStatuses:adminStatuses
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props.showing){
            let admin = props.admin?props.admin:{}
            let adminStatuses = admin.adminStatuses?admin.adminStatuses:[]

            this.setState({
                admin:admin, adminStatuses:adminStatuses
            })
        }
    }


    render() {
        let {admin, adminStatuses} = this.state

        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.showing}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.StatusHistory}
                    </ModalHeader>
                    <ModalBody>
                        {this.renderAdminStatuses()}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onClose()
                            }}>
                            {label.Close}
                        </Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }

    renderAdminStatuses = () =>{
        return (
            <Row key={2}>
                <Col>
                    <Card>
                        <CardHeader>
                            <strong>{label.AdminStatus}</strong>
                        </CardHeader>
                        <CardBody>
                            {this.renderAdminStatusesTable()}
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        )
    }


    renderAdminStatusesTable = () =>{
        let {adminStatuses} = this.state
        return (
            <Card>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Status}</th>
                            <th>{label.Reason}</th>
                            <th>{label.PerformedBy}</th>
                            <th>{label.Created}</th>
                            <th>{label.Updated}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                           adminStatuses.map((item, index)=>(
                                <tr key={item.id}>
                                    <th scope="row">{(++index)}</th>
                                    <td>{item.name}</td>
                                    <td>{item.reason}</td>
                                    <td>{item.performer?item.performer.fullname:"-"}</td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        )
    }


}

AdminStatusHistoryModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    admin:PropTypes.object.isRequired
}
