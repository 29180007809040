import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import CardBlock from 'reactstrap/es/CardBlock';
import BasePage from './BasePage';
import kitchen from '../utils/AxiosInstance';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {isEmpty, parseDate, sortirMap} from '../utils/Utilities';
import {MdDelete, MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import {IoMdMenu} from 'react-icons/io';
import label from '../message/Label'

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class RolePage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      roles : [],
      role:{},
      categories:[],
      category:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var role = this.state.role
    if(isEmpty(role.name)){
      this.showDialog("Notice", "Please complete this form fields")
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }

  save = () => {
    let role = this.state.role
    if(!role.id && !role.active){
      role.active = false ;
    }
    this.setState({
      modalAddConfirm:false
    }, () => {
      kitchen.post(Global.API.ROLE_SAVE, role, null).then(res => {
        if(res.data.code===200){
          this.setState({
            role:{}
          }, () => {
            this.fetchAll()
          })
        }else{

        }
      });
    })
  }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      kitchen.get(Global.API.ROLE_DELETE+"/"+this.state.role.id).then(res => {
        if(res.data.code===200){
          this.setState({
            role:{}
          }, () => {
            this.fetchAll()
          })
        }else{

        }
      });
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Global.API.ROLES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          roles : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )

  render() {
    return (
        <Page
            title={label.Role}
            breadcrumbs={[{ name: label.Role, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message="Do you want to update this role item to your list ?"
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message="Do you want to delete this role item from your list ?"
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.Role}</CardHeader>
                <CardBody >
                  <Row>
                    <Col md={4}>
                      <Card body>
                        <Row>
                          <CardBlock>
                            <FormGroup>
                              <Label for="name">{label.Name}</Label>
                              <Input
                                  type="text"
                                  name="name"
                                  value={!isEmpty(this.state.role.name)?this.state.role.name:""}
                                  onChange={(e) =>{
                                    let ctg = this.state.role
                                    if(ctg!=null){
                                      ctg.name = e.target.value
                                      this.setState({
                                        role:ctg
                                      })
                                    }
                                  }}
                                 placeholder={label.EnterRoleName}
                              />
                            </FormGroup>
                            <FormGroup>
                              <ActiveOption
                                default={this.state.role.active}
                                callback={(active)=>{
                                  let ctg = this.state.role
                                  if(ctg!=null){
                                    ctg.active = active
                                    this.setState({
                                      role:ctg
                                    })
                                  }

                                }}
                            />
                            </FormGroup>
                        </CardBlock>
                        </Row>
                        <Row>
                          <Col>
                            <ButtonGroup className="float-left">
                              <Button color="danger" onClick={e => (
                                  this.setState({
                                    role:null
                                  })
                              )}>{label.Cancel}</Button>
                            </ButtonGroup>
                          </Col>
                          <Col>
                            <ButtonGroup className="float-right">
                              <Button color="primary" onClick={e=>(
                                  this.confirmSave()
                              )}> {
                                (this.state.role!=null && this.state.role.id!=null)?label.Update:label.Add
                              } </Button>

                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.Status}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Edit}</th>
                                <th>Menu</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.roles.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.name}</td>
                                      <td><ActiveLabel active={item.active}/></td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                        <td>
                                          <Button color="danger" onClick={e => {
                                            this.setState({
                                              role:item
                                            }, () => {
                                              this.confirmDelete()
                                            })
                                          }}>
                                            <MdDelete/>
                                          </Button>
                                          &nbsp;
                                          <Button color="primary" onClick={e=>(
                                              this.setState({
                                                role:item
                                              })
                                          )}>
                                            <MdEdit/>
                                          </Button>
                                        </td>
                                      <td><Button color="primary" onClick={event=>{
                                        event.preventDefault()
                                        this.props.history.push("/menu?roleId="+item.id)
                                      }}><IoMdMenu/></Button></td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}
export default Object.assign(RolePage, {ENDPOINT : "/roles"})
