export function inspectionEventsFromNative(datas){
    let inspectionEvents = [];

    if(datas.length>0) datas.map((data)=>{
        let inspectionEvent = {}
        let inspection = {};
        let model = {};
        let modelCategory = {};
        let inspectionStatus = {};
        let user = {}
        inspectionEvent.id = data[0]
        inspectionEvent.serialCode = data[2]
        inspectionEvent.customer_order_no = data[3]
        inspectionEvent.productSKU=data[4]
        inspectionEvent.productName=data[5]
        inspectionEvent.performerName=data[8]
        inspectionEvent.performerRole=data[9]
        inspectionEvent.created = data[12]

        modelCategory.name = data[7]

        model.systemType = data[6]
        model.modelCategory = modelCategory

        inspection.reference = data[1]
        inspection.model = model

        user.id = data[13]
        user.fullname = data[14]+" "+data[15]

        inspectionStatus.name = data[10]
        inspectionStatus.label = data[11]

        inspectionEvent.inspection = inspection
        inspectionEvent.inspectionStatus = inspectionStatus
        if (data[13]){
            inspectionEvent.user = user
        }
        inspectionEvents.push(inspectionEvent)
    })

    console.log("generatedEvents",inspectionEvents);
    return inspectionEvents;

}