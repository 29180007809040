export class FormType{
    static TEXT  = "TEXT"
    static EMAIL  = "EMAIL"
    static NUMBER  = "NUMBER"
    static PHONE  = "PHONE"
    static CURRENCY  = "CURRENCY"
    static DATE  = "DATE"
    // static DROPDOWN : string = "DROPDOWN"
    static DROPDOWN_DATA_API  = "DROPDOWN_DATA_API"
    static POSITIVE_NEGATIVE  = "POSITIVE_NEGATIVE"
    static IMAGE  = "IMAGE"
}