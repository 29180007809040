import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import catchadealLogo from 'assets/img/logo/ic_logo_abacus.png';
import SourceLink from 'components/SourceLink';
import React from 'react';
import {FaTools, MdAccountCircle, MdArrowDropDownCircle, MdDashboard, MdDetails, MdSettings} from 'react-icons/all';
import {NavLink} from 'react-router-dom';
import {Collapse, Nav, Navbar, NavItem, NavLink as BSNavLink} from 'reactstrap';
import bn from 'utils/bemnames';
import {AuthConsumer} from '../../utils/AuthContext';
import {getData} from '../../utils/StorageUtil';
import Global from '../../utils/Global';
import Img from 'react-image';
import label from '../../message/Label'

const mdIcons = {
    "dashboard": <MdDashboard/>,
    "inspection": <FaTools/>,
    "inspectionEvent": <MdDetails/>,
    "user": <MdAccountCircle/>,
    "profil": <MdSettings/>
}

const sidebarBackground = {
    backgroundImage: `url("${sidebarBgImage}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};
const bem = bn.create('sidebar');

class Sidebar extends React.Component {
    state = {
        // isOpenProduct: true,
        isOpenComponents: true,
        isOpenContents: true,
        isOpenPages: true,
    };

    handleClick = name => () => {
        this.setState(prevState => {
            const isOpen = prevState[`isOpen${name}`];

            return {
                [`isOpen${name}`]: !isOpen,
            };
        });
    };

    render() {
        let configuration = getData(Global.CONFIGURATION)
        if (configuration === null) {
            configuration = {}
        }

        let configurationAdmin = getData(Global.CONFIGURATION_ADMIN)
        if (configurationAdmin === null) {
            configurationAdmin = {}
        }


        return (
            <AuthConsumer>
                
                {
                    ({admin}) => (
                        
                        <aside className={bem.b()} data-image={sidebarBgImage}>
                            <div className={bem.e('background')} style={sidebarBackground}/>
                            <div className={bem.e('content')}>
                                <br/>
                                <Navbar>
                                    <SourceLink>
                                        <Img
                                            src={catchadealLogo}
                                            width="100%"
                                            alt="Logo"
                                        />
                                    </SourceLink>
                                </Navbar>
                                <Nav vertical>
                                    {
                                        admin.role.treeMenus.map(({clazz, name, childMenus, link}, index) => (
                                            childMenus.length > 0 ? (
                                                <span key={index}>
                            <NavItem className={bem.e('nav-item')} onClick={event => {
                                event.preventDefault()
                                this.setState({
                                    ['isOpen' + name]: this.state['isOpen' + name] === undefined ? true : !this.state['isOpen' + name]
                                })
                            }}>
                              <BSNavLink className={bem.e('nav-item-collapse')}>
                                <div className="d-flex">
                                    <span className="align-self-start">{label(name)}</span>
                                </div>
                                <MdArrowDropDownCircle
                                    className={bem.e('nav-item-icon')}
                                    style={{
                                        padding: 0,
                                        transform: this.state['isOpen' + name]
                                            ? 'rotate(0deg)'
                                            : 'rotate(-90deg)',
                                        transitionDuration: '0.3s',
                                        transitionProperty: 'transform',
                                    }}/>
                              </BSNavLink>
                            </NavItem>
                                                    {
                                                        <Collapse key={index} isOpen={this.state['isOpen' + name]}>
                                                            {childMenus.map((item, index1) => (
                                                                <NavItem key={index1} className={bem.e('nav-item')}>
                                                                    <BSNavLink
                                                                        id={`navItem-${item.name}-${index1}`}
                                                                        tag={NavLink}
                                                                        to={item.link}
                                                                        activeClassName="active"
                                                                        exact={true}
                                                                    >
                                                                        {/*<Icon className={bem.e('nav-item-icon')} />*/}
                                                                        <span className="">&nbsp;&nbsp;{label(item.name)}</span>
                                                                    </BSNavLink>
                                                                </NavItem>
                                                            ))}
                                                        </Collapse>
                                                    }
                              </span>
                                            ) : (
                                                <NavItem key={index} className={bem.e('nav-item')}>
                                                    <BSNavLink
                                                        id={`navItem-${name}-${index}`}
                                                        className="text-uppercase"
                                                        tag={NavLink}
                                                        to={link}
                                                        activeClassName="active"
                                                        exact={true}
                                                    >
                                                        {mdIcons[clazz]}&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span className="">{label(name)}</span>
                                                    </BSNavLink>
                                                </NavItem>
                                            )
                                        ))
                                    }
                                </Nav>
                            </div>
                        </aside>
                    )
                }
            </AuthConsumer>
        );
    }
}

export default Sidebar;
