import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import label from '../../message/Label'
import WarningLabel from "../Widget/WarningLabel";
import {isEmpty} from "../../utils/Utilities";
/*= (message, okCallback, cancelCallback) =>*/
export default class FormBuilderCopyModal extends React.Component{

    constructor(props){
        super(props);
        let groupForm = this.props.groupForm?this.props.groupForm:{}
        let formName = groupForm.name
        let documentNumber = groupForm.documentNumber
        this.state = {
            modal: this.props.modal,
            groupForm: groupForm?groupForm:{},
            formName : formName,
            documentNumber : documentNumber,
            okCallback : this.props.okCallback,
            closeCallback : this.props.closeCallback,
        }
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.props.modal
        if(!props.modal){
            this.setState({
                modal:props.modal,
                positiveCheck:true,
            })
        }else if(modal!=props.showing){
            let groupForm = props.groupForm
            let formName = groupForm.name
            let documentNumber = groupForm.documentNumber
            this.setState({
                modal:props.modal,
                groupForm: groupForm?groupForm:{},
                formName : formName,
                documentNumber : documentNumber,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
            })
        }
    }
    validate(){
        let {groupForm, formName, documentNumber, formNameWarning, documentNumberWarning} = this.state
        if(isEmpty(formName)){
            this.setState({
                formNameWarning:label.PleaseEnterFormName
            })
            return;
        }
        if(isEmpty(documentNumber)){
            this.setState({
                documentNumberWarning:label.PleaseEnterDocumentNumber
            })
            return;
        }
        this.state.okCallback(groupForm, this.state.formName, documentNumber)


    }
    render(){
        let {groupForm, formName, documentNumber, formNameWarning, documentNumberWarning} = this.state

        return (
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={true}>
                <ModalHeader>
                    {label.CopyToTheNewFormBuilder(groupForm.name)}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="formName">{label.NewFormBuilderName}</Label>
                                <Input
                                    id="formName"
                                    type="text"
                                    name="formName"
                                    value={formName}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        this.setState({
                                            formNameWarning:undefined,
                                            formName : value?value:null
                                        })
                                    }}
                                    placeholder={label.EnterNewFormBuilderName}
                                />
                                <WarningLabel show={formNameWarning} message={formNameWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="documentNumber">{label.NewDocumentNumber}</Label>
                                <Input
                                    id="documentNumber"
                                    type="text"
                                    name="formName"
                                    value={documentNumber}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        this.setState({
                                            documentNumberWarning:undefined,
                                            documentNumber : value?value:null
                                        })
                                    }}
                                    placeholder={label.EnterNewDocumentNumber}
                                />
                                <WarningLabel show={documentNumberWarning} message={documentNumberWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={e=>{
                                if(this.state.okCallback){
                                    this.validate()
                                }
                            }}>
                                {label.Save}
                            </Button>
                        </Col>
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

FormBuilderCopyModal.propTypes = {
    modal:PropTypes.bool,
    groupForm:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
