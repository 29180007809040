import BasePage from './BasePage';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table } from 'reactstrap';
import { isEmpty, parseDate } from '../utils/Utilities';
import Global, { DD_MM_YYYY_HH_MM_SS } from '../utils/Global';
import queryString from 'query-string';
import Pagination from '../components/Pagination';
import ItemOption from '../components/Widget/ItemOption';
import Page from '../components/Page';
import Chips from 'react-chips';
import { IoMdEye } from 'react-icons/io';
import JsonDialog from '../components/modal/JsonDialog';
import DateInput from '../components/Widget/DateInput';
import label from '../message/Label'
import { Select } from 'antd';

class AdminActivityPage extends BasePage {

    constructor(props) {
        super(props);
        this.state = {
            adminActivities: [],
            adminActivity: {},
            activityTypes: [],
            adminChips: [],
            adminFirstnames: [],
            search: "",
            creatorName: "",
            page: queryString.parse(this.props.query).page ? queryString.parse(this.props.query).page : 1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            substraction: null,
            type: null,
            createdStartDate: null,
            createdEndDate: null,
            jsonModal: false,
            sortBy: {},
            sequence: {}
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAdminActivity(true)
        this.fetchTypes()
        this.fetchAdminFirstnames()
    }

    componentWillReceiveProps(props, nextContext) {
        let currentPage = this.state.page
        let propsPage = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
        if (props != this.props) {
            this.setState({
                page: propsPage,
                totalPage: 0,
                totalElement: 0,
                pageElement: 0,
            }, () => {
                this.fetchAdminActivity(true)
            })
        }
    }


    fetchAdminActivity = (progressing) => {
        let dateRange = {}
        if (this.state.createdStartDate && this.state.createdEndDate) {
            dateRange = {
                startDate: this.state.createdStartDate,
                endDate: this.state.createdEndDate,
            }
        }
        this.get(Global.API.ADMIN_ACTIVITIES, {
            params: {
                ascending: this.state.sequence.name,
                sortir: this.state.sortBy.name,
                search: this.state.search,
                adminNames: this.state.adminChips.toString(),
                page: this.state.page - 1,
                type: this.state.type,
                substraction: this.state.substraction,
                ...dateRange
            }
        }, null, response => {
            if (response.code === 200) {
                this.setState({
                    adminActivities: response.data,
                    totalPage: response.totalPage,
                    totalElement: response.totalElement,
                    pageElement: response.pageElement,
                })
            }
        }, progressing, true)
    }


    fetchTypes = () => {
        this.get(Global.API.ADMIN_ACTIVITY_TYPES, null, null, response => {
            if (response.code === 200) {
                this.setState({
                    activityTypes: response.data
                })
            }
        }, false, true)
    }

    fetchAdminFirstnames = () => {
        this.get(Global.API.ADMIN_FIRSTNAMES, null, null, response => {
            if (response.code === 200) {
                this.setState({
                    adminFirstnames: response.data
                })
            }
        }, false, true)

    }


    render() {
        return this.renderTransaction()
    }

    renderTransaction = () => {

        let { activityTypes, sortBy, sequence, adminFirstnames } = this.state

        let types = []

        activityTypes.map((item, index) => {
            Object.keys(item).forEach(function eachKey(key) {
                types.push({
                    id: key,
                    name: item[key]
                })
            })
        })

        const adminActivity = this.state.adminActivity
        let admin = adminActivity.admin ? adminActivity.admin : {}
        return (

            <Page>
                {super.render()}
                <JsonDialog
                    json={adminActivity.jsonObject}
                    previousJsonData={adminActivity.previousObject}
                    showing={this.state.jsonModal} title={admin.fullname + " - " + adminActivity.type} okCallback={() => {
                        this.setState({
                            jsonModal: false
                        })
                    }} />
                <Card className="border-top-0">
                    <CardHeader>{label.AdminActivity}</CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <ItemOption
                                    title={label.SelectType}
                                    objects={types}
                                    default={this.state.type != null ? this.state.type : null}
                                    callback={(type) => {
                                        if (type != null) {
                                            this.setState({
                                                type: type.id
                                            }, () => {
                                                this.fetchAdminActivity(true)
                                            })
                                        }
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <Label for="createdStartDate">{label.StartDate}</Label>
                                <DateInput
                                    id="createdStartDate"
                                    maxdate={this.state.createdEndDate}
                                    value={this.state.createdStartDate}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({
                                            createdStartDate: value ? value : null
                                        }, () => {
                                            if (this.state.createdStartDate && this.state.createdEndDate) {
                                                this.fetchAdminActivity(true)
                                            } else if (!this.state.createdStartDate && this.state.createdEndDate) {
                                                this.fetchAdminActivity(true)
                                            }
                                        })
                                    }}
                                    placeholder={label.EnterStartDate}
                                />
                            </Col>
                            <Col md={3}>
                                <Label for="createdEndDate">{label.EndDate}</Label>
                                <DateInput
                                    id="createdEndDate"
                                    mindate={this.state.createdStartDate}
                                    value={this.state.createdEndDate}
                                    onChange={(e) => {
                                        let previousEndDate = this.state.createdEndDate;
                                        let value = e.target.value
                                        this.setState({
                                            createdEndDate: value ? value : null
                                        }, () => {
                                            if (this.state.createdStartDate && this.state.createdEndDate) {
                                                this.fetchAdminActivity(true)
                                            } else if (this.state.createdStartDate && !this.state.createdEndDate) {
                                                this.fetchAdminActivity(true)
                                            }
                                        })
                                    }}
                                    placeholder={label.EnterEndDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className={"col-input-chips"}>
                                <Label for="searchAdmin">{label.SearchByName} </Label>
                                {/* <Chips
                                    value={this.state.adminChips}
                                    onChange={(chips)=>{
                                        this.setState({
                                            adminChips:chips
                                        }, () => {
                                            this.fetchAdminActivity()
                                        })
                                    }}
                                    className="cr-search-form__input"
                                    suggestions={this.state.adminFirstnames}
                                    placeholder={label.Name}

                                /> */}
                                <Select mode={'tags'} style={{ width: "100%", display:"block" }}
                                onChange={(chips) => {
                                    this.setState({
                                        adminChips:chips
                                    }, () => {
                                        this.fetchAdminActivity()
                                    })
                                }}
                                >
                                    {adminFirstnames && Object.keys(adminFirstnames).length > 0 ?
                                        adminFirstnames.map((item, index) => (
                                            <Select.Option value={item}>{item}</Select.Option>
                                        )) : (
                                            <Select.Option value={''}></Select.Option>
                                        )
                                    }

                                </Select>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <ItemOption
                                        title={label.SortBy}
                                        fieldForLabel={"label"}
                                        objects={[
                                            { id: 1, name: "aa.created", label: label.Created },
                                            { id: 2, name: "a.firstname", label: label.Name }
                                        ]}
                                        default={!isEmpty(sortBy) ? sortBy.id : null}
                                        callback={(sortBy) => {
                                            this.setState({
                                                sortBy: sortBy
                                            }, () => {
                                                this.fetchAdminActivity(true)
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <ItemOption
                                        title={label.Sequence}
                                        fieldForLabel={"label"}
                                        objects={[
                                            { id: 1, name: true, label: label.Ascending },
                                            { id: 2, name: false, label: label.Descending }
                                        ]}
                                        default={!isEmpty(sequence) ? sequence.id : null}
                                        callback={(sequence) => {
                                            this.setState({
                                                sequence: sequence
                                            }, () => {
                                                this.fetchAdminActivity(true)
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {
                                    this.renderTransactionTable()
                                }
                            </Col>
                        </Row>
                    </CardBody>
                    <Pagination {...this.props} currentPage={this.state.page} pageCount={this.state.totalPage} key={'audit'} />
                </Card>

            </Page>
        )
    }


    renderTransactionTable = () => {
        return (
            <Table responsive hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{label.Type}</th>
                        <th>{label.Description}</th>
                        <th>Admin</th>
                        <th>{label.TimePerform}</th>
                        <th>{label.View}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.adminActivities.map((item, index) => (
                            <tr key={index}>
                                <th scope="row">{((this.state.page - 1) * this.state.pageElement) + (++index)}</th>
                                <td>{item.typeView}</td>
                                <td>{item.message}</td>
                                <td>{item.admin.fullname}</td>
                                <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                <td>
                                    <Button color="primary" onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            adminActivity: item,
                                            jsonModal: true
                                        })
                                    }}><IoMdEye /></Button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        )
    }
}

export default Object.assign(AdminActivityPage, { ENDPOINT: "/adminActivities" })
