
const label =  {
    Submit:"Submit",
    Cancel:"Cancel",
    Back:"Back",
    Or:"Or",
    And:"And",
    Ok:"Ok",
    Edit:"Edit",
    Remove:"Remove",
    Save: "Save",
    Close:"Close",
    Email:"Email",
    Page:"Page",
    Detail:"Detail",
    AddProduct:"Add Product",
    AddInventory:"Add Inventory",
    EnterYourMail:"Enter Your Email",
    YourEmail:"emailanda@email.com",
    Password:"Password",
    ConfirmPassword:"Confirm Password",
    EnterPassword:"Enter Password",
    EnterConfirmPassword:"Enter confirm password",
    EnterOldPassword:"Enter old password",
    EnterNewPassword:"Enter new password",
    ForgetPassword:"Forget Password",
    NotRegisteredYet:"Not Registered Yet",
    InvalidEmailFormat:"Invalid Email Format",
    PleaseEnterYourEmailWeWillSendYouInstructionToResetYourPassword:"Please Enter Your Email We Will Send You Instruction To Reset Your Password",


    SelectProduct:"Select Product",
    SelectSize:"Select Size",
    Sortir:"Sortir",
    SortBy:"Sort By",
    Ascending:"Ascending",
    Descending:"Descending",
    Confirmation:"Confirmation",
    UpdateConfirmationMessage:"Do you want to update your content",
    DeleteConfirmationTitle:"Delete Confirmation",
    DeleteConfirmationMessage:"Do you want to delete the selected content",

    Created:"Created",
    Name:"Name",
    BrandName:"Brand Name",
    Heading:"Menu",
    Category:"Sub Menu",
    Updated:"Updated",
    Create:"Create",
    Update:"Update",
    Add:"Add",
    SelectStatus:"Select Status",
    Active:"Active",
    Inactive:"Inactive",
    Expired:"Expired",
    All:"All",
    AllInventory:"All Inventory",
    Type:"Type",
    Quantity:"Quantity",
    Weight:"Weight",
    Price:"Price",
    Fullname:"Fullname",
    Phone:"Phone",

    Search:"Search",
    SearchCreator:"SearchCreator",
    TypeAndEnterToSearch:"Enter the keyword..",
    SearchByName:"Search By Name",
    HashTag:"Hashtag",
    Image:"Image",
    Slug:"Slug",
    Status:"Status",
    LastStatus:"Last Status",
    PreviousStatus:"Previous Status",
    Warning:"Warning",
    PleaseCompleteTheFields:"Please Complete The Fields",
    ProductForm:"Product Form",
    UseImageTypeInstruction:(type, size)=>"Use "+type+" image type, with maximum size of file is "+size+" Kb",
    Upload:"Upload",
    EnterProductName:"Enter Product Name",
    EnterQuantity:"Enter Quantity",
    EnterSlug:"Enter Slug",
    EnterWeight:"Enter Weight",
    EnterPrice:"Enter Price",
    AutoSlug:"Auto Slug",
    SelectBrand:"Select Brand",
    SelectHeading:"Select Heading",
    SelectCategory:"Select Category",
    SelectFormType:"Select Form Type",
    RelatedHashTag:"Related HashTag",
    Description:"Description",
    EnterDescription:"Enter Description",
    Size:"Size",
    EnterSize:"Enter Size",
    EnterName:"Enter Name",
    EnterBrandName:"Enter Brand Name",
    EnterCategoryName:"Enter Category Name",
    EnterProvinceName:"Enter Province Name",
    SelectProvince:"Select Province",
    EnterCityName:"Enter City Name",
    SelectCity:"Select City",
    EnterDistrictName:"Enter District Name",
    SelectDistrict:"Select District",
    EnterVillageName:"Enter Village Name",
    SelectVillage:"Select Village",
    PostalCode:"Postal Code",
    Creator:"Creator",
    ContentOrStockLabel:"Content / Stock",
    SoldStock:"Sold Stock",
    Stock:"Stock",
    StockLog:"Stock History",
    SelectSortir:"Select Sortir",
    SelectDirection:"Select Direction",
    SelectInOurOut:"Select In / Out",
    SelectStockAuditType:"Select Auditn Type",
    StartDate:"Start Date",
    EndDate:"End Date",
    CreatedStartDate:"Created Start Date",
    CreatedEndDate:"Created End Date",
    UpdatedStartDate:"Updated Start Date",
    UpdatedEndDate:"Updated End Date",
    EnterStartDate:"Enter Start Date",
    EnterEndDate:"Enter End Date",
    Note:"Note",
    View:"View",
    IconClass:"Icon Class",
    EnterIconClass:"Enter Icon Class",
    InventoryForm:"Inventory Form",
    AdminForm:"Admin Form",
    CustomerForm:"Customer Form",
    PointForm:"Point Form",
    VoucherForm:"Voucher Form",
    PopularProduct:"Popular Product",
    LatestProduct:"Latest Product",
    Latest:"Latest",
    OrderQuantity:"Order Quantity",
    SoldOut:"Sold Out",
    Added:"Added",
    EnterBankLabel:"Enter Bank Label",
    EnterBankName:"Enter Bank Name",
    EnterBankCode:"Enter Bank Code",
    EnterAccountBankNumber:"Enter Bank Account Number",
    EnterAccountBankName:"Enter Bank Account Name",
    SMSBankingSenderId:"SMS Banking Sender Id",
    SMSBankingTemplate:"SMS Banking Template",
    EnterSmsBankingSenderId:"Enter Sms Banking Sender Id",
    SMSPrefixIndicator:"SMS Prefix Indicator",
    EnterSMSPrefixIndicator:"Enter SMS Prefix Indicator",
    CurrencyFormat:"Currency Format",
    CurrencyFormatExample:"Example : ##.###,##",
    EnterMobilePhone:"Enter Mobile Phone",
    EnterCustomerReceiverEmail:"Enter Customer Receiver Email",
    EnterSubscriberReceiverEmail:"Enter Subscriber Receiver Email",
    EnterSortir:"Enter Sortir",
    EnterPageLink:"Enter Page Link",
    TransactionCount:"Transaction Count",
    TransactionDate:"Tanggal Transaksi",
    FavoriteCount:"Transaction Date",
    CartCount:"Cart Count",
    OriginalPrice:"Original Price",
    FinalPrice:"Final Price",
    Select:"Select",
    Check:"Check",
    CompanyDescription:"Company Description",
    EnterCompanyDescription:"Enter Company Description",
    VoucherCode:"Voucher Code",
    Owner:"Owner",
    Theme:"Theme",
    Period:"Period",
    Benefit:"Benefit",
    Aggregate:"Aggregate",
    StartPeriod:"Start Period",
    EndPeriod:"End Period",
    SelectPeriod:"Select Period",
    SelectVoucherBenefit:"Select Voucher Benefit",
    SelectPointBenefit:"Select Point Benefit",
    SelectVoucherOwnerType:"Select Voucher Owner Type",
    SelectVoucherDiscountType:"Select Voucher Discount Type",
    SelectAggregateType:"Select Aggregate Type",
    MaxUsage:"Maximum Usage",
    CurrentUsage:"Current Usage",
    Publish:"Publish",
    Unpublish:"Unpublish",
    ValidDay:"Valid Day",
    PurchaseDiscount: "Purchase Discount",
    ShippingDiscount:"Shipping Discount",
    FreeShipping:"Free Shipping",
    PercentageDiscount : "Percentage Discount",
    PriceDiscount : "Price Discount",
    AllTime:"All Time",
    Dynamic:"Dynamic",
    Static:"Static",
    SpecificCustomer:"Specific Customer",
    AnyCustomer:"Any Customer",
    SingleItem:"Single Item",
    JoinItem:"Join Item",
    AnyItem:"Any Item",
    PointExchange:"Point Exchange",
    EnterVoucherCode:"Enter Voucher Code",
    EnterVoucherName:"Enter Voucher Name",
    ThemeColor:"Theme Color",
    AvailableItemForThisVoucher:"Available Item For This Voucher",
    MinimumPurchaseQuantity:"Minimum Purchase Quantity",
    AvailableStock:"Available Stock",
    CustomerWhoHasThisVoucher:"Customer Who Has This Voucher",
    SelectPointType:"Select Point Type",
    PurchaseItemPoin:"Purchase Item Point",
    PurchasePricePoin:"Purchase Price Point",
    PurchasePercentagePoint:"Purchase Percentage Point",
    FixedPoint:"Fixed Point",
    PointBonus:"Bonus Point",
    PurchaseMinimum:"Purchase Minimum",
    ProductItemBelongToThisPoin:"Product Item Belong To This Point",
    RegisteredForThisPoin:"Registered For This Point",
    RegisteredForAnotherPoin:"Registered For Another Point",
    NotRegisteredForAnyPoin:"Not Registered For Any Point",
    PublishConfirmation:"Publish Confirmation",
    UnpublishConfirmation:"Unpublish Confirmation",
    PublishConfirmationMessage:"Do you want to publish this point ?",
    UnpublishConfirmationMessage:"Do you want to unpublish this point ?",
    FlashSaleContent:"Flash Sale Content",
    Inactivate:"Inactivate",
    Activate:"Activate",
    InactivateConfirmation:"Inactivate Confirmation",
    ActivateConfirmation:"Activate Confirmation",
    InactivateFlashSaleConfirmationMessage:"Do you want to unactivate this flash sale ?",
    ActivateFlashSaleConfirmationMessage:"Do you want to activate this flash sale ?",
    SearchProduct:"Search Product",
    FlashSaleStock:"Flash Sale Stock",
    FilterCustomerSearch:"Filter Customer Search",
    RegistrationStartDate:"Registration Start Date",
    RegistrationEndDate:"Registration End Date",
    Gender:"Gender",
    FilterCustomerByTransaction:"Customer Search Filtering By Transaction Filter",
    TransactionStartDate:"Transaction Start Date",
    TransactionEndDate:"Transaction End Date",
    MobilePhone:"Mobile Phone",
    TransactionDuring:"Transaction During",
    TransactionFavoriteCartColumnLabel:"Trx/Fav/Cart",
    Address:"Address",
    CustomerAddress:"Customer Address",
    ListOfDestinationAddress:"List Of Destination Address",
    EnterAddress:"Enter Address",
    Firstname:"Firstname",
    EnterFirstname:"Enter Firstname",
    Lastname:"Lastname",
    EnterLastname:"Enter Lastname",
    EnterEmail:"Enter Email",
    EnterPhoneNumber:"Enter Phone Number",
    BirthDate:"Birth Date",
    EnterBirthDate:"Enter Birth Date",
    SelectGender:"Select Gender",
    TransactionItemQuantity:"Transaction Item Quantity",
    TotalPay:"Total Payment",

    /**/
    // Transaction Status
    /**/
    Paid:"Paid",
    Packaging:"Packaging",
    Delivery:"Delivery",
    Done:"Done",
    Rejected:"Rejected",
    OnReturn:"OnReturn",
    AcceptedReturn:"Accepted Return",
    Order:"Order",
    /**/
    // End Transaction Status
    /**/

    Payment:"Payment",
    PaymentFee:"PaymentFee",
    ResiAndTracking:"Resi & Tracking",
    Print:"Print",
    SearchByReferenceCode:"Search By Reference Code",
    YourCustomerCart:"Your Customer Cart",
    RemoveAllCustomerCartConfirmationMessage:"Do you want to remove every customer's cart ?",
    RemoveCustomerCartConfirmationMessage:"Do you want to remove this customer's cart ?",
    RemoveAllCustomerCart:"Remove All Customer Cart",
    RemoveCartRespectively:"Remove Cart Respectively",
    PerCustomer:"Per Customer",
    RemoveHisCart:"Remove His Cart",
    SelectType:"Select Type",
    SearchCustomer:"Search Customer",
    CustomerName:"Customer Name",
    SearchAdmin:"Search Administrator",
    Attribute:"Attribute",
    AttributeForm:"Attribute Form",
    Open:"Open",
    OpenPageLink:"Open Pagelink",
    PageLink:"Page Link",
    EnterColorName:"Enter Color Name",
    Hexa:"Hexa",
    EnterLabel:"Enter Label",
    Label:"Label",
    SendEmail:"Send Email",
    Subject:"Subject",
    SendAt:"Send At",
    SendAnnouncement:"Send Announcement",

    PublishConfigurationConfirmationMessage:"Do you want to publish this configuration ? Configuration would be implemented through your website setting",
    ConfigurationDetail:"Configuration Detail",
    PageRow:"Page Row",
    FavoriteRow:"Favorite Row",
    LatestRow:"Latest Row",
    DiscountRow:"Discount Row",
    EnterDiscountRow:"Enter Discount Row",
    FlashSaleRow:"Flash Sale Row",
    EnterFlashSaleRow:"Enter Flash Sale Row",
    SelebgramRow:"Selebgram Row",
    EnterSelebgramRow:"Enter Selebgram Row",
    ImageSize:"Image Size",
    MainProductMaximumRow:"Main Product Maximum Row",
    EnterMainProductMaximumRow:"Enter Main Product Maximum Row",
    EnterCourierApiName:"Enter Courier Api Name",

    FreePaymentFee:"Free Payment Fee",
    BubbleWrapFee:"Bubble Wrap Fee",
    CompanyName:"Company Name",
    EnterCompanyName:"Enter Company Name",
    CompanyPhoneNumber:"Company Phone Number",
    EnterCompanyPhoneNumber:"Enter Company Phone Number",
    ContactPersonPhoneNumber:"Contact Person Phone Number",
    EnterContactPersonPhoneNumber:"Enter Contact Person Phone Number",
    ProductRelatedSize:"Product Related Size",
    EnterProductRelatedSize:"Enter Product Related Size That You Need To Display",
    EnterFavoriteRow:"Enter Favorite Row That You Need To Display",
    EnterLatestRow:"Enter Latest Row That You Need To Display",
    InventoryImageSize:"Maximum Inventory Image Size",
    EnterInventoryImageSize:"Enter Maximum Inventory Image Size",
    VoucherCodeLength:"Voucher Code Length",
    EnterVoucherCodeLength:"Enter Voucher Code Length",
    BankImageDimension:"Bank Image Dimension",
    Width:"Width",
    Height:"Height",
    BrandImage:"Brand Image",
    MidtransMediatorImageDimension:"Midtrans Mediator Image Dimension",
    CourierImageDimension:"Courier Image Dimension",
    ProductImageDimension:"Product Image Dimension",
    TransactionConfiguration:"Transaction Configuration",
    CourierServiceBaseURL:"Courier Service Base URL",
    EnterCourierServiceBaseURL:"Enter Courier Service Base URL",
    CourierServiceApiKey:"Courier Service Api Key",
    EnterCourierServiceApiKey:"Enter Courier Service Api Key",
    ShippingOriginAddress:"Shipping Origin Address",
    EnterShippingOriginAddress:"Enter Shipping Origin Address",
    SocialMedia:"Social Media",
    EnterHashTag:"Enter #hashtag",
    InstagramLink:"Instagram Link",
    EnterInstagramLink:"Enter Instagram Link",
    FacebookLink:"Facebook Link",
    EnterFacebookLink:"Enter Facebook Link",
    TwitterLink:"Twitter Link",
    EnterTwitterLink:"Enter Twitter Link",
    FacebookApplicationId:"Facebook Application Id",
    EnterFacebookApplicationId:"Enter Facebook Application Id",
    GoogleApplicationId:"Google Application Id",
    EnterGoogleApplicationId:"Enter Google Application Id",
    TawkToReference:"Tawk To Reference",
    EnterTawkToReference:"Enter Tawk To Reference",
    SelectRole:"Select Role",
    ResendPassword:"Resend Password",
    AccessMenu:"Access Menu",
    Menu:"Menu",
    Domain:"Domain",
    ClientKey:"Client Key",
    ServerKey:"Server Key",
    Environment:"Environment",
    PaymentLimitExpiration:"Payment Expiration Limit",
    BankCode:"Bank Code",
    BankAccountNumber:"Bank Account Number",
    BankAccountName:"Bank Account Name",
    SelectBank:"Select Bank",
    BalanceInformation:"Balance Information",
    History:"History",
    BalanceMutation:"Balance Mutation",
    Mutation:"Mutation",
    WithdrawalHistory:"Withdrawal History",
    ReferenceCode:"Reference Code",
    Reference:"Reference",
    Credit:"Credit",
    Debit:"Debit",
    SelectDebitOrCredit:"Select Debit / Credit",
    Amount:"Amount",
    RemainBalance:"RemainBalance",
    WithdrawalConfirmationTitle:"Withdrawal Confirmation",
    RequestWithdrawConfirmationMessage:(amount)=>"You will issued withdrawal with amount "+amount+", " +
        "\nPlease ensure you have entered bank account & account number well. " +
        "We will proceed around 2x24 hours. " +
        "Please wait or if you need to ask, do not hestitate to talk with our customer support, Thank You",
    YesIamSure:"Yes Im Sure",
    Sure:"Sure",
    ReCheck:"Recheck",
    WarningWithdrawExist:"You have another incomplete withdrawal request, please wait, Our staff would process it soon",
    Waiting:"Waiting",
    Processing:"Processing",
    Issued:"Issued",
    Postponed:"Postponed",
    StatusHistory:"Status History",
    WithdrawalStatusHistory:"Withdrawal Status History",
    WaitingForPayment:"Waiting For Payment",
    WaitingForSubscriptionPayment:"Waiting For Subscription Payment",
    WaitingForUpgradePayment:"Waiting For Upgrade Payment",
    ExpiredOn:"Expired On",
    Channel:"Channel",
    Account:"Account",
    Total:"Total",
    AccountInformation:"Account Information",
    RegisteredOn:"Registered At",
    ActivePeriod:"Active Period",
    YourProductService:"Your Product Service",
    ExtendPeriod:"Extend Period",
    PayNow:"Pay Now",
    PAY_NOW:"PAY NOW",
    RequestUpgrade:"Request Upgrade",
    REQUEST_UPGRADE:"REQUEST UPGRADE",
    MonthlyPeriod:"Monthly Period",
    Month:"Month",
    Months:"Months",
    ProductOption:"Product Option",
    AdditionalPayment:"Additional Payment",
    AdditionalDiscount:"Additional Discount",
    PaymentMethod:"Payment Method",
    ProductInformation:"Product Information",
    ProductName:"Product Name",
    ValidUntil:"Valid Until",
    Item:"Item",
    PleaseEnterReceiverBankAccountOnYourAccountPage:"Please complete your receiver bank account, it can be complete it on Account Page and only can be accessed by owner !",
    Refresh:"Refresh",
    TotalCustomer:"Total Customer",
    TotalCustomerTransaction:"Total Customer Transaction",
    PreviousMonth:"Previous Month",
    ThisMonth:"This Month",
    PreviousYear:"Previous Year",
    ThisYear:"This Year",
    TotalTransaction:"Total Transaction",
    TotalTransactionAmount:"Total Transaction Amount",
    OnGoingTransaction:"On Going Transaction",
    Date:"Date",
    SelectCriteria:"Select Criteria",
    TotalAmount:"Total Amount",
    BubbleWrapAmount:"Bubble Wrap Amount",
    CourierAmount:"Courier Amount",
    TotalQuantity:"Total Quantity",
    PurchaseItemQuantity:"Purchase Item Quantity",
    EnterYourTicketMessageHereInstruction:"Enter your message here... \nYou can also add an image attachment for more clearly information..",
    Attachment:"Attachment",
    PhysicalServiceAddress:"Physical Service Address",
    PublicDomain:"Public Domain",
    ProductSubscribe:"Product Subscribe",
    SubscribePeriod:"Subscribe Period",
    CloudVPS:"Cloud VPS",
    VPSInformation:"VPS Information",
    Yes:"Yes",
    TotalRecord:"Total Record",
    TotalPage:"Total Page",
    YourAccount:"Your Account",
    TakeAPayment:"Take A Payment",
    ExtendNow:"Extend Now",
    DoYouWantToLogoutFormThiApplication:"Do You Want To Logout Form This Application ?",
    RegistrationDate:"Registration Date",
    Until:"Until",

    Branch:"Branch",
    Title:"Title",
    EnterTitle:"Enter Title",
    FlashSaleName:"Flash Sale Name",
    EnterFlashSaleName:"Enter Flash Sale Name",
    EnterSenderEmail:"Enter Sender Email",
    WhenItCouldBeSent:"When It Could Be Sent ?",
    EnterNoteRecommended:"Enter Note (Recommended)",
    EnterResiCode:"Enter Resi Code",
    EnterDailyOperationInAPlainWords:"Enter Daily Operation In Plain Words",
    EnterSettingName:"Enter Setting Name",
    EnterStock:"Enter Stock",
    EnterPercentOfDiscount:"Enter Percent Of Discount",
    ChangePassword:"Change Password",
    OldPassword:"Old Password",
    NewPassword:"New Password",
    Receiver:"Receiver",
    Origin:"Origin",
    Destination:"Destination",
    DailyOperation:"Daily Operation",
    UpdateResiCode:"Update Resi Code",
    SubjectEmail:"Subject Email",
    Base:"Base",
    Api:"Api",
    Simulator:"Simulator",
    EditFlashSale:"Edit Flash Sale",
    CreateFlashSale:"Create Flash Sale",
    Scheduled:"Scheduled",
    MessageForm:"Message Form",
    ReplyFrom:"Reply From",
    SeeTicket:"See Ticket",
    CreateTicket:"Create Ticket",
    InvalidInputOfWaybill:"Invalid Input Of Waybill",
    No:"No",
    VoucherPublishMessage:"Do you want to publish this voucher to your customer ?",
    VoucherUnpublishMessage:"Do you want to unpublish this voucher from your customer ?",
    OriginAddress:"Origin Address",
    StockAllocation:"Stock Allocation",
    Allocation:"Allocation",
    SpecialCloudVPS:"Special Cloud VPS",
    AdvanceFilter:"Advance Filter",
    ClickToExpandFilterContent:"Click To Expand Filter Content",
    transactionStartDate:"Transaction Start Date",
    transactionEndDate:"Transaction End Date",
    DestinationProvince:"Destination Province",
    DestinationCity:"Destination City",
    DestinationDistrict:"Destination District",
    DestinationVillage:"Destination Village",
    WithVoucher:"With Voucher",
    EnterNameOrEmailOrPhoneNumberOrRefCodeOrResiCode:"Enter name, email, phone number, reference code or resi code",
    ApplyFilter:"Apply Filter",
    ResetFilter:"Reset Filter",
    DownloadExcel:"Download Excel",
    DeliveryDuration:"Delivery Duration",
    Main:"Main",
    Profile:"Profile",
    Help:"Help",
    Guide:"Guide",
    Signout:"Signout",
    Message:"Message",
    Ticket:"Ticket",
    ListOfImage:"List Of Image",
    EnterHeadingName:"Enter heading name",
    SearchFlashSaleName:"Search flashsale name...",
    Information:"Information",
    EmailSubjectCannotBeingEmpty:"Email subject cannot being empty",
    IfYouChooseScheduledModeThenSendAtCannotBeingEmpty:"If you choose schedule mode, then send at field cannot being empty",
    IfYouChooseNonRegulerThenSenderFieldCannotBeingEmpty:"If you choose non reguler then sender field cannot being empty",
    PleaseEnterAMinimumSingleReceiver:"Please enter a minimum single receiver",
    PleaseEnterTheContentOfThisAnnouncement:"Please enter the content of this announcement",
    DoYouWantToSendThisAnnouncementRightNowPleaseItCannotBePostponed:"Do you want to send this announcement right now, please be careful, it can't be canceled",
    DoYouWantScheduleThisAnnouncementAtTheMomentYouFillInPleaseItCannotBePostponed:"Do you want to make a schedule foor this announcement at the time you fill in, please be careful, it can't be canceled",
    RegularSender:"Regular Sender",
    RightNow:"Right Now",
    AllCustomer:"All Customer",
    AllSubscriber:"All Subscriber",
    AnnouncementContentOptions:"Announcement Content Options",
    ManualTyping:"Manual Typing",
    UploadHtmlFile:"Upload Html File",
    CustomerReceiver:"Customer Receiver",
    Sender:"Sender",
    PreviewHtmlContent:"Preview Html Content",
    PreviewContent:"Preview Content",
    WriteYourCSSInline:"Write your CSS inline or on your head tag, so it only need a single file html",
    UseImageWichisAlreadyStoredOnServer:"Use image wich can be accessed by public internet, and insert the src url to image src inline like https://domain.com/image.jpg, " +
        "or you may use also base 64 format",
    ThirdPartyKeys:"Third Party Keys",
    ListMenu:"List Menu",

    AppName:"Abacus QC Inspector",
    LOGIN:"LOGIN",
    SIGNUP:"SIGNUP",
    UpdateConfirmation:"Update Confirmation",
    Logout:"Logout",
    TotalUser:"Total User",
    TotalInspection:"Total Inspection",
    TotalInspectionDone:"Total Inspection Done",
    TotalTransfer:"Total Transfer",
    OnGoingInspection:"On Going Inspection",
    Pass:"Pass",
    Taken:"Taken",
    NotPass:"NotPass",
    Closed:"Closed",
    Suspended:"Suspended",
    Opened:"Opened",
    OnWorking:"On Working",
    UserCode:"User Code",
    ModelCategory:"Model Category",
    BalanceOfAllUser:"Balance Of All User",
    PaidAmountOfAllUser:"Paid Amount Of All Users",
    Success:"Success",
    ModelCategoryDetail:"Model Category Detail",
    MONTHS_LABEL:["January", "February","March","April","May","June","July","Augustus","September","October","November","December"],
    Failed:"Failed",
    ModelCount:"Model Count",
    EnterFullname:"Enter Fullname",
    EnterMessage:"Enter Message",
    EnterModelCategoryName:"Enter model category name",
    AddModel:"Add Model",
    AddBrand:"Add Brand",
    Fee:"Fee",
    Limitation:"Limitation",
    InspectionLimit:"Inspection Limit",
    User:"User",
    InspectionDone:"Inspection Done",
    Website:"Website",
    Suggestion:"Showing Order",
    Publication:"Publication",
    Published:"Published",
    Unpublished:"Unpublished",
    ModelList:"Model List",
    GroupForm:"Group Form",
    GroupFormDetail:"Detil Grup Form",
    GroupFormList:"Grup Form List",
    AddFormBuilder:"Add Form Builder",
    FormBuilder:"Form Builder",
    FormName:"Form Name",
    EnterFormName:"Enter Form Name",
    FormPage:"Form page",
    AddFormPage:"Add Form page",
    EnterFormPage:"Enter Form page",
    FormCard:"Form Input",
    AddFormCard:"Add Form Input",
    Tag:"Tag",
    EnterTag:"Enter tag",
    Mandatory:"Mandatory",
    True:"True",
    False:"Tidak",
    UnallowedCharacters:"Unallowed Characters",
    EnterUnallowedCharacters:"Enter Unallowed Characters",
    EnterFormType:"Enter form type",
    EnterFormTitle:"Enter form title",
    Placeholder:"Placeholder",
    EnterPlaceholder:"Enter placeholder",
    MaxLength:"Max Length",
    EnterMaxLength:"Enter max length",
    LineCount:"Line Count",
    EnterLineCount:"Enter line count",
    ImageDescription:"Image Description",
    ImageDirectory:"Image Directory",
    ExampleReplacer:"Example replacer",
    ExampleDirectoryFormat:"Example directory format",

    PleaseEnterFormTag:"Please enter a form tag",
    PleaseEnterAFormTitle:"Please enter a form title",
    SaveForm:"Save Form",
    UserList:"User List",
    Installation:"Installation",
    Installed:"Installed",
    Uninstalled:"Uninstalled",
    SoftwareInstallation:"Software Installation",
    LastOpen:"Last Open",
    EnterReceiverEmail:"Enter receiver emails",
    SlugAlreadyBeenUsed:"Slug already been used",
    Subtitle:"Subtitle",
    EnterSubtitle:"Enter Subtitle",
    PleaseEnterFormName:"Mohon masukkan nama form",
    EnterRoleName:"Enter role name",
    EnterMidtransMediatorLabel:"Enter midtrans mediator label",
    EnterMidtransMediatorName:"Enter midtrans mediator name",

    EnterGroupMenu:"Enter group menu",
    EnterQueryKey:"Enter query's key",
    EnterReference:"Enter reference",
    EnterRecommendedInformation:"Enter additional information (Recommended)",
    EnterRecommendedReceiptCode:"Enter receipt code (Recommended)",
    EnterFAQTitle:"Enter FAQ title",
    EnterFAQSubTitle:"Enter FAQ subtitle",
    EnterWebsite:"Enter website",
    EnterBusinessField:"Enter business field",
    EnterBuildDate:"Enter build date",
    EnterCompanyAddress:"Enter company address",
    EnterStartPeriod:"Enter start period",
    EnterEndPeriod:"Enter end period",
    EnterIdCardNumber:"Enter ID Card / Government ID",
    ClickButtonToGenerate:"Click button to generate",
    Organization:"Organization",
    EnterOrganization:"Enter Organization",
    EnterAccountName:"Enter account name",
    AccountName:"Nama Akun",
    EnterAccountNumber:"Enter account number",
    AccountNumber:"Account Number",
    EnterForSpecificEmailSender:"Enter for specific email sender",
    ModelDetail:"Model Detail",
    TimeValidating:"Time Validating",
    ModelIconRatio:"Model Icon, Dimention 1 (width) * 1 (height)",
    InspectionDetail:"Inspection Detail",
    ModelAddressLink:"Model Link Address",
    Platform:"Platform",
    DeviceId:"Device ID",
    FCMToken:"Firebase Cloud Messaging Token",
    Delete:"Delete",
    SearchDeviceId:"Search device id...",
    ManufacturingSite:"Manufacturing Site",
    ManufacturingSiteDetail:"Manufacturing Site Detail",
    EnterManufacturingSiteName:"Enter manufacturing site name",
    PleaseSelectManufacturingSite:"Please select manufacturing site",
    SequenceStartSerialNumber:"Sequence Start Serial Number",
    EnterSequenceStartSerialNumber:"Enter Sequence Start Serial Number",
    Other:"Other",



}
const baru = {
    SearchByCustomerNameOrModelNameOrRefCode:"Search by user, model or ref code...",
    LimitType:"Limit Type",
    Limit:"Limit",
    VerificationType:"Verification Type",
    WorkerVerificationType:"Worker Verification Type",
    InspectionCount:"Inspection Count",
    TotalFee:"Total Fee",
    InspectionReferenceCode:"Inspection Reference Code",
    BrandReferenceCode:"Brand Reference Code",
    StatusHistory:"Status History",
    BrandStatus:"Brand Status",
    ModelApplicationInfo:"Model Application Info",
    SelectVerificationStatus:"Select Verification Status",
    SelectUserStatus:"Select User Status",
    ReferralAgent:"Referral User",
    EverLoggedIn:"Ever Logged In",
    Never:"Never",
    TotalIncome:"Total Income",
    CurrentBalance:"Current Balance",
    CompletedInspection:"Completed Inspection",
    PostponedInspection:"Postponed Inspection",
    IDCard:"ID Card",
    InvalidEmailAddress:"Invalid email address",
    Regenerate:"Regenerate",
    Generate:"Generate",
    UserDetail:"User Detail",
    AddUser:"Add User",
    LocationOnMap:"Location On Map",
    OpenInMap:"Open In Map",
    EnterTitleSubtitleSlugBrandOrCategoryToSearch:"Enter title, description, slug, brand, or category to search..",
    SearchNameEmailPhoneUserCodeOrOrganization:"Search name, email, phone, or user code.. ",
    LastOpenStart:"Last Open Start",
    LastOpenEnd:"Last Open End",
    Male:"Male",
    Female:"Female",
    UnrecognizedDueNotAuthenticated:"Unrecognized due not authenticated",
    EnterDeviceIdUserCodeOrNameToSearch:"Enter device id, user code, or name to search..",
    AuthenticatedAsUser:"Authenticated",
    NotAuthenticatedAsUser:"Not Authenticated",
    AuthenticationOption:"Authentication Option",
    ReceiverOption:"Receiver Option",
    NotificationDetail:"Notification Detail",
    SendingConfirmationTitle:"Sending Confirmation",
    DoYouWantToSendThisNotificationWithTheSelectedTopic:"Apakah anda ingin mengirim notifikasi ini dengan spesifikasi topic yang anda isi ?",
    InspectionMustBeingOrderedSequentially:"Inspection must being ordered sequentially one by one",
    InspectionCanBeAppliedMoreThanOneSimultaneously:"Inspection can be applied more than one in simultaneously",
    InspectionSubmissionOrder:"Inspection Order",
    MaxUsagePerUser:"Max usage per User",
    MaxInspectionPerUserDefaultUnlimitedPlaceholder:"Max inspection per User (Default 0 for unlimited)",
    MaximumInspectionCanBeApplied:"Maximum inspection can be applied",
    MaximumApplicationUsage:"Maximum Inspection Usage",
    CurrentApplicationUsage:"Current Inspection Usage",
    MaxUsagePerUserCannotBeLargerThanMaxUsageGeneral:"Max inspection per user cannot be larger than general max usage",
    MaxUsageCannotBeSmallerThanCurrentUsage:"Max inspection cannot be smaller than current usage",
    PleaseEnterModelAddressLink:"Please complete model address link",
    PleaseEnterCallbackId:"Please enter callback id",
    PleaseEnterGroupForm:"Please enter group form",
    PleaseEnterModelTimeValidationByAdmin:"Please enter model time validation by admin",
    ModelFee:"Model Fee",
    PleaseEnterModelFee:"Please enter model fee",
    CanBeAppliedByUserVerificationAt:"Can be applied by user verification at",
    CanOnlyBeAppliedByVerifiedUser:"Can onlu be applied by verified user",
    CanBeAppliedByUnverifiedUser:"Can be applied by unverified user",
    TimeApplicationValidationByAdminInHour:"Time Application Validation Process By Admin (In Hour)",
    PleaseEnterDescription:"Please enter description",
    PleaseEnterReferenceDocument:"Please enter additional info",
    ReferenceDocument:"Reference Document",
    MainModelDetail:"Main Model Detail",
    EnterMainModelLabel:"Enter main model label",
    SelectModel:"Select Model",
    MainModelIcon:"Main model icon",
    MultiFinanceDetail:"Multi Finance Detail",
    Label:"Label",
    EnterMultiFinanceLabel:"Enter multi finance label",
    EnterMultiFinanceName:"Enter multifinance name",
    EnterLabelOfInstallmentPeriod:"Masukkan label dari tenor",
    EnterPeriodOfInstallmentPeriod:"Masukkan tenor",
    InstallmentPeriod:"Installment Period",
    InstallmentPeriodDetail:"Installment Period Detail",
    EnterDiscussionCategoryName:"Enter discussion category name",
    DiscussionCategoryDetail:"Discussion Category Detail",
    AgentFee:"User Fee",
    AgentFeeDetail:"User Fee Detail",
    SelectProductAgent:"Select Product User",
    SelectMultiFinance:"Select MultiFinance",
    AmountFee:"Amount Fee",
    PercentFee:"Percent Fee (%)",
    SelectInstallmentPeriod:"Select Installment Period",
    PreviewCount:"Preview Count",
    ShareCount:"Share Count",
    CommentCount:"Comment Count",
    ReplyCount:"Reply Count",
    Comment:"Comment",
    CommentReply:"Comment Reply",
    PartName:"Part Name",
    PN:"P/M",
    ACInput:"AC Input",
    Frequency:"Frequency",
    ACInputCurrent:"Ac Input Current",
    DCOutput:"DC Output",
    Polarity:"Polarity",
    CORef:"CO Ref",
    Warranty:"Warranty",

    EnterPartName:"Enter Part Name",
    EnterPn:"Enter PN",
    EnterACInput:"Enter AC Input",
    EnterFrequency:"Enter Frequency",
    EnterACInputCurrent:"Enter Ac Input Current",
    EnterDCOutput:"Enter DC Output",
    EnterPolarity:"Enter Polarity",
    EnterCORef:"Enter CO Ref",
    EnterWarranty:"Enter Warranty",




}

const menu = {
    Dashboard:"Dashboard",
    Account:"Account",
    Product:"Product",
    Brand:"Brand",
    Heading:"Menu",
    Category:"Category",
    ItemProduct:"Item Product",
    Inventory:"Inventory",
    Popular:"Popular",
    Latest:"Latest",
    MainProduct:"Main Product",
    StockAudit:"Stock Audit",
    Program:"Program",
    Discount:"Discount",
    Voucher:"Voucher",
    Point:"Point",
    PointName:"Point Name",
    FlashSale:"Flash Sale",
    Shopper:"Shopper",
    Customer:"Customer",
    Transaction:"Transaction",
    Cart:"Cart",
    Activity:"Activity",
    CustomerActivity:"Customer Activity",
    AdminActivity:"Admin Activity",
    Miscellaneous:"Miscellaneous",
    Banner:"Banner",
    Selebgram:"Selebgram",
    Color:"Color",
    Size:"Size",
    OnlineShop:"Online Shop",
    Branches:"Branches",
    CustomerGuide:"Customer Guide",
    Announcement:"Announcement",
    Broadcast:"Broadcast",
    Subscriber:"Subscriber",
    Administrator:"Administrator",
    Configuration:"Configuration",
    Admin:"Admin",
    Role:"Role",
    UserRole:"User Role",
    PaymentMedia:"Payment Media",
    Bank:"Bank",
    BankAccount:"Bank Account",
    MidtransMediator:"Midtrans Mediator",
    Courier:"Courier",
    Region:"Region",
    Province:"Province",
    City:"City",
    District:"District",
    Village:"Village",
    PaymentTypeCode:"Payment Type Code",
    EnterPaymentTypeCode:"Enter Payment Type Code",
    AmountFee:"Amount Fee",
    PercentFee:"Percent Fee",
    EnterAmountFee:"Enter Amount Fee",
    EnterPercentFee:"Enter Percent Fee",
    EnterCourierLabel:"Enter Courier Label",
    CourierCode:"Courier Code",
    Code:"Code",
    RequestWithdrawal:"Request Withdrawal",
    TransactionDetail:"Transaction Detail",
    TransactionInfo:"Transaction Info",
    ResiCode:"Resi Code",
    CourierCost:"Courier Cost",
    Service:"Service",
    TransactionStatus:"Transaction Status",
    ItemPrice:"Item Price",
    UniquePrice:"Unique Price",
    BubbleWrap:"Bubble Wrap",
    ShippingAddress:"Shipping Address",
    TransactionItem:"Transaction Item",
    TotalPrice:"Total Price",
    Source:"Source",
    TotalWeight:"Total Weight",
    UpdateStatus:"Update Status",
    CurrentStatus:"Current Status",

    Business:"Business",
    Inspection:"Inspection",
    InspectionScheme:"Inspection Scheme",
    InspectionEvent:"Inspection Event",
    SubMenu:"Sub Menu",
    Model:"Model",
    FormBuilder:"Form Builder",

    Worker:"Worker",
    QCManagement:"QC Management",
    Devices:"Devices",
    UserDevices:"User Devices",
    ConfigurationAdmin:"Configuration Admin",
    AdminDevices:"Admin Devices",
    User:"User",
    Verification:"Verification",
    RegHistory:"Reg History",
    Withdraw:"Withdraw",
    WithdrawalRequest:"Withdrawal Request",
    Mutation:"Mutation",
    UserActivity:"User Activity",
    Notification:"Notification",
    ApplicationVersion:"Application Version",
    MainModel:"Main Model",
    Reward:"Reward",
    Fee:"Fee",
    MultiFinance:"Multi Finance",
    InstallmentPerio:"Installment Period",
    Discussion:"Discussion",
    DiscussionCategory:"Discussion Category",
    CategoryImage:"Category Image",
    UserPhoto:"User Photo",
    IPAddress:"IP Address",
    UserAgent:"User Agent",
    SystemType:"System Type",
    ModelName:"Model Name",
    Form:"Form",
    EnterSystemType:"Enter system type name / model",
    EnterModelName:"Enter model name",
    Scope:"Scope",
    TestingEquipmentAndAccessories:"Testing Equipment And Accessories",
    SystemSpecification:"System Specification",
    SerialComponent:"Serial Component",
    Value:"Value",
    PassInspection:"Pass Inspection",
    NotPassInspection:"Not Pass Inspection",
    PostponedInspection:"Postponed Inspection",
    SerialCode:"Serial Code",
    InspectionList:"Inspection List",
    BrandCategory:"Category",
    SelectBrandCategory:"Select Category",
    Searching:"Searching",
    SearchReferenceSerialModelUser:"Search reference code, serial code, model, customer, client or user.. ",
    Copy:"Copy",
    CopyToTheNewFormBuilder:(formName) => "Copy ("+formName+") to the new form builder",
    CopyToTheNewForm:(formName) => "Copy ("+formName+") to the new form",
    NewFormBuilderName:"new Form Name",
    EnterNewFormBuilderName:"Enter new form name",
    CreatePage:"Page Creator",
    PageTitle:"Page Title",
    EnterPageTitle:"Enter page Tttle",
    PleaseEnterPageTitle:"Please enter page Title",
    ApiDescription:"API Description",
    PositiveLabel:"Positive Label",
    EnterPositiveLabel:"Enter positive label",
    NegativeLabel:"Negative Label",
    EnterNegativeLabel:"Enter negative label",
    HidePrintout:"Hide Printout",
    FormInputConfiguration:"Form Input Configuration",
    SizeOfRectifierControllerSerialNumber:"Size Of Rectifier/Controller SN",
    SubInput:"Sub Input",
    Type:"Type",
    DoYouWantToRemoveThisFormInput:"Do you want to remove this form input ?",
    DoYouWantToRemoveThisLane:"Do you want to remove this page ?",
    QualityController:"Quality Controller",
    YourInspectionHistory:"Your Inspection History",
    ThereIsNoHistory:"There is no history",
    NewInspection:"New Inspection",
    SerialCodeOrReferenceCode:"Serial code or reference code",
    SearchInspection:"Search Inspection",
    ContinueInspection:"Return Inspection",
    InspectionDetailInfo:"Inspection Detail Info",
    CreateNewInspection:"Create New Inspection",
    ModelOrSystemType:"Model / System Type",
    ItemInspectionIsNotAvailable:"Itenm Inspection Is Not Available",
    DoYouWantToCreateNewInspection:"Do you want to create a new inspection ?",
    DoYouWantToContinueInspection:"Do you want to continue inspection ?",
    InspectionItem:"Inspection Item",
    TakingInspectionItem:"Taking Inspection Item",
    ModelInformation:"Model Information",
    CurrentInspectionStatus : "Current Inspection Status",
    PerformedBy : "Performed By",
    PreviousInspectionUser:"Previous Inspection User",
    PreviousInspectionStatus:"Previous Inspection Status",
    PreviousInspectionDate:"Previous Inspection Date",
    LastInspectionPerformedBy:"Last Inspection Performed By",
    LastInspectionDate:"Last Inspection Date",
    LastInspectionStatus:"Last Inspection Status",
    InspectionStatus:"Inspection Status",
    Next:"Next",
    Previous:"Previous",
    PageNext:"Next >>",
    PagePrevious:"<< Previous",
    InspectionInstruction:"Inspection Instruction",
    TakePicture:"Take Picture",
    SetPicture:"Set Picture",
    OkIUnderstand:"OK, I Understand",
    RectifierControllerSerialNumber:"Controller/Rectifier Serial Number",
    ConfirmSubmission:"Confirm Submission",
    ScanTheSerialNumber:"Scan The Serial Number",
    OrEnterItManually:"Or Enter It Manually",
    YouWillSubmitInspectionWithReferenceVariable1AndSerialCodeVariable2WithStatusVariable3_AreYouSure : (reference, serialCode, status) =>
        "You will submit inspection with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong>and inspection status is become<strong><h3>"+status+"</h3></strong><p>, are you sure with this information ?</p>",
    YouWillCloseInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "You will close inspection with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong>and inspection status is become<strong><h3>"+status+"</h3></strong><p>, are you sure with this information ?</p>",
    YouWillPostponeInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "You will postpone inspection with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>This action will cause this inspection able to take by another user ?</p>",
    YouWillSuspendInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "You will suspend inspection with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>This action will cause this inspection unable to take by any user ?</p>",
    YesImSure:"Yes, Im sure",
    PleaseEnterAFieldValueVariable : (variableName) => "Please enter "+variableName,
    EnterMessageIfYouNeedTo:"Enter message if you need to",
    EnterNoteIfYouNeedTo:"Enter note if you need to",
    InspectionMessage:"Inspection Message",
    InspectionNote:"Inspection Note",
    Preview:"Preview",
    FormInspectionDetail:"Form Inspection Detail",
    CloseStatusAndPrint:"Close Status & Print",
    ClickToExpandMoreInformation:"Click to expand more information",
    CLoseStatus:"Close",
    SuccessUpdatingStatus:"Success Updating Status",
    FailedUpdatingStatus:"Failed Updating Status",
    Reprint:"Reprint",
    Checking:"Checking",
    CheckingList:"Checking List",
    UserStatus:"User Status",
    AdminStatus:"Admin Status",
    CheckingHistory:"Checking History",
    CheckingActivity:"Checking Activity",
    PostponeInspection:"Postpone Inspection",
    InspectionOrderedSequentially:"Inspection ordered sequentially",
    SelectSystemType:"Select System Type",
    OpenedInspection:"Opened Inspection",
    NA:"NA",
    SuspendInspection:"Suspend Inspection",
    Reopen:"Reopen",
    DoYouWantToReopenThisInspection_ThisActionWillCauseTheInspectionCouldBeTakenByAnyUser:"Do you want to reopen this inspection ?\nThis action will cause the inspection could be taken by any user, are you sure ?",
    ContinueReOpenInspection:"Continue Reopen Inspection",
    PleaseEnterFirstName:"Please enter a valid firstname",
    PleaseGenerateARefCodeForThisUser:"Please generate reference code for this user",
    PleaseEnterEmail:"Please enter a valid email",
    PleaseSelectRoleForThisUser:"Please select role for this user",
    PleaseSelectRoleForThisAdmin:"Please select role for this admin",
    PleaseEnterARightMobilePhone:"Please enter valid mobile phone",
    DoYouWantToCreateANewUser:"Do you want to create a new user ?",
    DoYouWantToCreateANewAdmin:"Do you want to create a new admin ?",
    DoYouWantToUpdateInformationOfThisUser:"Do you want to update information of this user ?",
    DoYouWantToUpdateInformationOfThisAdmin:"Do you want to update information of this admin ?",
    SuccessUpdatingData:"Success updating data",
    DoYouWantToResendAPasswordForThisUser:"Do you want to resend a password for this user ?",
    DoYouWantToResendAPasswordForThisAdmin:"Do you want to resend a password for this admin ?",
    SuccessSendingThePassword:"Success sending the password",
    DoYouWantToUpdateThisUserStatusFrom : (previousStatus, nextStatus) => "Do you want to update this user status from "+previousStatus+" to "+nextStatus,
    DoYouWantToUpdateThisAdminStatusFrom : (previousStatus, nextStatus) => "Do you want to update this admin status from "+previousStatus+" to "+nextStatus,
    Reason:"Reason",
    StatusHistory:"Status History",
    BanThisDevice_TheUserWillBeBannedAfterThisPerform:"Ban This Device, The user will be banned from the application after this perform, are you sure ?",
    BanThisDevice_TheAdminWillBeBannedAfterThisPerform:"Ban This Device, The admin will be banned from the application after this perform, are you sure ?",
    ApplicationSetting:"Application Setting",
    Sequence:"Order",
    TimeActivity:"Time Activity",
    TimePerform:"Time Perform",
    ProvincePage:"Province Page",
    AdminDetailInformation:"Admin Detail Information",
    CreateNewAdmin:"Create New Admin",
    AdminCode:"Admin Code",
    NeverEnd:"Never End",
    Periodic:"Periodic",
    NoLimit:"No Limitation",
    Limited:"With Limitation",
    PleaseAddAMinimumSingleForm:"Please add at least a single form",
    PleaseEnterGroupFormName:"Please enter group form name",
    PleaseEnterPositiveLabel:"Please enter positive label",
    PleaseEnterNegativeLabel:"Please enter negative label",
    PleaseEnterPlaceHolder:"Please enter placeholder",
    NEVER_END:"Never End",
    PERIODIC:"Periodic",
    NO_LIMIT:"No Limitation",
    LIMITED:"With Limitation",
    CustomerOrderNo:"Customer Order No",
    Client:"Client",
    ProductSKU:"Product SKU",
    ProductName:"Product Name",
    Controller:"Controller",
    FWVersion:"FW Version",
    ControllerInfo:"Controller Info",
    CustomerInfo:"Customer Info",
    OrderNo:"Order No",
    DoYouWantToChangeYourPassword:"Do you want to change your password ?",
    OldPasswordMustBeEntered:"Old password must be entered",
    NewPasswordMustBeEntered:"New Password must be entered",
    ConfirmPasswordMustBeEntered:"Confirm password must be entered",
    PasswordMustAtLeastContain6Characters:"Password must at least contain 6 characters",
    OldPasswordAndNewPasswordCannotBeSame:"Old password and new password cannot be same",
    ConfirmPasswordAndNewPasswordIsNotSame:"Confirm password and new password is not same",
    Performer:"Performer",
    LastPerformer:"Last Performer",
    PreviousPerformer:"Previous Performer",
    TimeAgo_future: "in %s",
    TimeAgo_past: "%s ago",
    TimeAgo_s: 'a few seconds',
    TimeAgo_m: "a minute ago",
    TimeAgo_mm: "%d minutes ago",
    TimeAgo_h: "an hour ago",
    TimeAgo_hh: "%d hours ago",
    TimeAgo_d: "a day ago",
    TimeAgo_dd: "%d days ago",
    TimeAgo_M: "a month ago",
    TimeAgo_MM: "%d months ago",
    TimeAgo_y: "a year ago",
    TimeAgo_yy: "%d years ago",
    LastModification:"Last Modification",
    DocumentNumber:"Document Number",
    EnterDocumentNumber:"Enter Document Number",
    PleaseEnterDocumentNumber:"Please Enter Document Number",
    NewDocumentNumber:"New Document Number",
    EnterNewDocumentNumber:"Enter New Document Number",
    PleaseEnterNewDocumentNumber:"Please Enter New Document Number",
    InternalPrint:"Internal Print",
    ExternalPrint:"Eksternal Print",
    SuccessSavingInspection:"Success Saving Inspection",
    InspectionEventDetail:"Inspection Event Detail",

      // start configuration admin
      EnterHostMail:"Enter your host email",
      PleaseEnterHostMail:"Please enter a valid host email",

      EnterPortMail:"Enter your port email",
      PleaseEnterPortMail:"Please enter a valid port email",

      EnterUsernameMail:"Enter your username email",
      PleaseEnterUsernameMail:"Please enter a valid username email",

      EnterPasswordMail:"Enter your password email",
      PleaseEnterPasswordMail:"Please enter a valid password email",

      EnterReplyToMail:"Enter your email reply",
      PleaseEnterReplyToMail:"Please enter a valid reply email",

      EnterFromMail:"Enter your email from",
      PleaseEnterFromMail:"Please enter a valid email",

      DoYouWantToUpdateInformationOfConfigurationAdmin:"Do you want to update information of this configuration ?",
      TestSendMail:"Test Send Email",
      // end configuration admin


}
module.exports = Object.freeze(Object.assign(label, menu, baru))

