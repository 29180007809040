import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import adminProfilePicture from 'assets/img/users/user_pp.png';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import kitchen from '../utils/AxiosInstance';
import Global, {ADMIN_STATUSES, DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {deleteParam, imageSelector, isEmpty, isValidEmail, parseDate, sortirMap} from '../utils/Utilities';
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ImageCropper from '../components/modal/ImageCropper';
import {IoMdKey} from 'react-icons/io';
import label from '../message/Label'
import {MdDelete, MdHistory} from "react-icons/md";
import UpdateAdminStatusModal from "../components/modal/UpdateAdminStatusModal";
import AdminStatusHistoryModal from "../components/modal/AdminStatusHistoryModal";
import SearchInput from "../components/SearchInput";
import WarningLabel from "../components/Widget/WarningLabel";
import MyCardImage from "../components/Card/MyCardImage";

class AdminDetailPage extends BasePage{

    constructor(props) {
        super(props);
        this.state = {
            admin:{},
            roles:[],
            adminDevices:[],
            adminStatuses:ADMIN_STATUSES,
            image :null,
            imageBlob:null,
            adminIdQuery: queryString.parse(this.props.query).id,
            role:{},
            adminStatus:{},
            modalAddConfirm:false,
            updateAdminStatusModal:false,
            adminStatusModal:false,
            page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            deviceSortir:"ud.created",
            deviceSearch:"",
            deviceAscending:false,

        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAllRole()
        this.fetchAdminDetail(this.state.adminIdQuery)
    }

    async confirmSave(){
        var admin = this.state.admin
        var image = this.state.image
        let valid = true ;

        if(isEmpty(admin.adminCode)){
            this.setState({
                refCodeWarning:label.PleaseGenerateARefCodeForThisUser
            })
            valid = false ;
        }
        if(isEmpty(admin.firstname)){
            this.setState({
                firstNameWarning:label.PleaseEnterFirstName
            })
            valid = false ;
        }
        if(isEmpty(admin.email)){
            this.setState({
                emailWarning:label.PleaseEnterEmail
            })
            valid = false ;
        }
        if(!isValidEmail(admin.email)){
            this.setState({
                emailWarning:label.InvalidEmailAddress
            })
            valid = false ;
        }
        if(!admin.id){
            valid = await this.verifyEmail(admin.email)
        }
        if(isEmpty(admin.role)){
            this.setState({
                roleWarning:label.PleaseSelectRoleForThisAdmin
            })
            valid = false ;
        }

        if(valid){
            this.openConfirmDialog(label.Confirmation, (admin.id?label.DoYouWantToUpdateInformationOfThisAdmin:label.DoYouWantToCreateANewAdmin), ()=>{
                this.saveUpload()
            })
        }
    }

    saveUpload = () => {
        var file = this.state.imageBlob ;
        var formData = new FormData();
        formData.append("adminDtoGson", JSON.stringify(this.state.admin))
        formData.append("multipartFile", file);
        this.setState({
            modalAddConfirm:false
        }, () => {
            this.post(Global.API.ADMIN_SAVE_UPLOAD, formData, null, res=>{
                this.setState({
                    admin:res.data
                }, () => {
                    this.openSuccessDialog(label.Success, res.message, ()=>{
                        this.props.history.goBack();
                    })
                    // changeParam(this.props, 'id', this.state.admin.id)
                    // this.fetchAdminDetail(this.state.admin.id)
                })
            },  true, true)
        })
    }

    fetchAdminDetail = (id) => {
        if(id!=null){
            this.get(Global.API.ADMIN, {
                params :{
                    id : id
                }
            }, null, res=>{
                if(res.code===200){
                    let adminStatus = null
                    ADMIN_STATUSES.forEach(value => {
                        if(value.name==res.data.adminStatus){
                            adminStatus = value
                        }
                    })
                    let admin = res.data
                    let role = admin.role?admin.role:{}
                    this.setState({
                        admin:admin,
                        role:res.data.role,
                        adminStatus:adminStatus,
                        image:res.data.image?res.data.imageLink:null,

                    }, () => {
                        if(admin.id){
                            this.fetchAdminDevices()
                        }

                    })

                }else{
                    this.props.history.goBack();
                }

            }, true, true)
        }
    }

    fetchAllRole = () =>{
        kitchen.get(Global.API.ROLES).then(res =>{
            if(res.data.code === 200){
                this.setState({
                    roles : res.data.data
                })
            }
        });
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
        })
    }

     async verifyEmail(email){
        let response = await this.asyncGet(Global.API.ADMIN_VALIDATE_EMAIL, {
            params:{
                email:email
            }
        }, null, false, false)
         if(response.code!=200){
             this.setState({
                 emailWarning:response.message
             })
         }

         if(response && response.code===200){
             return true ;
         }
         return false
    }

    render() {
        let adminStatus = this.state.adminStatus?this.state.adminStatus:{}
        let adminStatusModal = this.state.adminStatusModal
        let updateAdminStatusModal = this.state.updateAdminStatusModal

        let {admin} = this.state
        let adminStatusColor = admin.adminStatus&&admin.adminStatus===ADMIN_STATUSES[0].name?"green":"#d80718"

        var ids = [];
        var labels = [];
        this.state.roles.map((item, index)=>{
            ids.push(item.id);
            labels.push(item.name);
        })
        return (
            <Page
                title={label.AdminDetailInformation}
                breadcrumbs={[{ name: 'admin form', active: true }]}>
                {super.render()}
                <UpdateAdminStatusModal
                    admin={admin}
                    modal={updateAdminStatusModal}
                    closeCallback={()=>{
                        this.setState({
                            updateAdminStatusModal:false
                        })
                    }}
                    okCallback={()=>{
                        this.setState({
                            updateAdminStatusModal:false
                        }, () => {
                            this.fetchAdminDetail(admin.id)
                        })
                    }}/>
                <AdminStatusHistoryModal
                    showing={adminStatusModal}
                    onClose={()=>{
                        this.setState({adminStatusModal:false})
                    }}
                    admin={admin}/>

                <ConfirmDialog
                    showing={this.state.modalAddConfirm}
                    title={label.UpdateConfirmation}
                    message={label.UpdateConfirmationMessage}
                    okCallback={this.saveUpload}
                    cancelCallback={this.closeDialog}/>
                <Row key={1}>
                    <Col>
                        <Card className="mb-6">
                            <CardHeader>Admin</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <Card>
                                            <CardBody>
                                                <MyCardImage
                                                    top
                                                     src={this.state.image?this.state.image:adminProfilePicture}
                                                     onClick={e=>{
                                                        imageSelector(file=>{
                                                            this.setState({
                                                            image:file,
                                                            cropperModal:true
                                                         })
                                                    }).click()
                                                    }}
                                                    onError={(elm)=>this.defaultImage(elm, adminProfilePicture)}/>
                                            </CardBody>
                                            <CardBody>
                                                <CardTitle>{label.Image}</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => {
                                                                e.preventDefault()
                                                                this.setState({
                                                                    admin:null,
                                                                    image:null
                                                                })
                                                            }}>{label.Cancel}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                e.preventDefault()
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image:file,
                                                                        cropperModal:true
                                                                    })
                                                                }).click()
                                                            }}>{label.Upload}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {
                                                            admin.id&&(
                                                                <>
                                                                    <br/>
                                                                    <Row>
                                                                        <Col>
                                                                            <Button
                                                                                style={{width:'100%', background:adminStatusColor}}
                                                                                onClick={event => {
                                                                                    this.setState({
                                                                                        updateAdminStatusModal:true
                                                                                    })
                                                                                }}>
                                                                                {!admin.adminStatus?ADMIN_STATUSES[1].name:admin.adminStatus}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            admin.id&&(
                                                                <>
                                                                    <Row>
                                                                        <Col>
                                                                            <Button
                                                                                block
                                                                                color={"primary"}
                                                                                onClick={event => {
                                                                                    this.setState({
                                                                                        adminStatusModal:true
                                                                                    })
                                                                                }}>
                                                                                {label.StatusHistory}
                                                                                &nbsp;&nbsp;<MdHistory/>
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        }
                                                        <br/>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={9}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="firstname">{label.Firstname}</Label>
                                                            <Input
                                                                type="text"
                                                                name="firstname"
                                                                value={(this.state.admin!=null && this.state.admin.firstname)? this.state.admin.firstname:""}
                                                                onChange={(e) =>{
                                                                    let admin = this.state.admin
                                                                    if(admin!=null){
                                                                        admin.firstname = e.target.value
                                                                        this.setState({
                                                                            firstNameWarning:"",
                                                                            admin:admin
                                                                        })
                                                                    }
                                                                }}
                                                                placeholder={label.EnterFirstname}
                                                            />
                                                            <WarningLabel message={this.state.firstNameWarning} show={!isEmpty(this.state.firstNameWarning)}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="lastname">{label.Lastname}</Label>
                                                            <Input
                                                                type="text"
                                                                name="lastname"
                                                                value={(this.state.admin!=null && this.state.admin.lastname)?this.state.admin.lastname:""}
                                                                onChange={(e) =>{
                                                                    let admin = this.state.admin
                                                                    if(admin!=null){
                                                                        admin.lastname = e.target.value
                                                                        this.setState({
                                                                            admin:admin
                                                                        })
                                                                    }
                                                                }}
                                                                placeholder={label.EnterLastname}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="email">{label.Email}</Label>
                                                            <Input
                                                                type="text"
                                                                name="email"
                                                                disabled={(admin && admin.id)}
                                                                value={(admin && admin.email)?admin.email:""}
                                                                onChange={(e) =>{
                                                                    let email = e.target.value
                                                                    admin.email = email
                                                                    if(!isValidEmail(email)){
                                                                        this.setState({
                                                                            admin : admin,
                                                                            emailWarning:label.InvalidEmailAddress
                                                                        })
                                                                    }else{
                                                                        admin.email = email
                                                                        this.setState({
                                                                            emailWarning:"",
                                                                            admin:admin
                                                                        }, async () => {
                                                                            await this.verifyEmail(admin.email)
                                                                        })
                                                                    }
                                                                }}
                                                                placeholder={label.EnterEmail}
                                                            />
                                                            <WarningLabel message={this.state.emailWarning} show={!isEmpty(this.state.emailWarning)}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectRole}
                                                                objects={this.state.roles}
                                                                default={this.state.role!=null?this.state.role.id:null}
                                                                callback={(role)=>{
                                                                    let admin = this.state.admin
                                                                    if(admin && role){
                                                                        admin.role = role
                                                                        this.setState({
                                                                            roleWarning:"",
                                                                            admin:admin,
                                                                            role:role
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                            <WarningLabel message={this.state.roleWarning} show={!isEmpty(this.state.roleWarning)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="adminCode">{label.AdminCode}</Label>
                                                            <Input
                                                                type="text"
                                                                name="adminCode"
                                                                value={admin.adminCode}
                                                                disabled={true}
                                                                onChange={(e) =>{
                                                                    admin.adminCode = e.target.value
                                                                    this.setState({
                                                                        refCodeWarning:"",
                                                                        admin:admin
                                                                    })
                                                                }}
                                                                placeholder={label.ClickButtonToGenerate}
                                                            />
                                                            <WarningLabel message={this.state.refCodeWarning} show={!isEmpty(this.state.refCodeWarning)}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <Label for="adminCode">&nbsp;</Label>
                                                            <br/>
                                                            <Button
                                                                block
                                                                onClick={event => {
                                                                    this.generateAdminCode()
                                                                }}>{
                                                                admin.adminCode?label.Regenerate:label.Generate
                                                            }</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    admin:null
                                                                }, () => {
                                                                   this.props.history.goBack();
                                                                })
                                                                )}>{label.Back}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            {(admin && admin.id)&&(
                                                                <>
                                                                    <Button color="danger" onClick={event=>{
                                                                        this.openConfirmDialog(label.Confirmation, label.DoYouWantToResendAPasswordForThisAdmin, ()=>{
                                                                            this.get(Global.API.ADMIN_RESEND_PASSWORD, {
                                                                                params:{
                                                                                    adminId:this.state.admin.id
                                                                                }
                                                                            }, null, response=>{
                                                                                if(response.code===200){
                                                                                    this.successToast(response.message)
                                                                                }
                                                                            }, true, true)
                                                                        })
                                                                    }}><IoMdKey/>&nbsp;&nbsp;Resend Password</Button>
                                                                    &nbsp;&nbsp;
                                                                </>
                                                            )}
                                                            <Button color="primary" onClick={async (e)=>{
                                                                e.preventDefault()
                                                                await this.confirmSave()
                                                            }}> {
                                                                (admin && admin.id)?label.Update:label.Create
                                                            } </Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    !isEmpty(admin.id)?
                        this.renderDevice()
                        :
                        null
                }
                <ImageCropper src={this.state.image} show={this.state.cropperModal} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image:file,
                            cropperModal:false,
                            imageBlob:fileBlob,
                        }, () => {
                            // this.uploadPhotoProfile(file, login)
                        })
                    })
                }}/>
            </Page>
        );
    }

    generateAdminCode(){
        let admin = this.state.admin
        this.get(Global.API.ADMIN_GENERATE_ADMIN_CODE, null, null, response=>{
            if(response.code===200){
                admin.adminCode = response.data
                this.setState({
                    refCodeWarning:"",
                    admin:admin
                })
            }

        }, true, true)
    }

    refreshAdminDevice = (ascending, sortir, progressing) => (
        this.setState({
            deviceAscending:ascending,
            deviceSortir:sortir
        }, () => {
            this.fetchAdminDevices()
        })
    )

    fetchAdminDevices = () => {
        let admin = this.state.admin ;
        this.get(Global.API.ADMIN_DEVICES, {
            params:{
                ascending:this.state.deviceAscending,
                sortir:this.state.deviceSortir,
                search:this.state.deviceSearch,
                adminId:admin.id,
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    adminDevices : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, true, true);
    }

    renderDevice = () =>{
        var i = 0 ;
        let {admin, adminDevices} = this.state
        return (
            <>
                <Card className="mb-6">
                    <CardHeader>
                        <strong>{label.Devices}</strong>
                        <br/><br/>
                        <Row>
                            <Col md={3}>
                                {label.SortBy} :
                                <UncontrolledButtonDropdown key={1}>
                                    <DropdownToggle
                                        caret
                                        color="white"
                                        className="text-capitalize m-1">
                                        {
                                            sortirMap(this.state.deviceSortir)
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e=>(this.refreshAdminDevice(this.state.deviceAscending, "ud.created", true))}>{label.Created}</DropdownItem>
                                        <DropdownItem onClick={e=>(this.refreshAdminDevice(this.state.deviceAscending, "ud.updated", true))}>{label.Updated}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md={3}>
                                {label.Sortir}&nbsp;:
                                <UncontrolledButtonDropdown key={2}>
                                    <DropdownToggle
                                        caret
                                        color="white"
                                        className="text-capitalize m-1">
                                        {
                                            this.state.deviceAscending?label.Ascending:label.Descending
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e=>(this.refreshAdminDevice(true, this.state.deviceSortir, true))}>{label.Ascending}</DropdownItem>
                                        <DropdownItem onClick={e=>(this.refreshAdminDevice(false, this.state.deviceSortir, true))}>{label.Descending}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md={6}>
                                <SearchInput
                                    placeholder={label.SearchDeviceId}
                                    value={this.state.search}
                                    onChange={e=>{
                                        this.setState({
                                            search:e.target.value
                                        }, () => {
                                            if(this.state.search===''){
                                                deleteParam(this.props, 'page')
                                            }
                                        })
                                    }}
                                    onEnter={e=>{
                                        if(this.state.search!==''){
                                            deleteParam(this.props, 'page')
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Table responsive hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{label.Platform}</th>
                                    <th>{label.IPAddress}</th>
                                    <th style={{width:'25%'}}>{label.UserAgent}</th>
                                    <th>{label.DeviceId}</th>
                                    <th>{label.Created}</th>
                                    <th>{label.LastOpen}</th>
                                    <th>{label.Delete}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    adminDevices.map((item, index)=>(
                                        <tr key={item.id}>
                                            <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                            <td>{item.platform}</td>
                                            <td>{item.ipAddress}</td>
                                            <td>{item.userAgent}</td>
                                            <td>{item.deviceId}</td>
                                            <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                            <td>{parseDate(item.lastOpenDate, DD_MM_YYYY_HH_MM_SS)}</td>
                                            <td>
                                                <Button size="sm" color="danger" onClick={e => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        adminDevice:item
                                                    }, () => {
                                                        this.openConfirmDialog(label.DeleteConfirmationTitle, label.BanThisDevice_TheAdminWillBeBannedAfterThisPerform,()=>{
                                                            this.get(Global.API.ADMIN_DEVICE_DELETE+"/"+this.state.adminDevice.id, null, null, res => {
                                                                if(res.code===200){
                                                                    this.successToast(res.message)
                                                                    this.fetchAdminDevices()
                                                                }
                                                            }, true, true);

                                                        })
                                                    })
                                                }}>
                                                    <MdDelete/>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Row>
                    </CardBody>
                </Card>
                <br/>
            </>
        );
    }

}
export default Object.assign(AdminDetailPage, {ENDPOINT : "/adminDetail"})
