import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {cloneObject, isEmpty, parseDate, sortirMap} from '../utils/Utilities';
import {MdDelete, MdEdit} from 'react-icons/md';
import {FaClone} from 'react-icons/fa';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import label from '../message/Label'
import FormBuilderCopyModal from "../components/modal/FormBuilderCopyModal";


class FormBuilderPage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      groupForms : [],
      groupForm:{},
      heading:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      ascending:true,
      sortir:'created',
      page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
      totalPage:0,
      totalElement:0,
      pageElement:0,
      formCopyModal:false,
    }
  }

  componentWillReceiveProps(props, nextContext) {
    if(props!=this.props){
      this.setState({
        page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
      }, () => {
        this.fetchAll()
      })
    }
  }


  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var groupForm = this.state.groupForm
    if(isEmpty(groupForm.name) || isEmpty(groupForm.heading)){
      this.showDialog("Notice", "Please complete this form fields")
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }

  save = () => {
    let groupForm = this.state.groupForm
    if(!groupForm.id && !groupForm.active){
      groupForm.active = false ;
    }

    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Global.API.GROUP_FORM_SAVE, groupForm, null, res => {
        if(res.code===200){
          this.setState({
            groupForm:{}
          }, () => {
            this.fetchAll()
          })
        }
      }, true, true);
    })
  }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Global.API.GROUP_FORM_DELETE+"/"+this.state.groupForm.id, null, null, res => {
        if(res.code===200){
          this.setState({
            groupForm:{}
          }, () => {
            this.fetchAll()
          })
        }
      },true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Global.API.GROUP_FORMS, {
      params:{
        page: this.state.page-1,
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          groupForms : response.data,
          totalPage: response.totalPage,
          totalElement: response.totalElement,
          pageElement: response.pageElement,

        })
      }
    }, true, true);
  }

  cloneForm = (groupForm, name, documentNumber) => {
    let form = new FormData()
    form.append("groupFormId", groupForm.id)
    form.append("name", name)
    form.append("documentNumber", documentNumber)
    this.post(Global.API.CLONE_GROUP_FORM, form, null, response=>{
      if(response.code === 200){
        this.fetchAll()
      }
    }, true, true);
  }


  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let formCopyModal = this.state.formCopyModal
    let groupForm = this.state.groupForm
    return (
        <Page
            title={label.GroupForm}
            breadcrumbs={[{ name: label.GroupForm, active: true }]}
            className="TablePage">
          {super.render()}
          <FormBuilderCopyModal
              groupForm={groupForm}
              okCallback={(groupForm, formName, documentNumber)=>{
                this.cloneForm(groupForm, formName, documentNumber)
                this.setState({
                  formCopyModal:false
                })
              }}
              closeCallback={()=>{
                this.setState({
                  formCopyModal:false
                })
              }}
              modal={formCopyModal}
          />
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message="Do you want to udpate this groupForm item to your list ?"
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message="Do you want to delete this groupForm item from your list ?"
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.GroupFormList}</CardHeader>
                <CardBody >
                  <Row>
                    <Col>
                      <Card body>
                        <Row>
                          <Col md={3}>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "documentNumber"))}>{label.DocumentNumber}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col md={4}>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col md={5}>
                            <ButtonGroup className="float-right">
                              <Button color="primary" onClick={e=>{
                                this.props.history.push('/formBuilderDetail')
                              }}> + {label.AddFormBuilder}</Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.DocumentNumber}</th>
                                <th>{label.Created}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Delete}&nbsp;|&nbsp;{label.Copy}&nbsp;|&nbsp;{label.Edit}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.groupForms.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.name}</td>
                                      <td>{item.documentNumber}</td>
                                      <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button color="danger" onClick={e => {
                                          this.setState({
                                            groupForm:cloneObject(item)
                                          }, () => {
                                            this.confirmDelete()
                                          })
                                        }}>
                                          <MdDelete/>
                                        </Button>
                                        &nbsp;
                                        <Button color="warning" onClick={e => {
                                          this.setState({
                                            groupForm:cloneObject(item)
                                          }, () => {
                                            this.setState({formCopyModal: true})
                                          })
                                        }}>
                                          <FaClone/>
                                        </Button>
                                        &nbsp;
                                        <Button color="primary" onClick={e=>{
                                          this.props.history.push('/formBuilderDetail?id='+item.id)
                                        }}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}

export default Object.assign(FormBuilderPage, {ENDPOINT : "/formBuilders"})
