
const label =  {
    Submit:"Kirim",
    Cancel:"Batalkan",
    Back:"Kembali",
    Or:"Atau",
    And:"Dan",
    Ok:"Oke",
    Edit:"Edit",
    Remove:"Hapus",
    Save:"Simpan",
    Close:"Tutup",
    Email:"Email",
    Page:"Halaman",
    Detail:"Detil",
    AddProduct:"Tambah Produk",
    AddInventory:"Tambah Inventori",
    EnterYourMail:"Masukkan Email Anda",
    YourEmail:"your@email.com",
    Password:"Password",
    ConfirmPassword:"Konfirmasi Password",
    EnterPassword:"Masukkan password",
    EnterConfirmPassword:"Masukkan konfirmasi password",
    EnterOldPassword:"Masukkan password lama",
    EnterNewPassword:"Masukkan password baru",
    ForgetPassword:"Lupa Password",
    NotRegisteredYet:"Belum Terdaftar",
    InvalidEmailFormat:"Format email tidak sesuai",
    PleaseEnterYourEmailWeWillSendYouInstructionToResetYourPassword:"Mohon masukkan email anda, kami akan mengirim instruksi lupa password melalui email",


    SelectProduct:"Pilih Produk",
    SelectSize:"Pilih Ukuran",
    Sortir:"Pengurutan",
    SortBy:"Urut Berdasarkan",
    Ascending:"Kecil Ke Besar",
    Descending:"Besar Ke Kecil",
    Confirmation:"Konfirmasi",
    UpdateConfirmationMessage:"Apakah anda yakin untuk memperbarui konten anda",
    DeleteConfirmationTitle:"Konfirmasi Penghapusan",
    DeleteConfirmationMessage:"Apakah anda yakin akan menghapus konten ini",

    Created:"Tanggal Buat",
    Name:"Nama",
    BrandName:"Merk",
    Heading:"Menu",
    Category:"Sub Menu",
    Updated:"Tanggal Ubah",
    Create:"Buat",
    Update:"Ubah",
    Add:"Tambah",
    SelectStatus:"Pilih Status",
    Active:"Aktif",
    Inactive:"Inaktif",
    Expired:"Expired",
    All:"Semua",
    AllInventory:"Semua Inventori",
    Type:"Tipe",
    Quantity:"Kuantitas",
    Weight:"Berat",
    Price:"Harga",
    Fullname:"Nama Lengkap",
    Phone:"No Telpon",

    Search:"Cari",
    SearchCreator:"Cari Pembuat",
    TypeAndEnterToSearch:"Masukkan pencarian lalu tekan enter",
    SearchByName:"Cari Berdasarkan Nama",
    HashTag:"Hashtag",
    Image:"Gambar",
    Slug:"Slug",
    Status:"Status",
    LastStatus:"Status Terakhir",
    PreviousStatus:"Status Sebelumnya",
    Warning:"Peringatan",
    PleaseCompleteTheFields:"Mohon lengkapi form input",
    ProductForm:"Form Produk",
    UseImageTypeInstruction:(type, size)=>"Gunakan gambar bertipe "+type+" Dengan ukuran maksimal adalah "+size+" Kb",
    Upload:"Upload",
    EnterProductName:"Masukkan Nama Produk",
    EnterQuantity:"Masukkan Kuantitas",
    EnterSlug:"Masukkan Slug",
    EnterWeight:"Masukkan Berat",
    EnterPrice:"Masukkan Harga",
    AutoSlug:"Slug Otomatis",
    SelectBrand:"Pilih Merk",
    SelectHeading:"Pilih Menu",
    SelectCategory:"Pilih Kategori",
    SelectFormType:"Pilih Jenis Form",
    RelatedHashTag:"Hashtag terkait",
    Description:"Deskripsi",
    EnterDescription:"Masukkan Deskripsi",
    Size:"Ukuran",
    EnterSize:"Masukkan Ukuran",
    EnterName:"Masukkan nama",
    EnterBrandName:"Masukkan nama merk",
    EnterCategoryName:"Masukkan nama sub menu",
    EnterProvinceName:"Masukkan nama propinsi",
    SelectProvince:"Pilih propinsi",
    EnterCityName:"Masukkan nama kota",
    SelectCity:"Pilih kota",
    EnterDistrictName:"Masukkan nama kecamatan",
    SelectDistrict:"Pilih kecamatan",
    EnterVillageName:"Masukkan nama kelurahan",
    SelectVillage:"Pilih kelurahan",
    PostalCode:"Kode Pos",
    Creator:"Pembuat",
    ContentOrStockLabel:"Konten / Stok",
    SoldStock:"Stok Terjual",
    Stock:"Stok",
    StockLog:"Riwayat Stok",
    SelectSortir:"Pilih Pengurutan",
    SelectDirection:"Pilih Arah Urutan",
    SelectInOurOut:"Pilih Tambah / Kurang",
    SelectStockAuditType:"Pilih Jenis Audit",
    StartDate:"Tanggal Mulai",
    EndDate:"Tanggal Akhir",
    CreatedStartDate:"Dibuat Mulai Tanggal",
    CreatedEndDate:"Dibuat Hingga Tanggal",
    UpdatedStartDate:"Diubah Mulai Tanggal",
    UpdatedEndDate:"Diubah Hingga Tanggal",
    EnterStartDate:"Masukkan Tanggal Awal",
    EnterEndDate:"Masukkan Tanggal Akhir",
    Note:"Catatan",
    View:"Lihat",
    IconClass:"Klasifikasi Icon",
    EnterIconClass:"Masukkan klasifikasi Icon",
    InventoryForm:"Form Inventori",
    AdminForm:"Form Admin",
    CustomerForm:"Form Customer",
    PointForm:"Form Poin",
    VoucherForm:"Form Voucher",
    PopularProduct:"Produk Populer",
    LatestProduct:"Produk Terbaru",
    Latest:"Terbaru",
    OrderQuantity:"Jumlah Pesanan",
    SoldOut:"Terjual",
    Added:"Ditambahkan",
    EnterBankLabel:"Masukkan label bank",
    EnterBankName:"Masukkan nama bank",
    EnterBankCode:"Masukkan kode bank",
    EnterAccountBankNumber:"Masukkan no rekening",
    EnterAccountBankName:"Masukkan nama pemilik rekening",
    SMSBankingSenderId:"Sender ID SMS",
    SMSBankingTemplate:"Template SMS Banking",
    EnterSmsBankingSenderId:"Masukkan sender SMS",
    SMSPrefixIndicator:"Prefix SMS Sebagai Indikator",
    EnterSMSPrefixIndicator:"Masukkan indikator prefix SMS",
    CurrencyFormat:"Format Mata Uang",
    CurrencyFormatExample:"Contoh : ##.###,##",
    EnterMobilePhone:"Masukkan no handphone",
    EnterCustomerReceiverEmail:"Masukkan email konsumen penerima",
    EnterSubscriberReceiverEmail:"Masukkan email subscriber penerima",
    EnterSortir:"Masukkan pengurutan",
    EnterPageLink:"Masukkan pagelink",
    TransactionCount:"Jumlah Transaksi",
    TransactionDate:"Tanggal Transaksi",
    FavoriteCount:"Jumlah Favorite",
    CartCount:"Jumlah Keranjang",
    OriginalPrice:"Harga Asli",
    FinalPrice:"Harga Final",
    Select:"Pilih",
    Check:"Cek",
    CompanyDescription:"Deskripsi Perusahaan",
    EnterCompanyDescription:"Masukkan Deskripsi Perusahaan",
    VoucherCode:"Kode Voucher",
    Owner:"Pemilik",
    Theme:"Tema",
    Period:"Periode",
    Benefit:"Benefit",
    Aggregate:"Aggregate",
    StartPeriod:"Periode Awal",
    EndPeriod:"Periode Akhir",
    SelectPeriod:"Pilih Periode",
    SelectVoucherBenefit:"Pilih Keuntungan Voucher",
    SelectPointBenefit:"Pilih Keuntungan Poin",
    SelectVoucherOwnerType:"Klasifikasi Pemilik Voucher",
    SelectVoucherDiscountType:"Klasifikasi Diskon",
    SelectAggregateType:"Pilih Tipe Aggregate",
    MaxUsage:"Maksimum Penggunaan",
    CurrentUsage:"Penggunaan Saat Ini",
    Publish:"Publikasi",
    Unpublish:"Stop Publikasi",
    ValidDay:"Masa Berlaku Dalam Hari",
    PurchaseDiscount: "Diskon Pembelian",
    ShippingDiscount:"Diskon Pengiriman",
    FreeShipping:"Gratis Pengiriman",
    PercentageDiscount : "Diskon Persentasi Harga",
    PriceDiscount : "Diskon Potongan Harga",
    AllTime:"Sepanjang Waktu",
    Dynamic:"Dinamis",
    Static:"Statis",
    SpecificCustomer:"Customer Tertentu",
    AnyCustomer:"Siapa Saja",
    SingleItem:"Satu Item Saja",
    JoinItem:"Penggabungan Item",
    AnyItem:"Item Apapun",
    PointExchange:"Penukaran Poin",
    EnterVoucherCode:"Masukkan Kode Voucher",
    EnterVoucherName:"Masukkan Nama Voucher",
    ThemeColor:"Warna Tema",
    AvailableItemForThisVoucher:"Item Tersedia Untuk Voucher Ini",
    MinimumPurchaseQuantity:"Minimum jumlah belanja",
    AvailableStock:"Stok Tersedia",
    CustomerWhoHasThisVoucher:"Customer Memiliki Voucher Ini",
    SelectPointType:"Pilih Jenis Poin",
    PurchaseItemPoin:"Poin Pembelian Untuk Item",
    PurchasePricePoin:"Poin Pembelian Untuk Harga",
    PurchasePercentagePoint:"Poin Persentase Pembelian",
    FixedPoint:"Poin Tetap",
    PointBonus:"Bonus Poin",
    PurchaseMinimum:"Minimal Pembelian",
    ProductItemBelongToThisPoin:"Item Produk Dengan Poin Ini",
    RegisteredForThisPoin:"Diberikan Poin Ini",
    RegisteredForAnotherPoin:"Diberikan Poin Lain",
    NotRegisteredForAnyPoin:"Belum Diberikan Poin",
    PublishConfirmation:"Konfirmasi Publikasi",
    UnpublishConfirmation:"Konfirmasi Stop Publikasi",
    PublishConfirmationMessage:"Apakah anda setuju dengan publikasi poin ini ?",
    UnpublishConfirmationMessage:"Apakah anda setuju dengan stop publikasi poin ini ?",
    FlashSaleContent:"Konten Flash Sale",
    Inactivate:"Non Aktifkan",
    Activate:"Aktifkan",
    InactivateConfirmation:"Konfirmasi Non Aktifkan",
    ActivateConfirmation:"Konfirmasi Aktifkan",
    InactivateFlashSaleConfirmationMessage:"Apakah anda yakin untuk menonaktifkan Flash Sale ini ?",
    ActivateFlashSaleConfirmationMessage:"Apakah anda yakin untuk mengaktifkan Flash Sale ini ?",
    SearchProduct:"Cari Produk",
    FlashSaleStock:"Stok Flash Sale",
    FilterCustomerSearch:"Filter Pencarian Customer",
    RegistrationStartDate:"Tanggal Registrasi Mulai",
    RegistrationEndDate:"Tanggal Registrasi Hingga",
    Gender:"Jenis Kelamin",
    FilterCustomerByTransaction:"Filter Pencarian Customer Berdasarkan Transaksi",
    TransactionStartDate:"Tanggal Transaksi Mulai",
    TransactionEndDate:"Tanggal Transaksi Hingga",
    MobilePhone:"Handphone",
    TransactionDuring:"Transaksi Antara",
    TransactionFavoriteCartColumnLabel:"Trx/Fav/Keranjang",
    Address:"Alamat",
    CustomerAddress:"Alamat Customer",
    ListOfDestinationAddress:"Daftar Alamat Pengiriman",
    EnterAddress:"Masukkan Alamat",
    Firstname:"Nama Depan",
    EnterFirstname:"Masukkan Nama Depan",
    Lastname:"Nama Belakang",
    EnterLastname:"Masukkan Nama Belakang",
    EnterEmail:"Masukkan Email",
    EnterPhoneNumber:"Masukkan No Telp",
    BirthDate:"Tanggal Lahir",
    EnterBirthDate:"Masukkan Tanggal Lahir",
    SelectGender:"Pilih Jenis Kelamin",
    TransactionItemQuantity:"Jumlah Item Transaksi",
    TotalPay:"Jumlah Bayar",

    /**/
    // Transaction Status
    /**/
    Paid:"Terbayar",
    Packaging:"Pengepakan",
    Delivery:"Pengiriman",
    Done:"Selesai",
    Rejected:"Pembatalan",
    OnReturn:"Pengembalian",
    AcceptedReturn:"Pengembalian Diterima",
    Order:"Pemesanan",
    /**/
    // End Transaction Status
    /**/

    Payment:"Pembayaran",
    PaymentFee:"Fee Pembayaran",
    ResiAndTracking:"Resi & Tracking",
    Print:"Cetak",
    SearchByReferenceCode:"Cari Berdasarkan Kode Referensi",
    YourCustomerCart:"Keranjang Belanja Customer Anda",
    RemoveAllCustomerCartConfirmationMessage:"Apakah anda yakin untuk menghapus semua keranjang belanja konsumen anda ?",
    RemoveCustomerCartConfirmationMessage:"Apakah anda yakin untuk menghapus keranjang belanja milik konsumen tersebut ?",
    RemoveAllCustomerCart:"Hapus Semua Keranjang Customer",
    RemoveCartRespectively:"Hapus Keranjang Per Customer",
    PerCustomer:"Per Customer",
    RemoveHisCart:"Hapus Keranjang Miliknya",
    SelectType:"Pilih Tipe",
    SearchCustomer:"Cari Customer",
    CustomerName:"Nama Customer",
    SearchAdmin:"Cari Administrator",
    Attribute:"Atribut",
    AttributeForm:"Form Atribut",
    Open:"Buka",
    OpenPageLink:"Buka Pagelink",
    PageLink:"Pagelink",
    EnterColorName:"Masukkan nama warna",
    Hexa:"Hexa",
    EnterLabel:"Enter Label",
    Label:"Label",
    SendEmail:"Kirim Email",
    Subject:"Subyek",
    SendAt:"Kirim Pada Waktu",
    SendAnnouncement:"Kirim Pengumuman",

    PublishConfigurationConfirmationMessage:"Apakah anda setuju dengan publikasi konfigurasi ini ? Konfigurasi akan ditetapkan secara langsung terhadap setting website anda",
    ConfigurationDetail:"Detil Konfigurasi",
    PageRow:"Baris Per Halaman",
    FavoriteRow:"Baris Halaman Favorit",
    LatestRow:"Baris Halaman Terbaru",
    DiscountRow:"Baris Halaman Diskon",
    EnterDiscountRow:"Masukkan Baris Halaman Diskon",
    FlashSaleRow:"Baris Halaman Flash Sale",
    EnterFlashSaleRow:"Masukkan Baris Halaman Flash Sale Yang Diinginkan",
    SelebgramRow:"Selebgram Yang Hendak Ditampilkan",
    EnterSelebgramRow:"Masukkan Jumlah Selebgram Yang Hendak Ditampilkan",
    ImageSize:"Ukuran Gambar",
    MainProductMaximumRow:"Jumlah Maksimal Product Utama",
    EnterMainProductMaximumRow:"Masukkan Jumlah Maksimum Product Utama",
    EnterCourierApiName:"Masukkan Nama Penyedia Jasa API Courier",

    FreePaymentFee:"Gratis Biaya Pembayaran",
    BubbleWrapFee:"Biaya Kirim Dengan Bubble Wrap",
    CompanyName:"Nama Perusahaan",
    EnterCompanyName:"Masukkan Nama Perusahaan",
    CompanyPhoneNumber:"No Telp Perusahaan",
    EnterCompanyPhoneNumber:"Masukkan No Telp Perusahaan",
    ContactPersonPhoneNumber:"No Telp Kontak Person",
    EnterContactPersonPhoneNumber:"Masukkan No Telp Kontak Person",
    ProductRelatedSize:"Jumlah Produk Terkait Yang Ditampilkan",
    EnterProductRelatedSize:"Masukkan Jumlah Produk Terkait Yang Hendak Ditampilkan",
    EnterFavoriteRow:"Masukkan Jumlah Produk Favorite Yang Ditampilkan",
    EnterLatestRow:"Masukkan Jumlah Produk Terbaru Yang Hendak Ditampilkan",
    InventoryImageSize:"Jumlah Maksimal Gambar Inventori",
    EnterInventoryImageSize:"Masukkan Ukuran Gambar Inventori",
    VoucherCodeLength:"Panjang Kode Voucher",
    EnterVoucherCodeLength:"Panjang Kode Voucher Yang Diinginkan",
    BankImageDimension:"Dimensi Gambar Bank",
    Width:"Lebar",
    Height:"Tinggi",
    BrandImage:"Gambar Merk",
    MidtransMediatorImageDimension:"Dimensi Gambar Channel Midtrans",
    CourierImageDimension:"Dimensi Gambar Logo Kurir",
    ProductImageDimension:"Dimensi Gambar Produk",
    TransactionConfiguration:"Konfigurasi Transaksi",
    CourierServiceBaseURL:"Address Service Kurir",
    EnterCourierServiceBaseURL:"Masukkan Address Service Kurir",
    CourierServiceApiKey:"Kunci Akses API Courier",
    EnterCourierServiceApiKey:"Masukkan Kunci Akses API Courier",
    ShippingOriginAddress:"Alamat Asal Pengiriman",
    EnterShippingOriginAddress:"Masukkan Alamat Asal Pengiriman",
    SocialMedia:"Media Sosial",
    EnterHashTag:"Masukkan #hashtag",
    InstagramLink:"Link Instagram",
    EnterInstagramLink:"Masukkan Link Instagram",
    FacebookLink:"Link Facebook",
    EnterFacebookLink:"Masukkan Link Facebook",
    TwitterLink:"Link Twitter",
    EnterTwitterLink:"Masukkan Link Twitter",
    FacebookApplicationId:"ID Aplikasi Facebook",
    EnterFacebookApplicationId:"Masukkan ID Aplikasi Facebook",
    GoogleApplicationId:"ID Aplikasi Google",
    EnterGoogleApplicationId:"Masukkan ID Aplikasi Google",
    TawkToReference:"Referensi Aplikasi Tawk To",
    EnterTawkToReference:"Masukkan Referensi Aplikasi Tawk To",
    SelectRole:"Pilih Hak Akses",
    ResendPassword:"Kirim Ulang Password",
    AccessMenu:"Akses Menu",
    Menu:"Menu",
    Domain:"Domain",
    ClientKey:"Kunci Klien",
    ServerKey:"Kunci Server",
    Environment:"Environment",
    PaymentLimitExpiration:"Masa Berlaku Pembayaran",
    BankCode:"Kode Bank",
    BankAccountNumber:"No Rekening",
    BankAccountName:"Nama Pemilik Rekening",
    SelectBank:"Pilih Bank",
    BalanceInformation:"Informasi Saldo",
    History:"Riwayat",
    BalanceMutation:"Mutasi Saldo",
    Mutation:"Mutasi",
    WithdrawalHistory:"Riwayat Penarikan",
    ReferenceCode:"Kode Referensi",
    Reference:"Referensi",
    Credit:"Kredit",
    Debit:"Debit",
    SelectDebitOrCredit:"Pilih Debit / Kredit",
    Amount:"Nominal",
    RemainBalance:"Sisa Saldo",
    WithdrawalConfirmationTitle:"Konfirmasi Penarikan Dana",
    RequestWithdrawConfirmationMessage:(amount)=>"Anda akan melakukan penarikan sejumlah "+amount+", " +
        "\nMohon pastikan no rekening & bank anda sudah terisi dengan benar. " +
        "Kami akan memprosesnya dalam waktu lebih kurang 2x24 jam. " +
        "Mohon menunggu atau anda dapat memberikan konfirmasi kepada admin / support kami, Terimakasih",
    YesIamSure:"Ya saya yakin",
    Sure:"Yakin",
    ReCheck:"Cek Ulang",
    WarningWithdrawExist:"Anda memiliki permintaan penarikan sebelumnya yang belum diproses, mohon menunggu, Staff kami akan segera memprosesnya",
    Waiting:"Menunggu",
    Processing:"Sedang Diproses",
    Issued:"Telah Dibayarkan",
    Postponed:"Ditunda",
    StatusHistory:"Riwayat Status",
    WithdrawalStatusHistory:"Riwayat Status Penarikan",
    WaitingForPayment:"Menunggu Pembayaran",
    WaitingForSubscriptionPayment:"Menunggu Pembayaran Perpanjangan Subscription",
    WaitingForUpgradePayment:"Menunggu Pembayaran Permintaan Upgrade",
    ExpiredOn:"Expired Pada",
    Channel:"Channel",
    Account:"Akun",
    Total:"Total",
    AccountInformation:"Informasi Akun",
    RegisteredOn:"Terdaftar Pada",
    ActivePeriod:"Periode Aktif",
    YourProductService:"Layanan Anda",
    ExtendPeriod:"Perpanjang Periode",
    PayNow:"Bayar Sekarang",
    PAY_NOW:"BAYAR SEKARANG",
    RequestUpgrade:"Request Upgrade",
    REQUEST_UPGRADE:"REQUEST UPGRADE",
    MonthlyPeriod:"Periode Bulanan",
    Month:"Bulan",
    Months:"Bulan",
    ProductOption:"Pilihan Produk",
    AdditionalPayment:"Penambahan Biaya",
    AdditionalDiscount:"Potongan Harga",
    PaymentMethod:"Metode Pembayaran",
    ProductInformation:"Informasi Produk",
    ProductName:"Nama Produk",
    ValidUntil:"Berlaku Hingga",
    Item:"Item",
    PleaseEnterReceiverBankAccountOnYourAccountPage:"Mohon lengkapi terlebih dahulu informasi data akun bank penerima, yang dapat dilengkapi pada halaman akun oleh pemilik !",
    Refresh:"Perbarui Data",
    TotalCustomer:"Jumlah Customer",
    TotalCustomerTransaction:"Jumlah Customer Bertransaksi",
    PreviousMonth:"Bulan Lalu",
    ThisMonth:"Bulan Ini",
    PreviousYear:"Tahun Lalu",
    ThisYear:"Tahun Ini",
    TotalTransaction:"Jumlah Transaksi",
    TotalTransactionAmount:"Nominal Transaksi",
    OnGoingTransaction:"Pelayanan Transaksi Saat Ini",
    Date:"Waktu",
    SelectCriteria:"Pilih Kriteria",
    TotalAmount:"Total Nominal",
    BubbleWrapAmount:"Nominal Bubble Wrap",
    CourierAmount:"Nominal Ongkos Kirim",
    TotalQuantity:"Total Kuantitas",
    PurchaseItemQuantity:"Kuantitas Item Pembelian",
    EnterYourTicketMessageHereInstruction:"Masukkan pesan anda di sini... \nDan anda juga dapat menambahkan screenshot / gambar sebagai pendukung kejelasan atas pesan yang anda maksud..",
    Attachment:"Lampiran",
    PhysicalServiceAddress:"Alamat Layanan Fisik",
    PublicDomain:"Alamat Domain Publik",
    ProductSubscribe:"Produk Berlangganan",
    SubscribePeriod:"Periode Berlangganan",
    CloudVPS:"Cloud VPS",
    VPSInformation:"Informasi VPS",
    Yes:"Ya",
    TotalRecord:"Total Record",
    TotalPage:"Total Halaman",
    YourAccount:"Akun Anda",
    TakeAPayment:"Lakukan Pembayaran",
    ExtendNow:"Perpanjang Sekarang",
    DoYouWantToLogoutFormThiApplication:"Apakah anda ingin keluar dari aplikasi ini ?",
    RegistrationDate:"Tanggal Daftar",
    Until:"s/d",

    Branch:"Cabang",
    Title:"Judul",
    EnterTitle:"Masukkan judul",
    FlashSaleName:"Judul Flash Sale",
    EnterFlashSaleName:"Masukkan judul flash sale",
    EnterSenderEmail:"Masukkan email pengirim",
    WhenItCouldBeSent:"Kapan ini akan dikirim ?",
    EnterNoteRecommended:"Masukkan catatan (Rekomendasi)",
    EnterResiCode:"Masukkan Kode Resi",
    EnterDailyOperationInAPlainWords:"Masukkan hari operasional dengan kalimat umum",
    EnterSettingName:"Masukkan nama konfigurasi",
    EnterStock:"Masukkan kuantitas",
    EnterPercentOfDiscount:"Masukkan persentasi diskon",
    ChangePassword:"Ubah Password",
    OldPassword:"Password Lama",
    NewPassword:"Password Baru",
    Receiver:"Penerima",
    Origin:"Asal",
    Destination:"Tujuan",
    DailyOperation:"Hari Operasional",
    UpdateResiCode:"Perbarui Kode Resi",
    SubjectEmail:"Email Subyek",
    Base:"Base",
    Api:"Api",
    Simulator:"Simulator",
    EditFlashSale:"Ubah Flash Sale",
    CreateFlashSale:"Buat Flash Sale",
    Scheduled:"Dijadwalkan",
    MessageForm:"Pesan Dari",
    ReplyFrom:"Balasan Dari",
    SeeTicket:"Lihat Tiket",
    CreateTicket:"Buat Tiket",
    InvalidInputOfWaybill:"Kode Resi Tidak Valid",
    No:"Tidak",
    VoucherPublishMessage:"Apakah anda ingin mempublikasikan voucher ini kepada konsumen ?",
    VoucherUnpublishMessage:"Apakah anda ingin membatalkan publikasi voucher ini terhadp konsumen ?",
    OriginAddress:"Alamat Pengirim",
    StockAllocation:"Alokasi Stok",
    Allocation:"Alokasi",
    SpecialCloudVPS:"Cloud VPS Spesial",
    AdvanceFilter:"Penyaringan Data",
    ClickToExpandFilterContent:"Klik untuk membuka form penyaringan",
    transactionStartDate:"Transaksi mulai tanggal",
    transactionEndDate:"Transaksi hingga tanggal",
    DestinationProvince:"Propinsi tujuan kirim",
    DestinationCity:"Kota tujuan kirim",
    DestinationDistrict:"Kecamatan tujuan kirim",
    DestinationVillage:"Kelurahan tujuan kirim",
    WithVoucher:"Dengan Voucher",
    EnterNameOrEmailOrPhoneNumberOrRefCodeOrResiCode:"Masukkan nama, email, no telp, referensi, atau kode resi",
    ApplyFilter:"Terapkan Penyaringan",
    ResetFilter:"Reset Penyaringan",
    DownloadExcel:"Unduh File Excel",
    DeliveryDuration:"Durasi Pengiriman",
    Main:"Utama",
    Profile:"Profil",
    Help:"Tolong",
    Guide:"Panduan",
    Signout:"Keluar",
    Message:"Pesan",
    Ticket:"Tiket",
    ListOfImage:"Daftar Gambar",
    EnterHeadingName:"Masukkan nama menu",
    SearchFlashSaleName:"Cari nama flash sale...",
    Information:"Informasi",
    EmailSubjectCannotBeingEmpty:"Subyek email tidak boleh kosong",
    IfYouChooseScheduledModeThenSendAtCannotBeingEmpty:"Jika anda memilih pengiriman terjadwal maka input jadwal kirim tidak boleh dikosongkan",
    IfYouChooseNonRegulerThenSenderFieldCannotBeingEmpty:"Jika anda memilih pengirim non reguler maka input pengirim tidak boleh dikosongkan",
    PleaseEnterAMinimumSingleReceiver:"Mohon masukkan setidaknya 1 penerima email",
    PleaseEnterTheContentOfThisAnnouncement:"Mohon masukkan konten daripada pengumuman ini",
    DoYouWantToSendThisAnnouncementRightNowPleaseItCannotBePostponed:"Apakah anda ingin mengirim pengumuman saat ini juga, mohon diketahui ini tidak dapat dibatalkan",
    DoYouWantScheduleThisAnnouncementAtTheMomentYouFillInPleaseItCannotBePostponed:"Apakah anda ingin menjadwalkan pengumuman ini pada waktu yang telah anda tentukan, mohon diketahui ini tidak dapat dibatalkan",
    RegularSender:"Pengirim Reguler",
    RightNow:"Saat Ini Juga",
    AllCustomer:"Semua Customer",
    AllSubscriber:"Semua Subscriber",
    AnnouncementContentOptions:"Pilihan Konten Pengumuman",
    ManualTyping:"Pengetikan manual",
    UploadHtmlFile:"Unggah berkas berformat HTML",
    CustomerReceiver:"Customer Penerima",
    Sender:"Pengirim",
    PreviewHtmlContent:"Preview Konten HTML",
    PreviewContent:"Preview Konten",
    WriteYourCSSInline:"Masukkan konten css anda dalam file html secara inline atau menjadi 1 file saja",
    UseImageWichisAlreadyStoredOnServer:"Gunakan image yang dapat diakses secara publik / internet dan masukkan image src secara inline seperti https://domain.com/image.jpg, " +
        "atau gunakan format base 64",
    ThirdPartyKeys:"Kunci Pihak Ke Tiga",
    ListMenu:"Daftar Menu",
    AppName:"Abacus QC Inspector",
    ActivateDate:"Tanggal Pengaktifan",
    EnterActivateDate:"Masukkan tanggal jadwal pengaktifan",
    LOGIN:"MASUK",
    SIGNUP:"DAFTAR",
    UpdateConfirmation:"Konfirmasi Update",
    Logout:"Keluar",
    TotalUser:"Total Pengguna",
    TotalInspection:"Inspeksi Total",
    TotalInspectionDone:"Total Inspeksi Selesai",
    TotalTransfer:"Total Transfer Komisi",
    OnGoingInspection:"Inspeksi Berlangsung",
    Pass:"Lulus",
    Taken:"Dikerjakan",
    NotPass:"Tidak Lulus",
    Closed:"Closed",
    Suspended:"Suspended",
    Opened:"Terbuka",
    OnWorking:"Dikerjakan",
    UserCode:"Kode User",
    ModelCategory:"Kategori Model",
    BalanceOfAllUser:"Total Saldo Semua Pengguna",
    PaidAmountOfAllUser:"Terbayar Kepada Para Pengguna",
    Success:"Sukses",
    ModelCategoryDetail:"Detil Kategori Model",
    MONTHS_LABEL:["Januari", "Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"],
    Failed:"Gagal",
    ModelCount:"Jumlah Model",
    EnterFullname:"Masukkane Nama Lengkap",
    EnterMessage:"Masukkan Pesan",
    EnterModelCategoryName:"Masukkan nama kategori model",
    AddModel:"Tambah Model",
    AddBrand:"Tambah Merk",
    Fee:"Komisi",
    Limitation:"Batasan",
    InspectionLimit:"Batasan Inspeksi",
    Agent:"User",
    InspectionDone:"Inspeksi Selesai",
    Website:"Website",
    Suggestion:"Showing Order",
    Publication:"Publikasi",
    Published:"Terpublikasi",
    Unpublished:"Tak Terpublikasi",
    ModelList:"Daftar Model",
    GroupForm:"Kelompok Form",
    GroupFormDetail:"Detil Pengelompokan Form",
    GroupFormList:"Daftar Pengelompokan Form",
    AddFormBuilder:"Tambah Pembuatan Form",
    FormBuilder:"Pembuatan Form",
    FormName:"Nama Form",
    EnterFormName:"Masukkan Nama Form",
    FormPage:"Halaman Form",
    AddFormPage:"Tambah Halaman Form",
    EnterFormPage:"Masukkan halaman form",
    FormCard:"Form Input",
    AddFormCard:"Tambah Form Input",
    Tag:"Penanda",
    EnterTag:"Masukkan penanda",
    Mandatory:"Wajib Diisi",
    True:"Ya",
    False:"Tidak",
    UnallowedCharacters:"Karakter Tak Diperbolehkan",
    EnterUnallowedCharacters:"Masukkan karakter tak diperbolehkan",
    EnterFormType:"Masukkan tipe form",
    EnterFormTitle:"Masukkan judul form",
    Placeholder:"Placeholder",
    EnterPlaceholder:"Masukkan placeholder",
    MaxLength:"Jumlah Karakter Maksimum",
    EnterMaxLength:"Masukkan jumlah karakter maksimum",
    LineCount:"Jumlah Baris",
    EnterLineCount:"Masukkan jumlah baris",
    ImageDescription:"Deskripsi Gambar",
    ImageDirectory:"Direktori Gambar",
    ExampleReplacer:"Contoh format direktori",
    ExampleDirectoryFormat:"Contoh format direktori",

    PleaseEnterFormTag:"Mohon masukkan penanda form",
    PleaseEnterAFormTitle:"Mohon masukkan judul form",
    SaveForm:"Simpan Form",
    UserList:"Daftar User",
    Installation:"Instalasi",
    Installed:"Terpasang",
    Uninstalled:"Tidak Terpasang",
    SoftwareInstallation:"Pemasangan Aplikasi",
    LastOpen:"Terakhir Membuka",
    EnterReceiverEmail:"Masukkan email-email penerima",
    SlugAlreadyBeenUsed:"Slug sudah digunakan",
    Subtitle:"Sub judul",
    EnterSubtitle:"Masukkan sub judul",
    PleaseEnterFormName:"Mohon masukkan nama form",
    EnterRoleName:"Masukkan nama role",
    EnterMidtransMediatorLabel:"Masukkan label untuannel media midtrans",
    EnterMidtransMediatorName:"Masukkan nama channel media midtrans",

    EnterGroupMenu:"Masukkan nama grup menu",
    EnterQueryKey:"Masukkan kunci query",
    EnterReference:"Masukkan referensi",
    EnterRecommendedInformation:"Masukkan tambahan informasi (Disarankan)",
    EnterRecommendedReceiptCode:"Masukkan kode receipt (Disarankan)",
    EnterFAQTitle:"Masukkan judul FAQ",
    EnterFAQSubTitle:"Masukkan sub judul FAQ",
    EnterWebsite:"Masukkan nama website",
    EnterBusinessField:"Masukkan bidang usaha",
    EnterBuildDate:"Masukkan tanggal berdiri",
    EnterCompanyAddress:"Masukkan alamat perusahaan",
    EnterStartPeriod:"Masukkan Periode Awal",
    EnterEndPeriod:"Masukkan Periode Berakhir",
    EnterIdCardNumber:"Masukkan no id card / KTP",
    ClickButtonToGenerate:"Klik tombol untuk menggenerate otomatis",
    Organization:"Organisasi",
    EnterOrganization:"Masukkan Organisasi",
    EnterAccountName:"Masukan nama akun",
    AccountName:"Nama Akun",
    EnterAccountNumber:"Masukkan nomor/kode akun",
    AccountNumber:"Nomor / Kode Akun",
    EnterForSpecificEmailSender:"Masukkan email sebagai email pengirim",
    ModelDetail:"Detil Model",
    TimeValidating:"Waktu Proses Validasi",
    ModelIconRatio:"Icon Model, Dimensi = 1 (lebar) * 1 (tinggi)",
    InspectionDetail:"Detil Inspeksi",
    ModelAddressLink:"Link Alamat URL Model",
    Platform:"Platform",
    DeviceId:"Device ID",
    FCMToken:"Token Firebase Cloud Messaging",
    Delete:"Hapus",
    SearchDeviceId:"Cari berdasarkan device id...",
    PleaseAddAMinimumSingleForm:"Mohon tambahkan setidaknya satu form",
    PleaseEnterGroupFormName:"Mohon masukkan nama group form",
    ManufacturingSite:"Manufacturing Site",
    ManufacturingSiteDetail:"Detil Manufacturing Site",
    EnterManufacturingSiteName:"Mohon masukkan nama manufacturing site",
    PleaseSelectManufacturingSite:"Mohon pilih manufacturing site",
    SequenceStartSerialNumber:"Sekuensial serial number mulai dari",
    EnterSequenceStartSerialNumber:"Masukkan Sekuensial serial number mulai dari",
    Other:"Lainnya",


}
const baru = {
    SearchByCustomerNameOrModelNameOrRefCode:"Cari berdasarkan pengguna, model atau kode referensi...",
    LimitType:"Jenis Pembatasan",
    Limit:"Batasan",
    VerificationType:"Jenis Verifikasi",
    WorkerVerificationType:"Status User Terverifikasi",
    InspectionCount:"Jumlah Inspeksi",
    TotalFee:"Total Komisi",
    InspectionReferenceCode:"Kode Referensi Inspeksi",
    BrandReferenceCode:"Kode Referensi Merk",
    StatusHistory:"Riwayat Status",
    BrandStatus:"Status Merk",
    ModelApplicationInfo:"Informasi Pengajuan Model",
    SelectVerificationStatus:"Pilih Status Verifikasi",
    SelectUserStatus:"Pilih Status User",
    ReferralAgent:"Pengundang",
    EverLoggedIn:"Pernah Login",
    Never:"Tidak Pernah",
    TotalIncome:"Total Pemasukan",
    CurrentBalance:"Saldo Saat Ini",
    TotalWithdrawal:"Total Penarikan",
    CompletedInspection:"Inspeksi Selesai",
    PostponedInspection:"Inspeksi Ditunda",
    IDCard:"Kode / ID KTP",
    InvalidEmailAddress:"Email tidak valid",
    Regenerate:"Regenerate",
    Generate:"Generate",
    UserDetail:"Detil User",
    AddUser:"Tambah User",
    Location:"Lokasi",
    LocationOnMap:"Lokasi Di Peta",
    OpenInMap:"Buka Di Peta",
    EnterTitleSubtitleSlugBrandOrCategoryToSearch:"Masukkan judul, subjudul, slug, merk, category untuk mencari..",
    SearchNameEmailPhoneUserCodeOrOrganization:"Cari nama, email, telp, kode user atau organisasi..",
    LastOpenStart:"Terakhir Membuka Mulai",
    LastOpenEnd:"Terakhir Membuka Hingga",
    Male:"Pria",
    Female:"Wanita",
    UnrecognizedDueNotAuthenticated:"Tidak dikenali karena tidak melakukan login",
    EnterDeviceIdUserCodeOrNameToSearch:"Masukkan Device Id, nama user atau kode user untuk mencari..",
    AuthenticatedAsUser:"Login Sebagai User",
    NotAuthenticatedAsUser:"Tidak Login Sebagai User",
    AuthenticationOption:"Pilihan Otentikasi",
    ReceiverOption:"Pilihan Penerima",
    NotificationDetail:"Detil Notifikasi",
    SendingConfirmationTitle:"Sending Confirmation",
    DoYouWantToSendThisNotificationWithTheSelectedTopic:"Do you want to send this notification with the selected topic ?",
    InspectionMustBeingOrderedSequentially:"Inspeksi harus dilakukan secara berurutan satu per satu",
    InspectionCanBeAppliedMoreThanOneSimultaneously:"Inspeksi dapat dilakukan lebih dari satu secara bersamaan",
    InspectionSubmissionOrder:"Urutan Inspeksi",
    MaxUsagePerUser:"Batas maksimal inspeksi per user",
    MaxInspectionPerUserDefaultUnlimitedPlaceholder:"Maksimal Inspeksi @User (0 = tak terbatas)",
    MaximumInspectionCanBeApplied:"Jumlah Maksimal Inspeksi Yang Dapat Diajukan",
    MaximumApplicationUsage:"Jumlah Maksimal Inspeksi",
    CurrentApplicationUsage:"Jumlah Inspeksi Telah Terjadi Saat Ini",
    MaxUsagePerUserCannotBeLargerThanMaxUsageGeneral:"Maximum inspeksi per user tidak boleh lebih besar daripada jumlah maksimal pengajuan",
    MaxUsageCannotBeSmallerThanCurrentUsage:"Maximum inspeksi tidak boleh lebih kecil daripada jumlah pengajuan saat ini",
    PleaseEnterModelAddressLink:"Mohon lengkapi link alamat url model",
    PleaseEnterCallbackId:"Mohon lengkapi callback Id",
    PleaseEnterGroupForm:"Mohon lengkapi isian group form",
    PleaseEnterModelTimeValidationByAdmin:"Mohon lengkapi isian lama waktu validasi oleh admin",
    ModelFee:"Komisi Model",
    PleaseEnterModelFee:"Mohon lengkapi komisi model",
    CanBeAppliedByUserVerificationAt:"Dapat dilakukan oleh user dengan status verifikasi",
    CanOnlyBeAppliedByVerifiedUser:"Hanya dapat dilakukan oleh user terverifikasi",
    CanBeAppliedByUnverifiedUser:"Dapat dilakukan oleh user yang tidak terverifikasi",
    TimeApplicationValidationByAdminInHour:"Lama Proses Memvalidasi Oleh Admin (Dalam Hitungan Jam)",
    PleaseEnterDescription:"Mohon masukkan deskripsi",
    PleaseEnterReferenceDocument:"Mohon masukkan dokumen referensi",
    ReferenceDocument:"Dokumen Referensi",
    MainModelDetail:"Detil Model Utama",
    EnterMainModelLabel:"Masukkan label model utama",
    SelectModel:"Pilih Model",
    MainModelIcon:"Ikon model utama",
    MultiFinanceDetail:"Detil Multi Finance",
    Label:"Label",
    EnterMultiFinanceLabel:"Masukkan label multi finance",
    EnterMultiFinanceName:"Masukkan nama multi finance",
    EnterLabelOfInstallmentPeriod:"Enter label of installment period",
    EnterPeriodOfInstallmentPeriod:"Enter tenor of installment period",
    InstallmentPeriod:"Tenor",
    InstallmentPeriodDetail:"Detil Tenor",
    EnterDiscussionCategoryName:"Masukkan nama kategori diskusi",
    DiscussionCategoryDetail:"Detil Kategori Diskusi",
    AgentFee:"Komisi User",
    AgentFeeDetail:"Detil Komisi User",
    SelectProductAgent:"Pilih Produk",
    SelectMultiFinance:"Pilih MultiFinance",
    AmountFee:"Komisi dalam rupiah",
    PercentFee:"Komisi dalam persentasi (%)",
    SelectInstallmentPeriod:"Pilih Periode Tenor",
    PreviewCount:"Jumlah Preview",
    ShareCount:"Jumlah Share",
    CommentCount:"Jumlah Komentar",
    ReplyCount:"Jumlah Balasan",
    Comment:"Komentar",
    CommentReply:"Balasan Komentar",
    PartName:"Part Name",
    PN:"P/M",
    ACInput:"AC Input",
    Frequency:"Frequency",
    ACInputCurrent:"Ac Input Current",
    DCOutput:"DC Output",
    Polarity:"Polarity",
    CORef:"CO Ref",
    Warranty:"Garansi",

    EnterPartName:"Masukkan Part Name",
    EnterPn:"Masukkan PN",
    EnterACInput:"Enter AC Input",
    EnterFrequency:"Masukkan Frequency",
    EnterACInputCurrent:"Masukkan Ac Input Current",
    EnterDCOutput:"Masukkan DC Output",
    EnterPolarity:"Masukkan Polarity",
    EnterCORef:"Masukkan CO Ref",







}
const menu = {
    Dashboard:"Dasbor",
    Account:"Akun",
    Product:"Produk",
    Brand:"Merk",
    Heading:"Menu",
    Category:"Kategori",
    ItemProduct:"Produk",
    Inventory:"Inventori",
    Popular:"Populer",
    Latest:"Terbaru",
    MainProduct:"Produk Utama",
    StockAudit:"Kontrol Stok",
    Program:"Program",
    Discount:"Diskon",
    Voucher:"Voucher",
    Point:"Poin",
    PointName:"Nama Poin",
    FlashSale:"Flash Sale",
    Shopper:"Perbelanjaan",
    Customer:"Customer",
    Transaction:"Transaksi",
    Cart:"Keranjang",
    Activity:"Aktifitas",
    CustomerActivity:"Aktifitas Customer",
    AdminActivity:"Aktifitas Admin",
    Miscellaneous:"Aksesoris",
    Banner:"Banner",
    Selebgram:"Selebgram",
    Color:"Warna",
    Size:"Ukuran",
    OnlineShop:"Toko Online",
    Branches:"Cabang",
    CustomerGuide:"Panduan Customer",
    Announcement:"Pengumuman",
    Broadcast:"Distribusi Pesan",
    Subscriber:"Subscriber",
    Administrator:"Administrator",
    Configuration:"Konfigurasi",
    Admin:"Admin",
    Role:"Hak Akses",
    UserRole:"Hak Akses User",
    PaymentMedia:"Media Pembayaran",
    Bank:"Bank",
    BankAccount:"Rekening",
    MidtransMediator:"Media Midtrans",
    Courier:"Kurir",
    Region:"Wilayah",
    Province:"Propinsi",
    City:"Kota",
    District:"Kecamatan",
    Village:"Kelurahan",
    PaymentTypeCode:"Kode Tipe Pembayaran",
    EnterPaymentTypeCode:"Masukkan Kode Tipe Pembayaran",
    AmountFee:"Fee Nominal",
    PercentFee:"Fee Persentasi",
    EnterAmountFee:"Masukkan Fee Nominal",
    EnterPercentFee:"Masukkan Fee Persentasi",
    EnterCourierLabel:"Masukkan Label Kurir",
    CourierCode:"Kode Kurir",
    Code:"Kode",
    RequestWithdrawal:"Request Penarikan Dana",
    TransactionDetail:"Detil Transaksi",
    TransactionInfo:"Informasi Transaksi",
    ResiCode:"Kode Resi",
    CourierCost:"Biaya Ongkir",
    Service:"Layanan",
    TransactionStatus:"Status Transaksi",
    ItemPrice:"Harga Item",
    UniquePrice:"Harga Kode Unik",
    BubbleWrap:"Bubble Wrap",
    ShippingAddress:"Alamat Pengiriman",
    TransactionItem:"Item Transaksi",
    TotalPrice:"Harga Total",
    Source:"Sumber",
    TotalWeight:"Jumlah Berat",
    UpdateStatus:"Perbarui Status",
    CurrentStatus:"Status Saat Ini",

    Business:"Bisnis",
    Inspection:"Inspeksi",
    InspectionScheme:"Skema Inspeksi",
    InspectionEvent:"Event Inspeksi",
    SubMenu:"Sub Menu",
    Model:"Model",
    FormBuilder:"Pembuatan Form",
    Worker:"Data Pekerja",
    QCManagement:"Manajemen QC",
    Devices:"Perangkat Device",
    UserDevices:"Device User",
    AdminDevices:"Device Admin",
    ConfigurationAdmin:"Konfigurasi Admin",
    User:"User",
    Verification:"Verifikasi",
    RegHistory:"Riwayat Pendaftaran",
    Withdraw:"Penarikan Dana",
    WithdrawalRequest:"Permintaan Penarikan",
    Mutation:"Mutasi Saldo",
    UserActivity:"Aktifitas Pengguna",
    Notification:"Notifikasi",
    ApplicationVersion:"Versi Aplikasi",
    MainModel:"Model Utama",
    Reward:"Reward",
    Fee:"Komisi",
    MultiFinance:"Multi Finance",
    InstallmentPeriod:"Tenor",
    Discussion:"Diskusi",
    DiscussionCategory:"Kategori Diskusi",
    CategoryImage:"Gambar Kategori",
    UserPhoto:"Foto User",
    IPAddress:"IP Address",
    UserAgent:"User Agent",
    SystemType:"System Type",
    ModelName:"Nama Model",
    Form:"Form",
    EnterSystemType:"Masukkan nama system type / model",
    EnterModelName:"Masukkan nama model",
    Scope:"Scope",
    TestingEquipmentAndAccessories:"Testing Equipment And Accessories",
    SystemSpecification:"Spesifikasi Sistem",
    SerialComponent:"Serial Component",
    SerialComponentList:"Daftar Serial Component",
    Value:"Value",
    PassInspection:"Inspeksi Lulus",
    NotPassInspection:"Inspeksi Belum Lulus",
    PostponedInspection:"Inspeksi Ditunda",
    SerialCode:"Serial Number",
    InspectionList:"Daftar Inspeksi",
    BrandCategory:"Kategori",
    SelectBrandCategory:"Pilih Kategori",
    Searching:"Pencarian",
    SearchReferenceSerialModelUser:"Cari kode referensi, serial, model, customer, client, product SKU, product name, atau user.. ",
    Copy:"Copy",
    CopyToTheNewFormBuilder : (formName) => "Salin ("+formName+") menjadi sebuah form builder baru",
    CopyToTheNewForm : (formName) => "Salin ("+formName+") menjadi sebuah form input baru",
    NewFormBuilderName:"Nama Form Baru",
    EnterNewFormBuilderName:"Masukkan nama form baru",
    CreatePage:"Pembuatan Halaman",
    PageTitle:"Judul Halaman",
    EnterPageTitle:"Masukkan judul halaman",
    PleaseEnterPageTitle:"Mohon masukkan judul halaman",
    ApiDescription:"Deskripsi API",
    PositiveLabel:"Label untuk tipe positif",
    EnterPositiveLabel:"Masukkan label untuk tipe positif",
    NegativeLabel:"Label untuk tipe negative",
    EnterNegativeLabel:"Masukkan label untuk tipe negative",
    HidePrintout:"Sembunyikan Cetakan",
    FormInputConfiguration:"Konfigurasi Form Input",
    SizeOfRectifierControllerSerialNumber:"Jumlah Controller/Rectifier SN",
    SubInput:"Sub Input",
    Type:"Tipe",
    DoYouWantToRemoveThisFormInput:"Apakah anda ingin membuang form input ini ?",
    DoYouWantToRemoveThisLane:"Apakah anda ingin membuang halaman ini ?",
    QualityController:"Quality Controller",
    YourInspectionHistory:"Riwayat Inspeksi Mu",
    ThereIsNoHistory:"Belum ada riwayat",
    NewInspection:"Inspeksi Baru",
    SerialCodeOrReferenceCode:"SN or reference code",
    SearchInspection:"Cari Inspeksi",
    ContinueInspection:"Lanjutkan Inspeksi",
    InspectionDetailInfo:"Informasi Detil Inspeksi",
    CreateNewInspection:"Buat Inspeksi Baru",
    ModelOrSystemType:"Model / System Type",
    ItemInspectionIsNotAvailable:"Inspeksi Tidak Ditemukan",
    DoYouWantToCreateNewInspection:"Apakah anda ingin membuat data inspeksi baru ?",
    DoYouWantToContinueInspection:"Apakah anda ingin melanjutkan inspeksi item ini ?",
    InspectionItem:"Inspeksi Item",
    TakingInspectionItem:"Melakukan Inspeksi Item",
    ModelInformation:"Informasi Model",
    CurrentInspectionStatus : "Status Saat Ini",
    PerformedBy : "Dilakukan Oleh",
    PreviousInspectionPerformedBy:"User Inspeksi Sebelumnya",
    PreviousInspectionStatus:"Status Inspeksi Sebelumnya",
    PreviousInspectionDate:"Waktu Inspeksi Sebelumnya",
    LastInspectionPerformedBy:"User Inspeksi Terakhir",
    LastInspectionDate:"Waktu Inspeksi Terakhir",
    LastInspectionStatus:"Status Inspeksi Terakhir",
    InspectionStatus:"Status Inspeksi",
    Next:"Selanjutnya",
    Previous:"Sebelumnya",
    PageNext:"Selanjutnya >>",
    PagePrevious:"<< Sebelumnya",
    InspectionInstruction:"Instruksi Inspeksi",
    TakePicture:"Ambil Gambar",
    SetPicture:"Tetapkan Gambar",
    OkIUnderstand:"Baik Saya Mengerti",
    RectifierControllerSerialNumber:"Controller/Rectifier Serial Number",
    ConfirmSubmission:"Konfirmasi Pengiriman Data",
    ScanTheSerialNumber:"Scan Serial Number",
    OrEnterItManually:"Atau masukkan secara manual",
    YouWillSubmitInspectionWithReferenceVariable1AndSerialCodeVariable2WithStatusVariable3_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan mengirim data inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong>dan status inspeksi menjadi<strong><h3>"+status+"</h3></strong><p>Apakah anda sudah yakin dengan informasi tersebut?</p>",
    YesImSure:"Ya, Saya yakin",
    YouWillCloseInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan mengubah data inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong>dan status inspeksi menjadi<strong><h3>"+status+"</h3></strong><p>Apakah anda sudah yakin dengan informasi tersebut?</p>",
    YouWillPostponeInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan membatalkan inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>Tindakan ini akan membuat inspeksi dapat dilakukan oleh user lain, apakah anda yakin ?</p>",
    YouWillSuspendInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan mensuspend inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>Tindakan ini akan membuat inspeksi tidak dapat dilakukan siapapun, apakah anda yakin dengan hal tersebut ?</p>",
    PleaseEnterAFieldValueVariable : (variableName) => "Mohon lengkapi "+variableName,
    EnterMessageIfYouNeedTo:"Tambahkan pesan jika kamu membutuhkannya",
    EnterNoteIfYouNeedTo:"Tambahkan catatan jika kamu membutuhkannya",
    InspectionMessage:"Pesan Inspeksi",
    InspectionNote:"Catatan Inspeksi",
    Preview:"Preview",
    FormInspectionDetail:"Detil Form Inspeksi",
    CloseStatusAndPrint:"Close Status & Cetak",
    ClickToExpandMoreInformation:"Klik untuk membuka informasi lebih",
    CLoseStatus:"Close",
    SuccessUpdatingStatus:"Berhasil Memperbarui Status",
    FailedUpdatingStatus:"Gagal Memperbarui Status",
    Reprint:"Reprint",
    Checking:"Pengecekan",
    CheckingList:"Daftar Pengecekan",
    UserStatus:"Status Pengguna",
    AdminStatus:"Status Admin",
    CheckingHistory:"Riwayat Pengecekan",
    CheckingActivity:"Aktifitas Pengecekan",
    PostponeInspection:"Tunda Inspeksi",
    InspectionOrderedSequentially:"Inspeksi Dikerjakan Berurutan",
    SelectSystemType:"Pilih System Type",
    OpenedInspection:"Inspeksi Terbuka",
    NA:"NA",
    SuspendInspection:"Suspend Inspeksi",
    Reopen:"Buka Kembali",
    DoYouWantToReopenThisInspection_ThisActionWillCauseTheInspectionCouldBeTakenByAnyUser:
        "Apakah anda ingin membuka kembali inspeksi ini ?\nAksi ini akan menyebabkan inspeksi ini dapat diambil oleh user siapapun, apakah anda sudah yakin ?",
    ContinueReOpenInspection:"Buka Status Inspeksi",
    PleaseEnterFirstName:"Mohon lengkapi nama depan dengan benar",
    PleaseGenerateARefCodeForThisUser:"Mohon generate kode referensi untuk user ini",
    PleaseEnterEmail:"Mohon lengkapi email dengan benar",
    PleaseSelectRoleForThisUser:"Mohon pilih hak akses untuk user ini",
    PleaseSelectRoleForThisAdmin:"Mohon pilih hak akses untuk admin ini",
    PleaseEnterARightMobilePhone:"Mohon masukkan no handphone dengan benar",
    DoYouWantToCreateANewUser:"Apakah anda ingin membuat user baru ?",
    DoYouWantToCreateANewAdmin:"Apakah anda ingin membuat admin baru ?",
    DoYouWantToUpdateInformationOfThisUser:"Apakah anda ingin memperbarui informasi mengenai user ini ?",
    DoYouWantToUpdateInformationOfThisAdmin:"Apakah anda ingin memperbarui informasi mengenai admin ini ?",
    SuccessUpdatingData:"Berhasil memperbarui data",
    DoYouWantToResendAPasswordForThisUser:"Apakah kamu ingin mengirim ulang password kepada user ini ?",
    DoYouWantToResendAPasswordForThisAdmin:"Apakah kamu ingin mengirim ulang password kepada admin ini ?",
    SuccessSendingThePassword:"Berhasil mengirim password",
    DoYouWantToUpdateThisUserStatusFrom : (previousStatus, nextStatus) => "Apakah kamu hendak mengubah status user ini dari "+previousStatus+" menjadi "+nextStatus,
    DoYouWantToUpdateThisAdminStatusFrom : (previousStatus, nextStatus) => "Apakah kamu hendak mengubah status admin ini dari "+previousStatus+" menjadi "+nextStatus,
    Reason:"Alasan",
    StatusHistory:"Riwayar Status",
    BanThisDevice_TheUserWillBeBannedAfterThisPerform:"Keluarkan perangkat ini dari aplikasi, User akan otomatis keluar dari aplikasi setelah perform ini dilakukan, apakah kamu yakin ?",
    BanThisDevice_TheAdminWillBeBannedAfterThisPerform:"Keluarkan perangkat ini dari aplikasi, Admin akan otomatis keluar dari aplikasi setelah perform ini dilakukan, apakah kamu yakin ?",
    ApplicationSetting:"Setting Aplikasi",
    Sequence:"Urutan",
    TimeActivity:"Waktu Aktifitas",
    TimePerform:"Waktu Tindakan",
    AdminDetailInformation:"Detil Informasi Admin",
    CreateNewAdmin:"Buat Admin Baru",
    AdminCode:"Kode Admin",
    NeverEnd:"Selamanya",
    Periodic:"Periodik",
    NoLimit:"Tidak Terbatas",
    Limited:"Terbatas",
    PleaseEnterPositiveLabel:"Mohon masukkan label positif",
    PleaseEnterNegativeLabel:"Mohon masukkan label negatif",
    PleaseEnterPlaceHolder:"Mohon masukkan placeholder",
    NEVER_END:"Selamanya",
    PERIODIC:"Periodik",
    NO_LIMIT:"Tidak Terbatas",
    LIMITED:"Terbatas",
    CustomerOrderNo:"Customer Order No",
    Client:"Client",
    ProductSKU:"Product SKU",
    ProductName:"Nama Produk",
    Controller:"Controller",
    FWVersion:"FW Version",
    ControllerInfo:"Informasi Controller",
    CustomerInfo:"Informasi Customer",
    OrderNo:"Order No",
    DoYouWantToChangeYourPassword:"Apakah kamu ingin mengubah password mu ?",
    OldPasswordMustBeEntered:"Password lama harus diisi",
    NewPasswordMustBeEntered:"Password baru harus diisi",
    ConfirmPasswordMustBeEntered:"Konfirmasi password harus diisi",
    PasswordMustAtLeastContain6Characters:"Password setidaknya minimal terdiri dari 6 karakter",
    OldPasswordAndNewPasswordCannotBeSame:"Password lama dan password baru tidak boleh sama",
    ConfirmPasswordAndNewPasswordIsNotSame:"Konfirmasi password dan password baru tidak sesuai",
    Performer:"Performer",
    LastPerformer:"Performer Terkahir",
    PreviousPerformer:"Performer Sebelumnya",
    TimeAgo_future: "dalam %s",
    TimeAgo_past: "%s lalu",
    TimeAgo_s: 'beberapa detik yang lalu',
    TimeAgo_m: "semenit yang lalu",
    TimeAgo_mm: "%d menit lalu",
    TimeAgo_h: "sejam yang lalu",
    TimeAgo_hh: "%d jam lalu",
    TimeAgo_d: "sehari yang lalu",
    TimeAgo_dd: "%d hari lalu",
    TimeAgo_M: "sebulan yang lalu",
    TimeAgo_MM: "%d bulan lalu",
    TimeAgo_y: "setahun yang lalu",
    TimeAgo_yy: "%d tahun lalu",
    LastModification:"Perubahan Terakhir",
    DocumentNumber:"Nomer Dokumen",
    EnterDocumentNumber:"Enter Document Number",
    PleaseEnterDocumentNumber:"Mohon masukkan nomor dokumen",
    NewDocumentNumber:"Nomer Dokumen Baru",
    EnterNewDocumentNumber:"Masukkan Nomer Dokumen Baru",
    PleaseEnterNewDocumentNumber:"Mohon masukkan nomor dokumen baru",
    InternalPrint:"Cetak Internal",
    ExternalPrint:"Cetak Eksternal",
    SuccessSavingInspection:"Berhasil Menyimpan Inspeksi",
    InspectionEventDetail:"Detil Event Pengecekan",

    // start configuration admin
    EnterHostMail:"Masukan nama host email",
    PleaseEnterHostMail:"Mohon masukan nama host email",

    EnterPortMail:"Masukan port email",
    PleaseEnterPortMail:"Mohon masukan port email",

    EnterUsernameMail:"Masukan username email",
    PleaseEnterUsernameMail:"Mohon masukan username email",

    EnterPasswordMail:"Masukan password email",
    PleaseEnterPasswordMail:"Mohon masukan password email",

    EnterReplyToMail:"Masukan email balasan",
    PleaseEnterReplyToMail:"Mohon masukan email balasan",

    EnterFromMail:"Masukan email pengirim",
    PleaseEnterFromMail:"Mohon masukan email pengirim",

    DoYouWantToUpdateInformationOfConfigurationAdmin:"Apakah anda ingin memperbarui informasi mengenai konfigurasi ini ?",
    TestSendMail:"Tes Kirim Email",
    // end configuration admin


}
module.exports = Object.freeze(Object.assign(label,menu, baru))

