/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.19.577 on 2021-04-22 23:27:35.

export class EBaseDto implements Serializable {
    id: number;
    created: Date;
    createdBy: number;
    updated: Date;
    updatedBy: number;
    active: boolean;
}

export class WSResponse<D, E> {
    code: number;
    message: string;
    messageError: E;
    data: D;
    totalElement: number;
    totalPage: number;
    pageElement: number;
    success: boolean;
}

export class AdminDashboard extends EBaseDto {
    totalUser: number;
    thisMonthUser: number;
    previousMonthUser: number;
    totalModel: number;
    thisMonthModel: number;
    previousMonthModel: number;
    totalModelDone: number;
    thisMonthModelDone: number;
    previousMonthModelDone: number;
    totalPayment: number;
    thisMonthPayment: number;
    previousMonthPayment: number;
    thisMonthModelPercent: number;
    thisMonthModelDonePercent: number;
    thisMonthPaymentPercent: number;
    thisMonthUserPercent: number;
}

export class BarInfo {
    id: number;
    label: string;
    startDate: Date;
    endDate: Date;
}

export class DashboardCategoryAnalytic {
    barInfos: BarInfo[];
    datasets: DataSet<number>[];
    allUserIncomeAmount: number;
    allUserWithdrawAmount: number;
}

export class DataSet<OBJECT> {
    label: string;
    backgroundColor: string;
    borderColor: string;
    stack: string;
    data: OBJECT[];
}

export class BrandDto extends EBaseDto {
    brandType: string;
    fullName: string;
    website: string;
    email: string;
    image: string;
    imageUrl: string;
    point: number;
    selfPromotion: boolean;
    corporate: CorporateDto;
    personal: PersonalDto;
    brandStatus: BrandStatusDto;
    idCard: string;
    siupOrNwpw: string;
    siupOrNwpwUrl: string;
    idCardUrl: string;
    modelCount: number;
}

export class BrandStatusDto extends EBaseDto {
    name: string;
    description: string;
}

export class BusinessAddressDto extends EBaseDto {
    title: string;
    address: string;
    village: VillageDto;
    latitude: number;
    longitude: number;
}

export class CorporateDto extends EBaseDto {
    corporateName: string;
    buildDate: Date;
    officePhoneNumber: string;
    officeFax: string;
    corporateDescription: string;
    logo: string;
    latitude: number;
    longitude: number;
    businessField: string;
    address: string;
    village: VillageDto;
    corporateType: CorporateTypeDto;
    logoUrl: string;
}

export class CorporateTypeDto extends EBaseDto {
    name: string;
}

export class DirectorDto extends EBaseDto {
    name: string;
    phoneNumber: string;
}

export class NumberOfEmployeeDto extends EBaseDto {
    rangeStart: number;
    rangeEnd: number;
}

export class PVBrandStatusDto extends EBaseDto {
    brand: BrandDto;
    brandStatus: BrandStatusDto;
    cause: string;
    note: string;
}

export class PersonalDto extends EBaseDto {
}

export class AgentFeeDto extends EBaseDto {
    productName: string;
    multiFinance: MultiFinanceDto;
    installmentPeriod: InstallmentPeriodDto;
    percentFee: number;
    amountFee: number;
    totalFee: number;
}

export class FormApplicationDto extends EBaseDto {
    formLaneApplication: FormLaneApplicationDto;
    valueId: number;
    form: Form;
    value: string;
    imageUrl: string;
}

export class FormLaneApplicationDto extends EBaseDto {
    title: string;
    laneId: number;
    formApplications: FormApplicationDto[];
}

export class FormTransitDto extends EBaseDto {
    cookieValue: string;
    model: ModelDto;
    user: UserDto;
    submitted: boolean;
}

export class InspectionDto extends EBaseDto {
    serialCode: string;
    user: UserDto;
    model: ModelDto;
    inspectionStatus: InspectionStatusDto;
    reference: string;
    groupFormName: string;
    viewReference: string;
}

export class InspectionEventDto extends EBaseDto {
    inspection: InspectionDto;
    user: UserDto;
    inspectionStatus: InspectionStatusDto;
    reference: string;
    groupFormName: string;
    viewReference: string;
    formLaneApplications: FormLaneApplicationDto[];
}

export class InspectionStatusDto extends EBaseDto {
    name: string;
    label: string;
    description: string;
    sortir: number;
}

export class Option extends EBaseDto {
    name: string;
}

export class InstallmentPeriodDto extends Option {
    period: number;
    label: string;
}

export class MainModelDto extends EBaseDto {
    label: string;
    iconImage: string;
    iconImageUrl: string;
    sortir: number;
    model: ModelDto;
}

export class ModelBrandStatus implements Serializable {
    statusOrder: number;
    statusCode: string;
    statusLabel: string;
    statusInfo: string;
    created: Date;
    finalStatus: boolean;
}

export class ModelDto extends EBaseDto {
    systemType: string;
    name: string;
    description: string;
    slug: string;
    autoSlug: boolean;
    published: boolean;
    periodType: string;
    scope: string;
    referenceDocument: string;
    testingEquipmentAndAccessories: string;
    startPeriod: Date;
    endPeriod: Date;
    inspectionLimitType: string;
    inspectionLimitCount: number;
    inspectionCount: number;
    iconPhoto: string;
    iconPhotoUrl: string;
    callbackId: string;
    modelCategory: ModelCategoryDto;
    groupForm: GroupFormDto;
    suggestion: number;
    type: string;
    applyable: boolean;
    message: string;
    completeUrl: string;
    shareableAddress: string;
    sequentially: boolean;
    inspectionLimitPerUser: number;
    sizeOfRectifierControllerSerialNumber: number;
    situationInfo: string;
}

export class MultiFinanceDto extends Option {
    label: string;
}

export class MutationInfo implements Serializable {
    entityClassName: string;
    id: number;
    description: string;
}

export class PVInspectionStatusDto extends EBaseDto {
    inspection: InspectionDto;
    inspectionStatus: InspectionStatusDto;
    note: string;
}

export class PVWithdrawalRequestStatusDto extends EBaseDto {
    withdrawalRequest: WithdrawalRequestDto;
    withdrawalStatus: WithdrawalStatusDto;
    adminExecutor: AdminDto;
    note: string;
    accountNumber: string;
    bank: BankDto;
    receiptCode: string;
    image: string;
    imageUrl: string;
    statusName: string;
    statusDescription: string;
}

export class ProductAgent extends Option {
    productName: string;
}

export class ProductResult {
    exists: boolean;
    size: number;
}

export class WithdrawalRequestDto extends EBaseDto {
    user: UserDto;
    amount: number;
    pvWithdrawalRequestStatuses: PVWithdrawalRequestStatusDto[];
    withdrawalStatus: WithdrawalStatusDto;
    imageUrl: string;
}

export class WithdrawalStatusDto extends EBaseDto {
    name: string;
    description: string;
}

export class AttachmentDto extends EBaseDto {
    filename: string;
    fileLink: string;
}

export class SendEmailDto extends EBaseDto {
    subject: string;
    content: string;
    allCustomer: boolean;
    allSubscriber: boolean;
    sendStatus: string;
    sendDirectly: boolean;
    sendAt: Date;
    regularSender: boolean;
    sender: string;
    customerEmails: SendEmailPreference[];
    subscriberEmails: SendEmailPreference[];
    attachments: AttachmentDto[];
    contentType: boolean;
}

export class SendEmailPreference implements Serializable {
    email: string;
    responseStatus: string;
}

export class Form extends EBaseDto {
    tag: string;
    title: string;
    positiveValue: string;
    negativeValue: string;
    placeHolder: string;
    type: string;
    typeId: number;
    mandatory: boolean;
    allowedCharacters: string;
    unallowedCharacters: string;
    maxLength: number;
    line: number;
    fetchApi: string;
    queryParams: QueryParam[];
    imageDirectory: string;
    dependAnotherTag: boolean;
    subInputs: Form[];
}

export class FormLane extends EBaseDto implements Comparable<FormLane> {
    title: string;
    description: string;
    cards: Form[];
}

export class GroupFormDto extends EBaseDto {
    name: string;
    formLanes: FormLane[];
}

export class QueryParam implements Serializable {
    key: string;
    reference: string;
}

export class CommentDto extends EBaseDto {
    message: string;
    discussion: DiscussionDto;
    userCreator: UserDto;
    adminCreator: AdminDto;
    commentReplyCount: number;
}

export class CommentReplyDto extends EBaseDto {
    message: string;
    comment: CommentDto;
    userCreator: UserDto;
    adminCreator: AdminDto;
}

export class DiscussionCategoryDto extends Option {
    sortir: number;
    icon: string;
    hexaColor: string;
    topic: string;
    iconUrl: string;
    count: number;
}

export class DiscussionDto extends EBaseDto {
    image: string;
    title: string;
    description: string;
    imageLink: string;
    userCreator: UserDto;
    adminCreator: AdminDto;
    discussionCategory: DiscussionCategoryDto;
    seeCount: number;
    shareCount: number;
    commentCount: number;
}

export class PushNotificationResponse {
    status: number;
    message: string;
}

export class ApplicationVersionDto extends EBaseDto {
    androidVersionCode: number;
    androidVersionName: string;
    forceToUpdate: boolean;
    title: string;
    description: string;
    slug: string;
    completeUrl: string;
}

export class BannerDto extends EBaseDto {
    image: string;
    startDate: Date;
    endDate: Date;
    sortir: number;
    pagelink: string;
    title: string;
    description: string;
    previewType: string;
    imageLink: string;
}

export class ConfigurationDto extends EBaseDto {
    workingAgreementFileUrl: string;
    name: string;
    companyName: string;
    companyDescription: string;
    companyAddress: string;
    companyVillage: VillageDto;
    companyPhoneNumber: string;
    companyFaximile: string;
    contactPersonPhoneNumber: string;
    contactWhatsapp: string;
    pageRowSize: number;
    hashtag: string;
    instagramLink: string;
    facebookLink: string;
    twitterLink: string;
    minWithdrawal: number;
    facebookAppId: string;
    googleClientId: string;
    baseApi: string;
    bannerWidth: number;
    bannerHeight: number;
    mobileBannerWidth: number;
    mobileBannerHeight: number;
    regularEmailSender: string;
    mailDomain: string;
    modelBannerWidth: number;
    modelBannerHeight: number;
}

export class DataPage<O> {
    datas: O[];
    pageElement: number;
    totalPage: number;
    totalElement: number;
}

export class FaqDto extends EBaseDto {
    title: string;
    subtitle: string;
    link: string;
    image: string;
    imageLink: string;
}

export class ModelCategoryDto extends EBaseDto {
    sortir: number;
    name: string;
    icon: string;
    hexaColor: string;
    topic: string;
    count: number;
    totalModel: number;
    totalAvailableModel: number;
    iconUrl: string;
    coloredIconUrl: string;
}

export class NotificationData implements Serializable {
    notificationId: string;
    referenceId: string;
    referenceClass: string;
    addressLink: string;
}

export class NotificationDto extends EBaseDto implements Serializable {
    title: string;
    message: string;
    topic: string;
    type: string;
    data: NotificationData;
    admin: AdminDto;
    image: string;
    imageLink: string;
}

export class NotificationUserDto extends EBaseDto implements Serializable {
    user: UserDto;
    notification: NotificationDto;
    userDevice: UserDeviceDto;
    read: boolean;
}

export class WorkingDayDto extends EBaseDto implements Serializable {
    name: string;
}

export class BankAccountDto extends EBaseDto {
    bank: BankDto;
    customerName: string;
    accountCode: string;
}

export class BankDto extends Option {
    label: string;
    code: string;
    image: string;
    imageLink: string;
}

export class PersonalityCharacterDto extends EBaseDto {
    title: string;
    type: string;
    personality: PersonalityDto;
}

export class PersonalityDto extends EBaseDto {
    title: string;
}

export class CityDto extends Option {
    platNumber: string;
    province: ProvinceDto;
    courierId: string;
}

export class DistrictDto extends Option {
    city: CityDto;
    courierId: string;
}

export class ProvinceDto extends Option {
    countryCode: string;
    courierId: string;
}

export class VillageDto extends Option {
    postalCode: string;
    district: DistrictDto;
}

export class ReportCategoryDto extends EBaseDto {
    name: string;
    description: string;
}

export class ReportStatusDto extends EBaseDto {
    name: string;
    description: string;
}

export class MenuDto extends EBaseDto {
}

export class RoleDto extends EBaseDto {
    name: string;
    roleMenus: RoleMenuDto[];
    admins: AdminDto[];
}

export class RoleMenuDto extends EBaseDto {
}

export class PurchaseStatusDto extends EBaseDto {
    name: string;
    description: string;
}

export class AdminDto extends EBaseDto {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    image: string;
    imageLink: string;
    adminStatus: string;
    role: RoleDto;
    fullname: string;
}

export class OAuthPartyDto extends EBaseDto {
    party: string;
    partyId: string;
    email: string;
    name: string;
    token: string;
    profileLink: string;
}

export class PersonDto extends EBaseDto {
    email: string;
    homePhoneNumber: string;
    mobilePhone: string;
    firstname: string;
    lastname: string;
    photo: string;
    birthdate: Date;
    gender: string;
    maritalStatus: string;
    latitude: number;
    longitude: number;
    address: string;
    village: VillageDto;
    fullname: string;
    photoUrl: string;
}

export class RegistrationDto {
    firstname: string;
    lastname: string;
    mobilePhone: string;
    email: string;
    userCode: string;
    fullname: string;
}

export class UserDashboard extends EBaseDto {
    totalUser: number;
    thisMonthUser: number;
    previousMonthUser: number;
    totalModel: number;
    thisMonthModel: number;
    previousMonthModel: number;
    totalModelDone: number;
    thisMonthModelDone: number;
    previousMonthModelDone: number;
    totalPayment: number;
    thisMonthPayment: number;
    previousMonthPayment: number;
    thisMonthModelPercent: number;
    thisMonthModelDonePercent: number;
    thisMonthPaymentPercent: number;
    thisMonthUserPercent: number;
}

export class UserDeviceDto extends EBaseDto {
    fcmToken: string;
    platform: string;
    userAgent: string;
    ipAddress: string;
    deviceId: string;
    latitude: number;
    longitude: number;
    lastOpenDate: Date;
}

export class UserDto extends PersonDto {
    userCode: string;
    password: string;
    role: string;
    agreeTermAndCondition: boolean;
    firstLogin: boolean;
    onGoingModel: number;
    totalInspection: number;
    totalNotPassInspection: number;
    totalExpiredModel: number;
    totalRejectedModel: number;
    userStatus: string;
    verificationStatus: string;
    registration: RegistrationDto;
    userDevices: UserDeviceDto[];
    installed: boolean;
    deviceCount: number;
    authorization: string;
    registrationToken: string;
    lastOpenDate: Date;
    passwordSet: boolean;
    userRole: UserRoleDto;
    verified: boolean;
}

export class UserInspectionSummary {
    onGoingModel: number;
    totalModel: number;
}

export class UserMenuDto extends EBaseDto {
}

export class UserRoleDto extends EBaseDto {
    name: string;
    userRoleMenus: UserRoleMenuDto[];
    users: UserDto[];
}

export class UserRoleMenuDto extends EBaseDto {
}

export class WSConfiguration extends WSResponse<any, any> {
    data: ConfigurationDto;
}

export class WSConfigurations extends WSResponse<any, any> {
    data: ConfigurationDto[];
}

export class WSModelCategory extends WSResponse<any, any> {
    data: ModelCategoryDto;
}

export class WSNotification extends WSResponse<any, any> {
    data: NotificationDto;
}

export class WSNotifications extends WSResponse<any, any> {
    data: NotificationDto[];
}

export class WSWorkingDay extends WSResponse<any, any> {
    data: WorkingDayDto;
}

export class WSWorkingDays extends WSResponse<any, any> {
    data: WorkingDayDto[];
}

export class WSBank extends WSResponse<any, any> {
    data: BankDto;
}

export class WSBankAccount extends WSResponse<any, any> {
    data: BankAccountDto;
}

export class WSBankAccounts extends WSResponse<any, any> {
    data: BankAccountDto[];
}

export class WSBanks extends WSResponse<any, any> {
    data: BankDto[];
}

export class WSPersonalities extends WSResponse<any, any> {
    data: PersonalityDto[];
}

export class WSPersonality extends WSResponse<any, any> {
    data: PersonalityDto;
}

export class WSPersonalityCharacter extends WSResponse<any, any> {
    data: PersonalityCharacterDto;
}

export class WSPersonalityCharacters extends WSResponse<any, any> {
    data: PersonalityCharacterDto[];
}

export class WSReportCategories extends WSResponse<any, any> {
    data: ReportCategoryDto[];
}

export class WSReportCategory extends WSResponse<any, any> {
    data: ReportCategoryDto;
}

export class WSReportStatus extends WSResponse<any, any> {
    data: ReportStatusDto;
}

export class WSReportStatuses extends WSResponse<any, any> {
    data: ReportStatusDto[];
}

export class WSAdmin extends WSResponse<any, any> {
    data: AdminDto;
}

export class WSAdmins extends WSResponse<any, any> {
    data: AdminDto[];
}

export class WSPerson extends WSResponse<any, any> {
    data: PersonDto;
}

export class WSPersons extends WSResponse<any, any> {
    data: PersonDto[];
}

export class WSRegistration extends WSResponse<any, any> {
    data: RegistrationDto;
}

export class WSRegistrations extends WSResponse<any, any> {
    data: RegistrationDto[];
}

export class WSUser extends WSResponse<any, any> {
    data: UserDto;
}

export class WSUserDevice extends WSResponse<any, any> {
    data: UserDeviceDto;
}

export class WSUserDevices extends WSResponse<any, any> {
    data: UserDeviceDto[];
}

export class WSUsers extends WSResponse<any, any> {
    data: UserDto[];
}

export interface Serializable {
}

export interface Comparable<T> {
}
