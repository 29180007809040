import React from 'react';
import Page from '../components/Page';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import Label from 'reactstrap/es/Label';
import CardBody from 'reactstrap/es/CardBody';
import FormGroup from 'reactstrap/es/FormGroup';
import Checkbox from '../components/Checkbox';
import Global from '../utils/Global';
import queryString from 'query-string';
import CardFooter from 'reactstrap/es/CardFooter';
import Button from 'reactstrap/es/Button';
import BasePage from './BasePage';
import label from '../message/Label'

class UserRoleMenuDetailPage extends BasePage{

    constructor(props) {
        super(props);
        let state = this.state
        state.menus = []
        state.userRole = {}
        state.userRoleId = queryString.parse(this.props.query).userRoleId
        this.setState(state)
    }

    componentDidMount() {
        if(!this.state.userRoleId){
            this.props.history.goBack()
        }
        super.componentDidMount();
        this.fetchRoleDetail()
    }

    fetchRoleDetail=()=>{
        this.get(Global.API.USER_ROLE+"/"+this.state.userRoleId, null, null, response=>{
            if(response.code===200){
                let userRole = response.data
                this.setState({
                    userRole:userRole
                }, () => {
                    this.fetchMenu()
                })
            }
        }, true, true)
    }

    fetchMenu = () =>{
        this.get(Global.API.USER_MENUS_TREE, null, null, response=>{
            if(response.code===200){
                this.setState({
                    menus:response.data
                }, () => {
                    this.checkSelecetedMenus()
                })
            }
        }, true, true)
    }


    checkSelecetedMenus=()=>{
        let menus = this.state.menus
        menus.map((item, index)=>{
            item.checked = this.checkSelectedMenu(item.id)
            item.childMenus.map((childItem, childIndex)=>{
                childItem.checked = this.checkSelectedMenu(childItem.id)
            })
        })
        this.setState({
            menus:menus
        })
    }

    checkSelectedMenu=(menuId)=>{
        let check = false ;
        let userRole = this.state.userRole
        for (let i = 0; i < userRole.userRoleMenus.length; i++) {
            let menu = userRole.userRoleMenus[i].userMenu
            if(menu.id==menuId){
                check = true
                break
            }
        }
        return check
    }


    updateMenu=()=>{
        let menus = this.state.menus
        this.openConfirmDialog(label.Confirmation, "Do you want to update menu for this userRole ?", ()=>{
            this.post(Global.API.USER_ROLE_UPDATE_USER_MENU+"/"+this.state.userRole.id, menus, null, response=>{
                if(response.code===200){
                    this.successToast(response.message)
                    this.setState({
                        userRole:response.data
                    },() => {
                        this.checkSelecetedMenus()
                    })
                }
            }, true, true)
        })
    }


    render() {
        let menus = this.state.menus
        return (
            <Page
                title="Menu"
                breadcrumbs={[{ name: 'menu', active: true }]}
            >
                {super.render()}
                <Card>
                    <CardHeader>Menu</CardHeader>
                    <CardBody>
                        <Row>
                            {
                                menus.map((item, index)=>(
                                    <Col key={index} md={3}>
                                        <Card>
                                            <CardHeader>
                                                {++index} - {item.name}
                                            </CardHeader>
                                            <CardBody>
                                                <FormGroup>
                                                    <Row>
                                                        <Col md={9}>
                                                            <Label><b>{item.name}</b></Label>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Checkbox checked={item.checked} onChange={checked =>{
                                                                item.parentMenu&&(
                                                                    item.childMenus.map((childItem, childIndex)=>{
                                                                            childItem.checked=checked
                                                                        }
                                                                    )
                                                                )
                                                                item.checked=checked
                                                                this.setState({
                                                                    menus:menus
                                                                })
                                                            }}/>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        item.parentMenu&&(
                                                            item.childMenus.map((childItem, childIndex)=>(
                                                                    <Row key={childIndex}>
                                                                        <Col md={9}>
                                                                            <Label style={{marginLeft:'10%'}}>{childItem.name}</Label>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <Checkbox checked={childItem.checked} onChange={checked =>{
                                                                                childItem.checked=checked
                                                                                item.checked=checked?checked:item.checked
                                                                                this.setState({
                                                                                    menus:menus
                                                                                })
                                                                            }}/>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            )
                                                        )
                                                    }
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col md={6}>
                                <Button onClick={event=>{
                                    this.props.history.goBack()
                                }}>{label.Back}</Button>
                            </Col>
                            <Col md={6}>
                                <Button  className="float-right" color="primary" onClick={event=>{
                                    event.preventDefault()
                                    this.updateMenu()
                                }}>{label.Update}</Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Page>
        );
    }
}
export default Object.assign(UserRoleMenuDetailPage, {ENDPOINT : "/userMenu"})
