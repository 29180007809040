import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import {FormLane} from '../../model/formbuilder/FormLane';
import WarningLabel from '../Widget/WarningLabel';
import label from '../../message/Label'
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";

/*= (message, okCallback, cancelCallback) =>*/
export default class FormLaneModal extends BaseComponent{


    validator = {
        titleError: false,
        descriptionError:false
    }

    constructor(props){
        super(props);
        let formLane = this.props.formLane?this.props.formLane:new FormLane()
        formLane.id = formLane.id?formLane.id:new Date().getTime()
        let descriptionEditorState = EditorState.createEmpty()
        this.state = {
            backdrop: false,
            modal: this.props.showing,
            onAdd:this.props.onAdd,
            close:this.props.close,
            formLane : formLane,
            descriptionEditorState : descriptionEditorState,
            validator : this.validator
        }
        this.buttonClick = this.buttonClick.bind(this)
    }


    onDescriptionEditorStateChange= (descriptionEditorState) => {
        let formLane = this.state.formLane
        let validator = this.state.validator
        formLane.description = draftToHtml(convertToRaw(descriptionEditorState.getCurrentContent()))
        validator.description = undefined
        this.setState({
            formLane:formLane,
            descriptionEditorState:descriptionEditorState,
            validator:validator
        });
    }


    componentWillReceiveProps(props, nextContext) {
        if(this.props.showing!=props.showing){
            let formLane = props.formLane?props.formLane:new FormLane()
            formLane.id = formLane.id?formLane.id:new Date().getTime()
            let descriptionEditorState = EditorState.createEmpty()
            if(formLane.description){
                const descriptionContentBlock = htmlToDraft(formLane.description);
                if (descriptionContentBlock) {
                    let contentState = ContentState.createFromBlockArray(descriptionContentBlock.contentBlocks);
                    descriptionEditorState = EditorState.createWithContent(contentState);
                }else{
                    descriptionEditorState = EditorState.createEmpty()
                }
            }else{
                descriptionEditorState = EditorState.createEmpty()
            }

            this.setState({
                modal:props.showing,
                formLane : formLane,
                descriptionEditorState : descriptionEditorState,
                close:props.close,
                validator : this.validator
            })
        }
    }

    buttonClick (e, button){
        e.preventDefault()
        if(button===label.Ok){
            if(this.props.okCallback!=null){
                this.props.okCallback()
            }
        }else{
            if(this.props.cancelCallback!=null){
                this.props.cancelCallback()
            }
        }
    }

    close = () =>{
        this.setState({
            modal: false,
        }, () => {
            this.props.close()
        })
    }

    onOK(){
        let formLane = this.state.formLane
        if(!formLane.title){
            let validator = this.state.validator
            validator.titleError = true
            this.setState({
                validator : validator
            })
        }else{
            if(this.props.onAdd){
                this.props.onAdd(formLane)
            }
            // this.close()
        }

    }



    render(){
        let formLane = this.state.formLane
        let descriptionEditorState = this.state.descriptionEditorState

        let validator = this.state.validator
        return (
            /*xl-lg-sm*/
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={false}>
                {super.render()}
                <ModalHeader>
                    {label.CreatePage}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">{label.PageTitle}</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={formLane.title?formLane.title:""}
                                    onChange={(e) =>{
                                        validator.titleError = false
                                        formLane.title = e.target.value
                                        this.setState({
                                            formLane:formLane,
                                            validator:validator
                                        })
                                    }}
                                    onKeyDown={e=>{
                                        if(e.key==='Enter'){
                                            e.preventDefault()
                                            this.onOK()
                                        }
                                    }}
                                    placeholder={label.EnterPageTitle}
                                />
                                <WarningLabel show={validator.titleError} message={label.PleaseEnterPageTitle}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>{label.Description}</CardHeader>
                                <CardBody>
                                    <Editor
                                        editorState={descriptionEditorState}
                                        onEditorStateChange={this.onDescriptionEditorStateChange}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={e=>{
                        this.onOK()
                    }}>
                        {label.Ok}
                    </Button>{' '}
                    <Button color="secondary" onClick={e=>(this.close())}>
                        {label.Cancel}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

FormLaneModal.propTypes = {
    formLane:PropTypes.object,
    showing:PropTypes.bool.required,
    onAdd:PropTypes.func.required,
    close:PropTypes.func.required
}
