import Page from 'components/Page';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import CardBlock from 'reactstrap/es/CardBlock';
import BasePage from './BasePage';
import Global, {DD_MM_YYYY_HH_MM_SS,} from '../utils/Global';
import {allIsEmpty, cloneObject, isEmpty, parseDate, sortirMap} from '../utils/Utilities';
import {MdDelete, MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import label from '../message/Label'
import NumberInput from '../components/Widget/NumberInput';
import ItemOption from '../components/Widget/ItemOption';

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class ManufacturingSitePage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      models:[],
      model:{},
      manufacturingSites : [],
      manufacturingSite:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
    this.fetchPublishedModel()
  }

  confirmSave = () =>{
    var manufacturingSite = this.state.manufacturingSite
    var model = this.state.model
    if(allIsEmpty(manufacturingSite.name, manufacturingSite.code)){
      this.showDialog(label.Warning, label.PleaseCompleteTheFields)
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }


  saveUpload = () => {
    let manufacturingSite = this.state.manufacturingSite

    if(!manufacturingSite.id && !manufacturingSite.active){
      manufacturingSite.active = false ;
    }
    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Global.API.MANUFACTURING_SITE_SAVE, manufacturingSite, null, res=>{
        if(res.code===200){
          this.setState({
            manufacturingSite:{}
          }, () => {
            this.showDialog(label.Success, res.message)
            this.fetchAll()
            // this.fetch(this.state.manufacturingSite.id)
            // changeParam(this.props, 'id', this.state.product.id)
          })
        }
      },  true, true)
    })
  }


  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Global.API.MANUFACTURING_SITE_DELETE+"/"+this.state.manufacturingSite.id, null, null, res => {
        if(res.code===200){
          this.setState({
            manufacturingSite:{},
            model:{}
          }, () => {
            this.fetchAll()
          })
        }
      },true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Global.API.MANUFACTURING_SITES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          manufacturingSites : response.data
        })
      }
    }, true, true);
  }

  fetchPublishedModel = () => {
    this.get(Global.API.MODELS_PUBLISHED, {
      params:{
        ascending:true,
        sortir:"jt.systemType"
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          models : response.data,
        })
      }
    }, true, true);
  }


  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let aspect = 4/4
    let manufacturingSite = this.state.manufacturingSite?this.state.manufacturingSite:{}
    let models = this.state.models?this.state.models:[]
    let model = this.state.model?this.state.model:{}

    return (
        <Page
            title={label.ManufacturingSite}
            breadcrumbs={[{ name: label.ManufacturingSite, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message={label.UpdateConfirmation}
              okCallback={this.saveUpload}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message={label.DeleteConfirmationMessage}
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.ManufacturingSiteDetail}</CardHeader>
                <CardBody >
                  <Row>
                    <Col md={4}>

                      <Card>
                        <CardHeader>{label.Form}</CardHeader>
                        <CardBody>
                          <Row>
                            <CardBlock>
                              <FormGroup>
                                <Label for="name">{label.Name}</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={!isEmpty(manufacturingSite.name)?manufacturingSite.name:""}
                                    onChange={(e) =>{
                                      manufacturingSite.name = e.target.value
                                      this.setState({
                                        manufacturingSite:manufacturingSite
                                      })
                                    }}
                                    placeholder={label.EnterManufacturingSiteName}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="code">{label.Code}</Label>
                                <NumberInput
                                    name="code"
                                    className="form-control"
                                    value={(manufacturingSite)?manufacturingSite.code:""}
                                    onChangeEvent={(e, maskedvalue, floatvalue) => {
                                      e.preventDefault()
                                      manufacturingSite.code = floatvalue
                                      this.setState({
                                        manufacturingSite: manufacturingSite,
                                      })
                                    }}
                                    placeholder="0"
                                    maxLength={15}>
                                </NumberInput>
                              </FormGroup>
                              <FormGroup>
                                <ActiveOption
                                    default={manufacturingSite.active}
                                    callback={(active)=>{
                                      manufacturingSite.active = active
                                      this.setState({
                                        manufacturingSite:manufacturingSite
                                      })
                                    }}
                                />
                              </FormGroup>
                            </CardBlock>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonGroup className="float-left">
                                <Button color="danger" onClick={e => (
                                    this.setState({
                                      manufacturingSite:null
                                    })
                                )}>{label.Cancel}</Button>
                              </ButtonGroup>
                            </Col>
                            <Col>
                              <ButtonGroup className="float-right">
                                <Button color="primary" onClick={e=>(
                                    this.confirmSave()
                                )}> {
                                  (manufacturingSite && manufacturingSite.id)?label.Update:label.Add
                                } </Button>
                              </ButtonGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "label"))}>{label.Label}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.Code}</th>
                                <th>{label.Status}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Edit}</th>
                                <th>{label.Delete}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.manufacturingSites.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.name}</td>
                                      <td>{item.code}</td>
                                      <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.active}/></td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button color="primary" onClick={e=> {
                                          let manufacturingSite = cloneObject(item)
                                          let model = manufacturingSite.model
                                          this.setState({
                                            manufacturingSite: manufacturingSite,
                                            model:model
                                          })
                                        }}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={e=> {
                                          let manufacturingSite = cloneObject(item)
                                          this.setState({
                                            manufacturingSite: manufacturingSite
                                          }, () => {
                                            this.confirmDelete()
                                          })
                                        }}>
                                          <MdDelete/>
                                        </Button>
                                      </td>

                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Page>
    );
  }
}

export default Object.assign(ManufacturingSitePage, {ENDPOINT : "/manufacturingSites"})
