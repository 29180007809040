import { STATE_LOGIN } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import AuthPage from './pages/AuthPage';
import { AuthProvider } from './utils/AuthContext';
import kitchen from '../src/utils/AxiosInstance';
// pages
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import ProtectedRoute from 'components/Layout/ProtectedRoute';
import RolePage from './pages/RolePage';
import ConfigurationAdminPage from './pages/ConfigurationAdminPage';
import AdminPage from './pages/AdminPage';
import AdminDetailPage from './pages/AdminDetailPage';
import RoleMenuDetailPage from './pages/RoleMenuDetailPage';
import ProfilePage from './pages/ProfilePage';
import ProvincePage from './pages/ProvincePage';
import CityPage from './pages/CityPage';
import DistrictPage from './pages/DistrictPage';
import VillagePage from './pages/VillagePage';
import Global from './utils/Global';
import { storeData } from './utils/StorageUtil';
import AdminActivityPage from './pages/AdminActivityPage';
import UserActivityPage from './pages/UserActivityPage';
import MainDashboardPage from './pages/MainDashboardPage';
import './App.css';
import FormBuilderDetailPage from './pages/FormBuilderDetailPage';
import FormBuilderPage from './pages/FormBuilderPage';
import ModelPage from './pages/ModelPage';
import ModelDetailPage from './pages/ModelDetailPage';
import InspectionPage from './pages/InspectionPage';
import UserPage from './pages/UserPage';
import UserDetailPage from './pages/UserDetailPage';
import UserDevicePage from './pages/UserDevicePage';
import InspectionDetailPage from './pages/InspectionDetailPage';
import MainModelPage from './pages/MainModelPage';
import UserRolePage from "./pages/UserRolePage";
import UserRoleMenuDetailPage from "./pages/UserRoleMenuDetailPage";
import ModelCategoryPage from "./pages/ModelCategoryPage";
import InspectionEventPage from "./pages/InspectionEventPage";
import AdminDevicePage from "./pages/AdminDevicePage";
import InspectionEventDetailPage from "./pages/InspectionEventDetailPage";
import 'antd/dist/antd.css';
import ManufacturingSitePage from "./pages/ManufacturingSitePage";

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {


  componentDidMount() {
    fetchAppSetting()
  }


  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <AuthProvider>
          <GAListener>
            <Switch>
              <ProtectedRoute
                exact
                rootMenu={ModelPage.ENDPOINT}
                path={ModelPage.ENDPOINT}
                layout={MainLayout}
                component={ModelPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ModelPage.ENDPOINT}
                path={ModelDetailPage.ENDPOINT}
                layout={MainLayout}
                component={ModelDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={InspectionPage.ENDPOINT}
                path={InspectionPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionPage}
              />
              <ProtectedRoute
                exact
                rootMenu={InspectionPage.ENDPOINT}
                path={InspectionDetailPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={InspectionEventPage.ENDPOINT}
                path={InspectionEventPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionEventPage}
              />
              <ProtectedRoute
                exact
                rootMenu={InspectionEventPage.ENDPOINT}
                path={InspectionEventDetailPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionEventDetailPage}
              />


              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserPage.ENDPOINT}
                layout={MainLayout}
                component={UserPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserDetailPage.ENDPOINT}
                layout={MainLayout}
                component={UserDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserDevicePage.ENDPOINT}
                path={UserDevicePage.ENDPOINT}
                layout={MainLayout}
                component={UserDevicePage}
              />

              <ProtectedRoute
                exact
                path={ModelCategoryPage.ENDPOINT}
                rootMenu={ModelCategoryPage.ENDPOINT}
                layout={MainLayout}
                component={ModelCategoryPage}
              />
              <ProtectedRoute
                exact
                rootMenu={MainModelPage.ENDPOINT}
                path={MainModelPage.ENDPOINT}
                layout={MainLayout}
                component={MainModelPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ProvincePage.ENDPOINT}
                path={ProvincePage.ENDPOINT}
                layout={MainLayout}
                component={ProvincePage}
              />
              <ProtectedRoute
                exact
                rootMenu={CityPage.ENDPOINT}
                path={CityPage.ENDPOINT}
                layout={MainLayout}
                component={CityPage}
              />
              <ProtectedRoute
                exact
                rootMenu={DistrictPage.ENDPOINT}
                path={DistrictPage.ENDPOINT}
                layout={MainLayout}
                component={DistrictPage}
              />
              <ProtectedRoute
                exact
                rootMenu={VillagePage.ENDPOINT}
                path={VillagePage.ENDPOINT}
                layout={MainLayout}
                component={VillagePage}
              />

              <ProtectedRoute
                exact
                rootMenu={RolePage.ENDPOINT}
                path={RolePage.ENDPOINT}
                layout={MainLayout}
                component={RolePage}
              />
              <ProtectedRoute
                exact
                rootMenu={RolePage.ENDPOINT}
                path={RoleMenuDetailPage.ENDPOINT}
                layout={MainLayout}
                component={RoleMenuDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserRolePage.ENDPOINT}
                path={UserRolePage.ENDPOINT}
                layout={MainLayout}
                component={UserRolePage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserRolePage.ENDPOINT}
                path={UserRoleMenuDetailPage.ENDPOINT}
                layout={MainLayout}
                component={UserRoleMenuDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ConfigurationAdminPage.ENDPOINT}
                path={ConfigurationAdminPage.ENDPOINT}
                layout={MainLayout}
                component={ConfigurationAdminPage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminPage.ENDPOINT}
                path={AdminPage.ENDPOINT}
                layout={MainLayout}
                component={AdminPage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminPage.ENDPOINT}
                path={AdminDetailPage.ENDPOINT}
                layout={MainLayout}
                component={AdminDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminDevicePage.ENDPOINT}
                path={AdminDevicePage.ENDPOINT}
                layout={MainLayout}
                component={AdminDevicePage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminActivityPage.ENDPOINT}
                path={AdminActivityPage.ENDPOINT}
                layout={MainLayout}
                component={AdminActivityPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserActivityPage.ENDPOINT}
                path={UserActivityPage.ENDPOINT}
                layout={MainLayout}
                component={UserActivityPage}
              />
              <LayoutRoute
                exact
                path={AuthPage.ENDPOINT}
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <ProtectedRoute
                exact
                rootMenu={MainDashboardPage.ENDPOINT}
                path={MainDashboardPage.ENDPOINT}
                layout={MainLayout}
                component={MainDashboardPage}
              />
              <ProtectedRoute
                exact
                rootMenu={"*"}
                path={ProfilePage.ENDPOINT}
                layout={MainLayout}
                component={ProfilePage}
              />

              <ProtectedRoute
                exact
                rootMenu={FormBuilderPage.ENDPOINT}
                path={FormBuilderPage.ENDPOINT}
                layout={MainLayout}
                component={FormBuilderPage}
              />
              <ProtectedRoute
                exact
                rootMenu={FormBuilderPage.ENDPOINT}
                path={FormBuilderDetailPage.ENDPOINT}
                layout={MainLayout}
                component={FormBuilderDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ManufacturingSitePage.ENDPOINT}
                  path={ManufacturingSitePage.ENDPOINT}
                  layout={MainLayout}
                  component={ManufacturingSitePage}
              />

              <Redirect to={MainDashboardPage.ENDPOINT} />
            </Switch>
          </GAListener>
        </AuthProvider>
      </BrowserRouter>
    );
  }
}
function fetchAppSetting() {
  get(Global.API.CONFIGURATION_CURRENT_ACTIVE, null, null, response => {
    if (response.data.code === 200) {
      storeData(Global.CONFIGURATION, response.data.data)
    }
  })
}

function get(url, params, config, callback) {
  kitchen.get(url, params, config).then(response => {
    callback(response)
  }).catch(e => {

  });
}




const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
