import * as React from 'react';
import kitchen from '../utils/AxiosInstance';
import Dialog from './modal/Dialog';
import ProgressDialog from './modal/ProgressDialog';
import Global, {SC_UNAUTHORIZED} from '../utils/Global';
import {Lightbox} from 'react-modal-image';
import ImageLightbox from 'react-image-lightbox-with-rotate';
import 'react-image-lightbox-with-rotate/style.css'; // This only needs to be imported once in your app
import ProgressBar from '../components/popup/ProgressBar';
import ConfirmDialog from './modal/ConfirmDialog';
import {getData, removeData} from '../utils/StorageUtil';
import ToastServive from 'react-material-toast';
import {AuthConsumer} from '../utils/AuthContext';
import userAvatar from '../assets/img/users/user_avatar.png';
import axios from 'axios';
import TrackingDialog from './modal/TrackingDialog';
import flipImage from './../assets/img/widget/flip.png';
import SuccessDialog from "./modal/SuccessDialog";
import FailedDialog from "./modal/FailedDialog";
import Image from "./Widget/Image";
import {canUseWebP, isObject} from "../utils/Utilities";

// var clone = require('safe-clone-deep');

export const toast = ToastServive.new({
    place:'topRight', //topLeft topRight bottomLeft bottomRight
    duration:4,
    maxCount:8,
    closable:true
});


const FileDownload = require('js-file-download');



export default class BaseComponent extends React.Component{

    state  ={}

    constructor(props) {
        super(props);
        let state  = this.state
        state.failed = false
        state.dialog = false
        state.label = ""
        state.message = ""
        state.loading = false
        state.provinces = []
        state.cities = []
        state.districts = []
        state.villages = []
        state.genders = []
        state.openPreview = false
        state.imageLink = ""
        state.imageLinks = []
        state.imageLinkIndex = 0
        state.lightBoxOpen = false
        state.progressShow = false
        state.login = null
        state.logout = null
        state.refresh = null
        state.axiosRun = false
        state.trackingModal = false
        state.waybill = {}
        state.courier=""
        state.courierService=""
        state.resiCode = ""
        state.modeFlip = false ;
        state.modalSuccess = false ;
        state.modalFailed = false ;
        this.setState(state)
        this.showDialog = this.showDialog.bind(this)
    }

    componentDidMount() {
        // axiosRun = false
        this.setState({axiosRun : false})
    }

    componentWillUnmount() {

    }

    successToast(message){
        toast.success(message)
    }

    errorToast(message){
        toast.error(message)
    }

    showDialog(label, message){
        this.setState({
            label:label,
            message:message,
            dialog:true,
        },() => {
            this.forceUpdate()
        })

    }

    fetchProvinces = (callback) => {
        if(this.state.provinces!=null && this.state.provinces.length>0){
            callback(this.state.provinces)
        }
        this.get(Global.API.PROVINCES, null, null, response=>{
            if(response.code===200){
                this.setState({
                    provinces:response.data
                }, () => {
                    callback(this.state.provinces)
                })
            }
        }, false, false)
    }

    fetchCities = (provinceId, callback) => {
        this.get(Global.API.CITIES, {
            params:{
                provinceId:provinceId
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    cities:response.data
                }, () => {
                    callback(this.state.cities)
                })
            }
        }, false, false)
    }

    fetchDistricts = (cityId, callback) => {
        this.get(Global.API.DISTRICTS, {
            params:{
                cityId:cityId
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    districts:response.data
                }, () => {
                    callback(this.state.districts)
                })
            }
        }, false, false)
    }

    fetchVillages = (districtId, callback) => {
        this.get(Global.API.VILLAGES, {
            params:{
                districtId:districtId
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    villages:response.data
                }, () => {
                    callback(this.state.villages)
                })
            }
        }, false, false)
    }

    fetchGenders = (callback) => {
        this.get(Global.API.GENDERS, null, null, response=>{
            if(response.code===200){
                this.setState({
                    genders:response.data
                }, () => {
                    callback(this.state.genders)
                })
            }
        }, false, false)
    }

    openLightBox(images){
        const imageLinks = []
        images.map((item, index)=>{
            let imageLinks = "";
            if(item.imageLink && item.imageLink.includes("base64")){
                imageLinks.push(item.imageLink)
            }else{
                if(canUseWebP()){
                    imageLinks.push(item.imageLink+"?webp=true")
                }else{
                    imageLinks.push(item.imageLink+"?webp=false")
                }
            }
        })
        this.setState({
            lightBoxOpen:true,
            imageLinks:imageLinks,
            imageLinkIndex:0
        })
    }

    openLightBoxSingleImage(image){
        let imageLinks = "";
        if(image && image.includes("base64")){
            imageLinks = [image]
        }else{
            if(canUseWebP()){
                imageLinks = [image+"?webp=true"]
            }else{
                imageLinks = [image+"?webp=false"]
            }
        }
        this.setState({
            lightBoxOpen:true,
            imageLinks:imageLinks,
            imageLinkIndex:0
        })
    }

    closeLightBox(){
        this.setState({
            lightBoxOpen:false,
            imageLinks:[],
            imageLinkIndex:0
        })
    }

    showProgress(){
        this.setState({
            progressShow:true
        })
    }
    closeProgress(){
        this.setState({
            progressShow:false
        })
    }

    showTrackingModal(trackingModal, waybill, courier, courierService, resiCode){
        this.setState({
            trackingModal:trackingModal,
            waybill:waybill,
            courier:courier,
            courierService:courierService,
            resiCode:resiCode
        })
    }
    closeTrackingModal=()=>{
        this.setState({
            trackingModal:false,
            trackingData:{},
            courier:"",
            courierService:"",
            resiCode:""
        })
    }


    openConfirmDialog=(confirmationLabel, confirmationMessage, okConfirmCallback)=>{
        this.setState({
            confirmationLabel:confirmationLabel,
            confirmationMessage:confirmationMessage,
            modalConfirm:true,
            okConfirmCallback:okConfirmCallback
        })
    }
    closeConfirmDialog=()=>{
        this.setState({
            confirmationLabel:"",
            confirmationMessage:"",
            modalConfirm:false
        })
    }

    openSuccessDialog=(successLabel, successMessage, okConfirmCallback)=>{
        this.setState({
            successLabel:successLabel,
            successMessage:successMessage,
            modalSuccess:true,
            okConfirmCallback:okConfirmCallback
        })
    }
    closeSuccessDialog=()=>{
        this.setState({
            successLabel:"",
            successMessage:"",
            modalSuccess:false
        })
    }

    openFailedDialog=(failedLabel, failedMessage, okConfirmCallback)=>{
        this.setState({
            failedLabel:failedLabel,
            failedMessage:failedMessage,
            modalFailed:true,
            okConfirmCallback:okConfirmCallback
        })
    }
    closeFailedDialog=()=>{
        this.setState({
            failedLabel:"",
            failedMessage:"",
            modalFailed:false
        })
    }

    addDefaultUserAvatar(ev){
        ev.target.src = userAvatar
    }



    render() {
        let imageLinks = this.state.imageLinks
        let imageLinkIndex = this.state.imageLinkIndex
        let modeFlip = this.state.modeFlip;
        return (
            <AuthConsumer>
            {({admin, login, logout, refresh})=>(
                <main>
                    {
                        (this.state.login===null||this.state.logout===null||this.state.refresh===null)&&this.setState({
                            login:login,
                            logout:logout,
                            refresh:refresh,

                        })
                    }
                    {
                        this.state.openPreview?
                            <Lightbox
                                medium={this.state.imageLink}
                                large={this.state.imageLink}
                                onClose={e=> {
                                    this.setState({
                                        openPreview: !this.state.openPreview
                                    })
                                }}
                            />
                            :
                            null
                    }
                    <Dialog
                        showing={this.state.failed}
                        title={"Failed"}
                        message={this.state.message!=undefined?this.state.message:""}
                        okCallback={ e => this.setState({
                            failed:false
                        })}/>
                    <Dialog
                        showing={this.state.dialog}
                        title={this.state.label?this.state.label:"Alert"}
                        message={this.state.message!=undefined?this.state.message:""}
                        okCallback={ e => this.setState({
                            dialog:false
                        })}/>
                    <ConfirmDialog
                        showing={this.state.modalConfirm}
                        title={this.state.confirmationLabel}
                        message={this.state.confirmationMessage}
                        okCallback={()=>{
                            this.closeConfirmDialog()
                            if(this.state.okConfirmCallback){
                                this.state.okConfirmCallback()
                            }
                        }}
                        cancelCallback={()=>{
                            this.closeConfirmDialog()
                        }}/>
                    <SuccessDialog
                        showing={this.state.modalSuccess}
                        title={this.state.successLabel}
                        message={this.state.successMessage}
                        okCallback={()=>{
                            this.closeSuccessDialog()
                            if(this.state.okConfirmCallback){
                                this.state.okConfirmCallback()
                            }
                        }}
                        cancelCallback={()=>{
                            this.closeSuccessDialog()
                        }}/>
                    <FailedDialog
                        showing={this.state.modalFailed}
                        title={this.state.failedLabel}
                        message={this.state.failedMessage}
                        okCallback={()=>{
                            this.closeFailedDialog()
                            if(this.state.okConfirmCallback){
                                this.state.okConfirmCallback()
                            }
                        }}
                        cancelCallback={()=>{
                            this.closeFailedDialog()
                        }}/>
                    <TrackingDialog
                        showing={this.state.trackingModal?this.state.trackingModal:false}
                        waybill={this.state.waybill}
                        courier={this.state.courier}
                        courierservice={this.state.courierService}
                        resicode={this.state.resiCode}
                        okCallback={()=>{
                            this.closeTrackingModal()
                        }}
                    />
                    <ProgressDialog
                        showing={this.state.loading}/>
                    {
                        this.state.lightBoxOpen && (
                            <ImageLightbox
                                mainSrc={imageLinks[imageLinkIndex]}
                                nextSrc={imageLinks[(imageLinkIndex + 1) % imageLinks.length]}
                                prevSrc={imageLinks[(imageLinkIndex + imageLinks.length - 1) % imageLinks.length]}
                                onCloseRequest={() => this.closeLightBox()}
                                style={modeFlip?"image-flip":"image-normal"}
                                toolbarButtons={[
                                    <div style={{width:'40px', height:'50px', textAlign:'center'}} onClick={event => {
                                        event.preventDefault()
                                        let myElement = document.querySelector(".ril-inner");
                                        this.setState({
                                            modeFlip:!modeFlip
                                        }, () => {
                                            if(this.state.modeFlip){
                                                myElement.setAttribute('style', '-webkit-transform:scaleX(-1); transform:scaleX(-1)');
                                            }else{
                                                myElement.setAttribute('style', '');
                                            }
                                        })
                                    }}>
                                        <Image style={{width:'24px', height:'24'}} src={flipImage}></Image>
                                    </div>]}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        imageLinkIndex: (imageLinkIndex + imageLinks.length - 1) % imageLinks.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        imageLinkIndex: (imageLinkIndex + 1) % imageLinks.length,
                                    })
                                }
                            />
                        )
                    }
                    <ProgressBar show={this.state.progressShow} percentage={0}/>
                </main>
            )}
            </AuthConsumer>

        );
    }

    post(url, params, config, callback, progressing, alerting){
        let axiosRun = this.state.axiosRun
        // if(isObject(params)){
        //     params = clone(params)
        // }

        if (axiosRun) {
            let component = this
            setTimeout(function(){
                component.post(url, params, config, callback, progressing, alerting)
            },50);
        } else {
            this.setState({axiosRun : true})
            if(progressing){
                this.showProgress()
            }
            kitchen.post(url, params, config).then((res)=>{
                this.setState({axiosRun : false})
                let component = this
                setTimeout(function () {
                    if(progressing && !component.state.axiosRun){
                        component.closeProgress()
                    }
                    component.setState({
                        loading:false
                    }, () => {
                        if(res.data.code===200){
                            callback(res.data)
                        }else{
                            if(alerting){
                                toast.error(res.data.message);
                                component.setState({
                                    failed:true,
                                    message:res.data.message
                                }, () => {
                                    callback(res.data)
                                })
                            }else{
                                callback(res.data)
                            }
                        }
                    })
                }, 100)
            }).catch(e=>{
                this.setState({axiosRun : false})
                let component = this
                if(progressing){
                    this.closeProgress()
                }
                if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                    removeData(Global.ADMIN)
                    removeData(Global.AUTHORIZATION)
                    window.location.href = "/login?message="+e.response.data.message
                }else {
                    setTimeout(function () {
                        try {
                            component.closeProgress()
                            component.setState({
                                loading: false,
                                failed: true,
                                message: e.message
                            }, () => {
                            })
                        } catch (e) {

                        }
                    }, 100)
                }
            });
        }
    }

    get(url, params, config, callback, progressing, alerting){
        let axiosRun = this.state.axiosRun
        if (axiosRun) {
            let component = this
            setTimeout(function(){
                component.get(url, params, config, callback, progressing, alerting)
            },50);
        } else {
            this.setState({axiosRun : true})
            if(progressing){
                this.showProgress()
            }
            kitchen.get(url, params, null).then(res=>{
                this.setState({axiosRun : false})
                let component = this
                setTimeout(function () {
                    if(progressing && !component.state.axiosRun){
                        component.closeProgress()
                    }
                    component.setState({
                        loading:false
                    }, () => {
                        if(res.data.code===200){
                            callback(res.data)
                        }else{
                            if(alerting){
                                toast.error(res.data.message);
                                component.setState({
                                    failed:true,
                                    message:res.data.message
                                }, () => {
                                    callback(res.data)
                                })
                            }else{
                                callback(res.data)
                            }
                        }
                    })
                }, 100)
            }).catch(e=>{
                this.setState({axiosRun : false})
                let component = this
                if(progressing){
                    this.closeProgress()
                }
                if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                    removeData(Global.ADMIN)
                    removeData(Global.AUTHORIZATION)
                    window.location.href = "/login?message="+e.response.data.message
                }else {
                    setTimeout(function () {
                        try {
                            component.closeProgress()
                            component.setState({
                                loading: false,
                                failed: true,
                                message: e.message
                            }, () => {
                            })
                        } catch (e) {

                        }
                    }, 100)
                }
            });
        }
    }

    async asyncPost(url, params, config, progressing, alerting){
        if(progressing){
            await this.showProgress()
        }
        // if(isObject(params)){
        //     params = clone(params)
        // }

        try{
            let res = await kitchen.post(url, params, config);
            if(progressing){
                await this.closeProgress()
            }

            if(res.data.code===200){
                return res.data
            }else{
                if(alerting){
                    toast.error(res.data.message);
                    this.setState({
                        failed:true,
                        message:res.data.message
                    })
                }
                return res.data
            }
        }catch (e) {
            if(progressing){
                this.closeProgress()
            }
            if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                removeData(Global.ADMIN)
                removeData(Global.AUTHORIZATION)
                window.location.href = "/login?message="+e.response.data.message
            }
        }

    }

    async asyncGet(url, params, config, progressing, alerting){

        if(progressing){
            await this.showProgress()
        }
        try{
            let res = await kitchen.get(url, params);
            if(progressing){
                await this.closeProgress()
            }

            if(res.data.code===200){
                return res.data
            }else{
                if(alerting){
                    toast.error(res.data.message);
                    this.setState({
                        failed:true,
                        message:res.data.message
                    })
                }
                return res.data
            }
        }catch (e) {
            if(progressing){
                this.closeProgress()
            }
            if(e.response && (e.response.status===SC_UNAUTHORIZED)){
                removeData(Global.ADMIN)
                removeData(Global.AUTHORIZATION)
                window.location.href = "/login?message="+e.response.data.message
            }

        }

    }


    downloadBlobFile = (urlOrObject, callback)=>{
        axios({
            url: urlOrObject,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            var fileBlob = response.data ;
            if(callback){
                callback(fileBlob)
            }
        }).catch(reason => {
            this.errorToast(reason)
            callback(null)
        })
    }


    // getUsers = async () => {
    //     let res = await axios.get("https://reqres.in/api/users?page=1");
    //     let { data } = res.data;
    //     this.setState({ users: data });
    // };

    async asyncDownloadGet(url, params, config, progressing, alerting){

        if(progressing){
            await this.showProgress()
        }

        let options = {
            responseType: 'blob',
            headers: {
                'Authorization': getData(Global.AUTHORIZATION),
            },
            ...params
        }

        let res = await kitchen.get(url, options);
        if(progressing){
            await this.closeProgress()
        }
        let filename = res.headers.filename

        const fileUrl = window.URL.createObjectURL(new Blob([res.data], {type:'application/vnd.ms-excel'}));
        const link = document.createElement('a');

        link.href = fileUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        return true
    }


    defaultImage(elm, image){
        elm.target.src = image
    }


}