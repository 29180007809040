import React from 'react';
import userAvatar from '../../../assets/img/users/user_avatar.png';
import PropTypes from 'prop-types';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../../../utils/Global';
import {Button, Table} from 'reactstrap';
import {MdPersonPin} from 'react-icons/md';
import withBadge from 'hocs/withBadge';
import {isEmpty, parseDate} from '../../../utils/Utilities';
import Avatar from 'components/Avatar';
import {FaEye} from 'react-icons/all';
import CardPagination from '../../CardPagination';
import BaseComponent from '../../BaseComponent';
import label from '../../../message/Label'

const AvatarWithBadge = withBadge({
    position: 'bottom-right',
    color: 'success',
})(Avatar);


export default class DashboardInspectionTable extends BaseComponent{


    constructor(props) {
        super(props);
        let state = this.state
        state.page = 1 ;
        state.inspectionStatusId = this.props.inspectionStatusId
        state.inspections = []
        state.show = this.props.show
        state.fetching = this.props.fetching
        state.totalPage  = 0
        state.totalElement = 0
        state.pageElement = 0
        this.setState(state)
    }



    async componentDidMount() {
        super.componentDidMount();
        await this.fetchTransaction(true)
    }

    componentWillReceiveProps(props, nextContext) {
        let inspectionStatus = this.state.inspectionStatus
        let show = this.state.show
        let state = this.state
        if(!show && props.show){
            state.page = 1
            state.inspectionStatusId = props.inspectionStatusId
            state.show = props.show
            state.inspections = []
            state.fetching = props.fetching
            this.setState(state, async ()=>{
                await this.fetchTransaction(true)
            })
        }else{
            state.show = props.show
            this.setState(state)
        }
    }

    totalElement=()=>{
        return this.state.totalElement
    }

    fetchTransaction = async (progressing) =>{
        let fetching = this.state.fetching
        if(fetching && progressing){
            fetching(true)
        }
        let response = await this.asyncGet(Global.API.INSPECTIONS, {params:{
                inspectionStatusId:this.state.inspectionStatusId,
                page:this.state.page-1
            }}, null, false, false)

        if(response && response.code===200){
            this.setState({
                inspections : response.data,
                totalPage: response.totalPage,
                totalElement : response.totalElement,
                pageElement: response.pageElement,
            }, () => {
                fetching(false)
            })
        }else{
            fetching(false)
        }
    }

    render() {
        let inspections = this.state.inspections
        return (
            <span>
                {
                    super.render()
                }
            <Table responsive hover {...this.props}>
                <thead>
                <tr className="text-capitalize align-middle text-center">
                    <th style={{width:'15%'}}><MdPersonPin size={25}/></th>
                    <th style={{width:'25%'}}>{label.Performer}</th>
                    <th style={{width:'20%'}}>{label.Model}</th>
                    <th style={{width:'20%'}}>{label.Date}</th>
                    <th style={{width:'20%'}}>{label.Reference}</th>
                </tr>
                </thead>
                <tbody>
                {
                    inspections.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle text-center">
                                {
                                    item.user?(
                                        <AvatarWithBadge
                                            src={isEmpty(item.performerImageUrl)?userAvatar:item.performerImageUrl}
                                            onError={this.addDefaultUserAvatar}/>
                                    )
                                        :
                                    (
                                        <AvatarWithBadge src={userAvatar} onError={
                                            this.addDefaultUserAvatar
                                        }/>

                                    )
                                }
                            </td>
                            <td className="align-middle text-center">
                                {
                                    item.user?(
                                            <a href={'/userDetail?id='+item.user.id}>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</a>
                                        ):
                                        (<span>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</span>)
                                }
                            </td>
                            <td className="align-middle text-center">{item.model.systemType}</td>
                            <td className="align-middle text-center">{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                            <td className="align-middle text-center">
                                <a href={'/inspectionDetail?id='+item.id}>
                                    <Button size="sm" color="primary">
                                        {item.reference}&nbsp;&nbsp;<FaEye/>
                                    </Button>
                                </a>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
                <CardPagination
                    className="float-right"
                    {...this.props}
                    currentPage={this.state.page}
                    pageCount={this.state.totalPage} onSelect={(page)=>{
                    this.setState({
                        page:page
                    }, async () => {
                        await this.fetchTransaction(true)
                    })
                }}/>
            </span>
        );
    }

}

DashboardInspectionTable.propTypes = {
    inspectionStatusId : PropTypes.number.isRequired,
    show:PropTypes.bool,
    fetching:PropTypes.func.isRequired,
}
