import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import Page from '../components/Page';
import kitchen from '../utils/AxiosInstance';
import Global, { ADMIN_STATUSES, DD_MM_YYYY_HH_MM_SS } from '../utils/Global';
import {  isEmpty, isValidEmail } from '../utils/Utilities';
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import { IoMdKey } from 'react-icons/io';
import label from '../message/Label'
import WarningLabel from "../components/Widget/WarningLabel";
import { getData, storeData } from '../utils/StorageUtil';
import TestSendMailDialog from '../components/modal/TestSendMailDialog';

const InputFormatConfig = {
    HOST: 'host',
    MAIL_DEBUG: 'mailDebug',
    MAIL_FROM: 'mailFrom',
    PASSWORD: 'password',
    PORT: 'port',
    REPLY_TO: 'replyTo',
    SMTP_AUTH: 'smtpAuth',
    SMTP_STARTTTLS_ENABLE: 'smtpStarttlsEnable',
    SMTP_STARTTTLS_REQUIRED: 'smtpStarttlsRequired',
    USERNAME: 'username',
    SEQUENCE_START_SERIAL_NUMBER: 'sequenceStartSerialNumber',
}

class ConfigurationAdminPage extends BasePage {

    constructor(props) {
        super(props);
        this.state = {
            configurationAdmin: {},
            hostWarning: "",
            portWarning: "",
            mailFromWarning: "",
            passwordWarning: "",
            usernameWarning: "",
            replyToWarning: "",
            testSendMailModal : false

        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAll()
    }

    fetchAll = () => {

        // let {configurationAdmin}  = this.state;
        // configurationAdmin = null;

        // if (!configurationAdmin) {
            this.get(Global.API.CONFIGURATION_ADMIN, null, null, response => {
                if (response.code === 200) {
                    let configurationAdmin = response.data
                    storeData(Global.CONFIGURATION_ADMIN, configurationAdmin)
                    this.setState({
                        configurationAdmin: configurationAdmin
                    })
                }
            })
        // }

    }

    handleInputData = (format, value) => {
        let currentState = this.state

   
        switch (format) {
            case InputFormatConfig.HOST: {
                currentState.configurationAdmin.host = value
                break;
            }
            case InputFormatConfig.MAIL_FROM: {
                currentState.configurationAdmin.mailFrom = value
                break;
            }
            case InputFormatConfig.PASSWORD: {
                currentState.configurationAdmin.password = value
                break;
            }
            case InputFormatConfig.PORT: {
                currentState.configurationAdmin.port = value
                break;
            }
            case InputFormatConfig.REPLY_TO: {
                currentState.configurationAdmin.replyTo = value
                break;
            }
            case InputFormatConfig.USERNAME: {
                currentState.configurationAdmin.username = value
                break;
            }

            case InputFormatConfig.SMTP_AUTH: {
                currentState.configurationAdmin.smtpAuth = value
                break;
            }

            case InputFormatConfig.SMTP_STARTTTLS_ENABLE: {
                currentState.configurationAdmin.smtpStarttlsEnable = value
                break;
            }

            case InputFormatConfig.SMTP_STARTTTLS_REQUIRED: {
                currentState.configurationAdmin.smtpStarttlsRequired = value
                break;
            }

            case InputFormatConfig.MAIL_DEBUG: {
                currentState.configurationAdmin.mailDebug = value
                break;
            }

            case InputFormatConfig.SEQUENCE_START_SERIAL_NUMBER: {
                currentState.configurationAdmin.sequenceStartSerialNumber = value
                break;
            }


        }
        this.setState(currentState);
    }

    async confirmSave() {
        let { configurationAdmin } = this.state
        let valid = true;

        if (!configurationAdmin) {
            valid = false;
        }

        if (configurationAdmin && isEmpty(configurationAdmin.host)) {
            this.setState({
                hostWarning: label.PleaseEnterHostMail
            })
            valid = false;
        }

        if (configurationAdmin && isEmpty(configurationAdmin.port)) {
            this.setState({
                portWarning: label.PleaseEnterPortMail
            })
            valid = false;
        }

        if (configurationAdmin && isEmpty(configurationAdmin.port)) {
            this.setState({
                portWarning: label.PleaseEnterPortMail
            })
            valid = false;
        }

        if (configurationAdmin && isEmpty(configurationAdmin.username)) {
            this.setState({
                usernameWarning: label.PleaseEnterUsernameMail
            })
            valid = false;
        }

        if (configurationAdmin && !isValidEmail(configurationAdmin.username)) {
            this.setState({
                usernameWarning: label.InvalidEmailAddress
            })
            valid = false;
        }

        if (configurationAdmin && isEmpty(configurationAdmin.password)) {
            this.setState({
                passwordWarning: label.PleaseEnterPasswordMail
            })
            valid = false;
        }

        if (configurationAdmin && isEmpty(configurationAdmin.replyTo)) {
            this.setState({
                replyToWarning: label.PleaseEnterReplyToMail
            })
            valid = false;
        }

        if (configurationAdmin && !isValidEmail(configurationAdmin.replyTo)) {
            this.setState({
                replyToWarning: label.InvalidEmailAddress
            })
            valid = false;
        }

        
        if (configurationAdmin && isEmpty(configurationAdmin.mailFrom)) {
            this.setState({
                mailFromWarning: label.PleaseEnterFromMail
            })
            valid = false;
        }

        if (configurationAdmin && !isValidEmail(configurationAdmin.mailFrom)) {
            this.setState({
                mailFromWarning: label.InvalidEmailAddress
            })
            valid = false;
        }

        if (configurationAdmin && isEmpty(configurationAdmin.sequenceStartSerialNumber)) {
            this.setState({
                sequenceStartSerialNumberWarning: label.EnterSequenceStartSerialNumber
            })
            valid = false;
        }

        if (valid) {
            this.openConfirmDialog(label.Confirmation,  label.DoYouWantToUpdateInformationOfConfigurationAdmin, () => {
                this.saveUpload()
            })
        }
    }

    saveUpload = () => {
        
        if(this.state.configurationAdmin){
         let body = this.state.configurationAdmin  
         this.setState({
            modalAddConfirm: false
        }, () => {
            this.post(Global.API.CONFIGURATION_ADMIN_SAVE, body, null, response => {
                if (response.code === 200) {
                    let configurationAdmin = response.data
                    storeData(Global.CONFIGURATION_ADMIN, configurationAdmin)
                    this.setState({
                        configurationAdmin: configurationAdmin
                    }, () => {
                        this.openSuccessDialog(label.Success, response.message, () => {
                            // this.props.history.goBack();
                            this.fetchAll()
                        })
                        // changeParam(this.props, 'id', this.state.admin.id)
                        // this.fetchAdminDetail(this.state.admin.id)
                    })
                }
            }, true, true)
        })
        }
        
    }



    closeDialog = () => {
        this.setState({
            modalAddConfirm: false,
        })
    }



    render() {
        let { configurationAdmin } = this.state

        return (
            <Page
                title={label.ConfigurationAdmin}
                breadcrumbs={[{ name: label.ConfigurationAdmin, active: true }]}>
                {super.render()}
                <TestSendMailDialog
                            modal={this.state.testSendMailModal}
                            okCallback={()=>{
                                this.setState({
                                    testSendMailModal:false
                                })
                            }}
                            cancelCallback={()=>{
                                this.setState({
                                    testSendMailModal:false
                                })
                            }}
                        />

                <ConfirmDialog
                    showing={this.state.modalAddConfirm}
                    title={label.UpdateConfirmation}
                    message={label.UpdateConfirmationMessage}
                    okCallback={this.saveUpload}
                    cancelCallback={this.closeDialog} />
                <Row key={1}>
                    <Col>
                        <Card className="mb-6">
                            <CardHeader>{label.ConfigurationAdmin}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="host">Host</Label>
                                                            <Input
                                                                type="text"
                                                                name="host"
                                                                value={(configurationAdmin != null && configurationAdmin.host) ? configurationAdmin.host : ""}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin != null) {
                                                                        this.handleInputData(InputFormatConfig.HOST, e.target.value)
                                                                    }
                                                                }}
                                                                placeholder={label.EnterHostMail}
                                                            />
                                                            <WarningLabel message={this.state.hostWarning} show={!isEmpty(this.state.hostWarning)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="port">Port</Label>
                                                            <Input
                                                                type="text"
                                                                name="port"
                                                                value={(configurationAdmin != null && configurationAdmin.port) ? configurationAdmin.port : ""}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin != null) {
                                                                        this.handleInputData(InputFormatConfig.PORT, e.target.value.replace(/[^0-9]/gi, ""))
                                                                    }
                                                                }}
                                                                placeholder={label.EnterPortMail}
                                                            />
                                                            <WarningLabel message={this.state.portWarning} show={!isEmpty(this.state.portWarning)} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="username">Username</Label>
                                                            <Input
                                                                type="text"
                                                                name="username"
                                                                value={(configurationAdmin != null && configurationAdmin.username) ? configurationAdmin.username : ""}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin != null) {
                                                                        this.handleInputData(InputFormatConfig.USERNAME, e.target.value)
                                                                    }
                                                                }}
                                                                placeholder={label.EnterUsernameMail}
                                                            />
                                                            <WarningLabel message={this.state.usernameWarning} show={!isEmpty(this.state.usernameWarning)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="password">Password</Label>
                                                            <Input
                                                                type="text"
                                                                name="password"
                                                                value={(configurationAdmin != null && configurationAdmin.password) ? configurationAdmin.password : ""}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin != null) {
                                                                        this.handleInputData(InputFormatConfig.PASSWORD, e.target.value)
                                                                    }
                                                                }}
                                                                placeholder={label.EnterPasswordMail}
                                                            />
                                                            <WarningLabel message={this.state.passwordWarning} show={!isEmpty(this.state.passwordWarning)} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="replyTo">Reply To</Label>
                                                            <Input
                                                                type="text"
                                                                name="replyTo"
                                                                value={(configurationAdmin != null && configurationAdmin.replyTo) ? configurationAdmin.replyTo : ""}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin != null) {
                                                                        this.handleInputData(InputFormatConfig.REPLY_TO, e.target.value)
                                                                    }
                                                                }}
                                                                placeholder={label.EnterReplyToMail}
                                                            />
                                                            <WarningLabel message={this.state.replyToWarning} show={!isEmpty(this.state.replyToWarning)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="from">From</Label>
                                                            <Input
                                                                type="text"
                                                                name="from"
                                                                value={(configurationAdmin != null && configurationAdmin.mailFrom) ? configurationAdmin.mailFrom : ""}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin != null) {
                                                                        this.handleInputData(InputFormatConfig.MAIL_FROM, e.target.value)
                                                                    }
                                                                }}
                                                                placeholder={label.EnterFromMail}
                                                            />
                                                            <WarningLabel message={this.state.mailFromWarning} show={!isEmpty(this.state.mailFromWarning)} />

                                                        </FormGroup>
                                                    </Col>
                                                </Row>


                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>SMTP auth </Label>
                                                            <Input
                                                                type="select"
                                                                id="input1-group2"
                                                                name="input1-group2"
                                                                value={configurationAdmin.smtpAuth}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin) {
                                                                        this.handleInputData(InputFormatConfig.SMTP_AUTH, e.target.value)
                                                                    }
                                                                }}

                                                            >
                                                                <option value={true}>true</option>
                                                                <option value={false}>false</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>SMTP starttls enable</Label>
                                                            <Input
                                                                type="select"
                                                                id="input1-group2"
                                                                name="input1-group2"
                                                                value={configurationAdmin.smtpStarttlsEnable}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin) {
                                                                        this.handleInputData(InputFormatConfig.SMTP_STARTTTLS_ENABLE, e.target.value)
                                                                    }
                                                                }}
                                                            >

                                                                <option value={true}>true</option>
                                                                <option value={false}>false</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>SMTP starttls required</Label>
                                                            <Input
                                                                type="select"
                                                                id="input1-group2"
                                                                name="input1-group2"
                                                                value={configurationAdmin.smtpStarttlsRequired}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin) {
                                                                        this.handleInputData(InputFormatConfig.SMTP_STARTTTLS_REQUIRED, e.target.value)
                                                                    }
                                                                }}
                                                            >
                                                                <option value={true}>true</option>
                                                                <option value={false}>false</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Mail Debug</Label>
                                                            <Input
                                                                type="select"
                                                                id="input1-group2"
                                                                name="input1-group2"
                                                                value={configurationAdmin.mailDebug}
                                                                onChange={(e) => {
                                                                    if (configurationAdmin) {
                                                                        this.handleInputData(InputFormatConfig.MAIL_DEBUG, e.target.value)
                                                                    }
                                                                }}
                                                            >
                                                                <option value={true}>true</option>
                                                                <option value={false}>false</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader>
                                                                {label.Other}
                                                            </CardHeader>
                                                            <CardBody>
                                                                <FormGroup>
                                                                    <Label for="sequenceStartSerialNumber">{label.SequenceStartSerialNumber}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="sequenceStartSerialNumber"
                                                                        value={(configurationAdmin && configurationAdmin.sequenceStartSerialNumber) ? configurationAdmin.sequenceStartSerialNumber : ""}
                                                                        onChange={(e) => {
                                                                            if (configurationAdmin) {
                                                                                this.handleInputData(InputFormatConfig.SEQUENCE_START_SERIAL_NUMBER, e.target.value.replace(/[^0-9]/gi, ""))
                                                                            }
                                                                        }}
                                                                        placeholder={label.EnterSequenceStartSerialNumber}
                                                                    />
                                                                    <WarningLabel message={this.state.sequenceStartSerialNumberWarning} show={!isEmpty(this.state.sequenceStartSerialNumberWarning)} />
                                                                </FormGroup>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    configurationAdmin: {}
                                                                }, () => {
                                                                    this.props.history.goBack();
                                                                })
                                                            )}>{label.Back}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">

                                                            <Button color="secondary" onClick={event=>{
                                                                           event.preventDefault()
                                                                           this.setState({
                                                                               testSendMailModal:true
                                                                           })
                                                                    }}>{label.TestSendMail}</Button>
                                                                    &nbsp;&nbsp;

                                                            <Button color="primary" onClick={async (e) => {
                                                                e.preventDefault()
                                                                await this.confirmSave()
                                                            }}> {label.Save}</Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Page>
        );
    }
}
export default Object.assign(ConfigurationAdminPage, { ENDPOINT: "/configurationAdmin" })
